import {
  VerticalCarousel,
  VerticalCarouselProps,
} from '../algolia/catalogs/VerticalCarousel';
import { memo } from '../../util/memo';
import { OrNode, Hit } from '../../../functions/src/types/Hit';
import { Caller } from '../../../functions/src/types/realtimeDb/Room/Caller';
import { usePreemptState } from '../../contexts/algolia/PreemptStateContext';
import { useMemo, ReactNode, FC } from 'react';
import { NoSearchResults } from '../algolia/search/NoSearchResults';
import { renderHits } from '../../util/algolia/renderHits';
import { PLACEHOLDER_KEY } from 'src/hooks/content/useNearEndContent';

export type CallerHit = OrNode<Hit<Caller>>;

export type CallersVerticalCarouselProps = Omit<
  VerticalCarouselProps,
  'Content'
> & {
  hits: CallerHit[];
  noCallers: ReactNode;
  RenderCallerHit: FC<CallerHit>;
};

const CallersVerticalCarouselUnmemoized = ({
  hits,
  noCallers,
  RenderCallerHit,
  ...props
}: CallersVerticalCarouselProps) => {
  const state = usePreemptState();
  const query = state?.query;

  const Content = useMemo(() => {
    if (hits.length === 0 && !query) {
      return [
        {
          key: PLACEHOLDER_KEY,
          Node: noCallers,
        },
      ];
    }

    if (hits.length === 0) {
      return [
        {
          key: PLACEHOLDER_KEY,
          Node: <NoSearchResults />,
        },
      ];
    }

    return renderHits(hits, (hit) => {
      if (!hit) {
        return;
      }
      return <RenderCallerHit {...hit} />;
    });
  }, [hits, query, noCallers, RenderCallerHit]);

  return <VerticalCarousel {...props} Content={Content} />;
};

export const CallersVerticalCarousel = memo(CallersVerticalCarouselUnmemoized);
