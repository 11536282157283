/* eslint-disable no-console */
/* eslint-disable security/detect-object-injection */
/* eslint-disable @typescript-eslint/no-explicit-any */

import stringify from 'safe-stable-stringify';

type ConsoleMethod = 'log' | 'info' | 'warn' | 'error';

const originalConsoleMethods: Record<ConsoleMethod, typeof console.log> = {
  log: console.log,
  info: console.info,
  warn: console.warn,
  error: console.error,
};

export const IGNORED_MESSAGES: Record<ConsoleMethod, readonly RegExp[]> = {
  error: [
    /^Warning: Cannot update a component \(\`\%s\`\) while rendering a different component \(\`\%s\`\)/,
    /^AlgoliaLayoutBidirectionalUnmemoized/,
    /TypeError: Cannot read properties of undefined \(reading 'slice'\).*at AlgoliaSearchHelper/,
  ],
  warn: [
    /^\[InstantSearch.js\]: The `searchFunction` option is deprecated. Use `onStateChange` instead.$/,
  ],
  log: [],
  info: [],
};

export const shouldIgnoreMessage = (
  message: string,
  regexes: readonly RegExp[],
) => {
  return regexes.some((regex) => {
    return regex.test(message);
  });
};

export const createConsoleMethod = (
  method: ConsoleMethod,
  customHandler?: (message: string, ...args: any[]) => void,
) => {
  return (...args: any[]) => {
    const argsStringified = args.map((arg) => {
      if (typeof arg !== 'object') {
        return String(arg);
      }
      try {
        return stringify(arg);
      } catch (error) {
        return '';
      }
    });
    const message = argsStringified.join('');

    if (shouldIgnoreMessage(message, IGNORED_MESSAGES[method])) {
      return;
    }

    if (customHandler) {
      customHandler(message, ...args);
    }

    originalConsoleMethods[method](...args);
  };
};

export const setupConsoleSuppression = (
  customHandlers?: Partial<
    Record<ConsoleMethod, (message: string, ...args: any[]) => void>
  >,
) => {
  (Object.keys(originalConsoleMethods) as ConsoleMethod[]).forEach((method) => {
    console[method] = createConsoleMethod(method, customHandlers?.[method]);
  });
};

// This is commmented out because we initialize via detectAdBlock.ts
setupConsoleSuppression();
