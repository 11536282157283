import { memo } from '../../util/memo';
import { outcomeOf } from '../../util/tournaments/outcomeOf';
import { GradientTypography } from '../gradients/GradientTypography';
import { CompetitorStatusIcon } from './CompetitorStatusIcon';
import {
  MatchAggregated,
  Outcome,
} from 'functions/src/types/firestore/Game/Tournament/Bracket';
import { useMemo } from 'react';
import { useMatch } from './match/MatchProvider';
import { useMatchDetailsRouter } from '../../hooks/tournaments/useMatchDetailsRouter';
import { isSessionCompleted } from '../../../functions/src/util/tournaments/moderation/isSessionCompleted';
import { useAuth } from '../../contexts/AuthContext';
import { isCurrentSessionScoredBy } from '../../../functions/src/util/tournaments/moderation/isCurrentSessionScoredBy';

export type CompetitorOutcomeProps = {
  status: MatchAggregated['status'];
  outcome?: Outcome;
  score?: number;
};

export const CompetitorOutcome = memo(function CompetitorOutcomeUnmemoized(
  props: CompetitorOutcomeProps,
) {
  const { outcome, score, status } = props;
  const { uid } = useAuth();
  const [{ sessionIndex = 0 }] = useMatchDetailsRouter();
  const { team1Scores, team2Scores, team1, team2 } = useMatch();

  const sessionCompleted = useMemo(() => {
    return isSessionCompleted(sessionIndex, { team1Scores, team2Scores });
  }, [sessionIndex, team1Scores, team2Scores]);

  const currentSessionScored = useMemo(() => {
    return isCurrentSessionScoredBy(
      sessionIndex,
      {
        team1Scores,
        team2Scores,
      },
      [team1?.acceptedUserIds || [], team2?.acceptedUserIds || []],
      uid,
    );
  }, [sessionIndex, team1Scores, team2Scores, team1, team2, uid]);

  const isScoringActive = !sessionCompleted && !currentSessionScored;

  const { outcomeFormatted } = outcomeOf(outcome, score, isScoringActive);

  if (outcomeFormatted !== undefined) {
    return (
      <GradientTypography
        variant="h6"
        gradientColor={
          outcome === 'win' || outcome === 'bypass'
            ? 'secondary.horizontal'
            : outcome === 'loss'
            ? 'error.horizontal'
            : 'warning.main'
        }
        sx={{
          textTransform: 'uppercase',
        }}
      >
        {outcomeFormatted}
      </GradientTypography>
    );
  }

  return <CompetitorStatusIcon status={status} />;
});
