import { useCallback } from 'react';
import type { Params as SetBanAndStatusParams } from '../../firebaseCloud/messaging/setBanAndStatus';
import { toBanId } from '../../util/ban/toBanId';
import { toUserId } from '../../../functions/src/util/messaging/mapId';

export type UseSetBanStatusParams = Omit<SetBanAndStatusParams, 'details'> & {
  details: Omit<SetBanAndStatusParams['details'], 'id'>;
};

export const useSetBanStatus = () => {
  const setBanStatus = useCallback(async (params: UseSetBanStatusParams) => {
    const { setBanAndStatus } = await import(
      '../../firebaseCloud/messaging/setBanAndStatus'
    );

    const { operation, details } = params;
    const { channelId, target_user_id, timeout, tournamentId } = details;

    // TODO: When we implement the guild page, instead of setBanAndStatus taking in tournamentId,
    // it should take in roledPath. We want to make this extensible to any document type that extends Roled.

    const baseDetails = {
      channelId,
      tournamentId,
      id: toBanId({ userId: toUserId(target_user_id), channelId }),
      target_user_id,
    };

    const banDetails =
      operation === 'ban'
        ? {
            ...details,
            startDate: Date.now(),
            endDate: timeout ? Date.now() + timeout : undefined,
          }
        : {};

    const options = {
      operation,
      details: {
        ...baseDetails,
        ...banDetails,
      },
    };

    return await setBanAndStatus(options);
  }, []);

  return { setBanStatus };
};
