export type ToPresencePathParams = {
  elementId: string;
  choice:
    | 'liveViewers'
    | 'liveViewerCount'
    | 'totalViewers'
    | 'totalViewerCount'
    | 'totalViews';
  viewerId?: string;
};

export const toPresencePath = ({
  elementId,
  choice,
  viewerId,
}: ToPresencePathParams) => {
  return `/presence/${elementId}/${choice}${viewerId ? `/${viewerId}` : ''}`;
};
