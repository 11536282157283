import { GroupType } from '../../../groupType';
import { toGroupDocumentPath } from '../path';

export function toSubgroupCollectionPath(
  groupType: GroupType,
  parentGroupId: string,
) {
  return `${toGroupDocumentPath(groupType, parentGroupId)}/Subgroup`;
}

export function toSubgroupDocumentPath(
  groupType: GroupType,
  parentGroupId: string,
  subgroupId: string,
) {
  return `${toSubgroupCollectionPath(groupType, parentGroupId)}/${subgroupId}`;
}
