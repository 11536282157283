import {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
  useMemo,
} from 'react';
import { memo } from '../../util/memo';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';

export type TheaterModeContextValue = {
  isTheaterMode: boolean;
  enterTheaterMode: () => void;
  exitTheaterMode: () => void;
};

const TheaterModeContext = createContext<TheaterModeContextValue | undefined>(
  undefined,
);

export function useTheaterMode() {
  const context = useContext(TheaterModeContext);
  if (context === undefined) {
    throw new HttpsError(
      'failed-precondition',
      'useTheaterMode must be used within a TheaterModeProvider',
    );
  }
  return context;
}

const TheaterModeProviderUnmemoized = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isTheaterMode, setIsTheaterMode] = useState(false);

  const enterTheaterMode = useCallback(() => {
    setIsTheaterMode(true);
  }, []);

  const exitTheaterMode = useCallback(() => {
    setIsTheaterMode(false);
  }, []);

  const valueMemoed = useMemo(() => {
    return {
      isTheaterMode,
      enterTheaterMode,
      exitTheaterMode,
    };
  }, [isTheaterMode, enterTheaterMode, exitTheaterMode]);

  return (
    <TheaterModeContext.Provider value={valueMemoed}>
      {children}
    </TheaterModeContext.Provider>
  );
};

export const TheaterModeProvider = memo(TheaterModeProviderUnmemoized);
