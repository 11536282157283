import { Capacitor } from '@capacitor/core';
import { BlumintPlatform } from '../../functions/src/types/Platform';

export const detectPlatform = (): BlumintPlatform => {
  if (isOnAndroid()) {
    return 'android';
  }
  if (IS_ELECTRON) {
    return 'electron';
  }
  return 'browser';
};

export const isOnAndroid = () => {
  return Capacitor.isNativePlatform();
};

export const IS_ELECTRON =
  typeof window !== 'undefined' && window.electron?.isElectron;
