/* eslint-disable @typescript-eslint/no-explicit-any */
import type { CollectionReference, DocumentData } from 'firebase/firestore';
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { memo } from '../../util/memo';
import {
  EditStep,
  EditStepPreFirestore,
} from '../../../functions/src/types/firestore/EditStep/Step';
import { applyStep } from '../../util/edit-steps/applyStep';
import { useAuth } from '../AuthContext';
import { ConverterFactory } from '../../../functions/src/util/firestore/ConverterFactory';
import { useEditingConnectionSentinel } from '../../hooks/organizer/useEditingConnectionSentinel';
import { useWriteStepsBatch } from '../../hooks/organizer/useWriteStepsBatch';
import { useOptimisticFieldUpdate } from '../../hooks/organizer/useOptimisticFieldUpdate';
import { useDynamic } from '../../hooks/useDynamic';
import { useDebounce } from '@react-hook/debounce';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';

export type UpdateFieldParams<
  T extends DocumentData,
  TKey extends Exclude<keyof T, symbol>,
> = {
  field: TKey | string;
  value: T[TKey] | T[string] | { insert: T[TKey]; index: number };
  action: EditStep['action'];
}[];

export type EditableStepsContextType<T extends DocumentData> = {
  document: T | undefined;
  setDocumentPath: Dispatch<SetStateAction<string | undefined>>;
  updateField: <TKey extends Exclude<keyof T, symbol>>(
    updates: UpdateFieldParams<T, TKey>,
  ) => Promise<void>;
  editableFields: (keyof T)[];
};

const EditableStepsContext =
  createContext<EditableStepsContextType<any> | null>(null);

export const useEditable = <T extends DocumentData>() => {
  const context = useContext(
    EditableStepsContext,
  ) as EditableStepsContextType<T>;
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useEditable must be used within a EditableStepsProvider',
    );
  }
  return context;
};

export type EditableStepsProviderProps<T> = {
  children: React.ReactNode;
  documentPath?: string;
  documentInitial?: T;
  editableFields: Array<keyof T>;
};

export const EditableStepsProvider = memo(
  function EditableStepsProviderUnmemoized<T extends DocumentData>({
    children,
    documentPath: documentPathInitial,
    documentInitial,
    editableFields,
  }: EditableStepsProviderProps<T>) {
    const [documentPath, setDocumentPath] = useState<string | undefined>(
      documentPathInitial,
    );

    const { uid } = useAuth();
    const connector = useEditingConnectionSentinel(
      documentPath,
      uid || undefined,
    );
    const [document, setDocument] = useState<T | undefined>(documentInitial);

    const [stepRef, setStepRef] =
      useState<CollectionReference<EditStep> | null>(null);
    const [currentStepId, setCurrentStepId] = useState(1);
    const currentStepIdRef = useRef(currentStepId);

    const [pendingSteps, setPendingSteps] = useDebounce<EditStepPreFirestore[]>(
      [],
      500,
    );

    useEffect(() => {
      // Update the ref whenever currentStepId changes
      // allows up-to-date access to currentStepId without
      // infinite loops in useEffect
      currentStepIdRef.current = currentStepId;
    }, [currentStepId]);

    const addNewSteps = useCallback((newSteps: EditStepPreFirestore[]) => {
      setPendingSteps((prev) => {
        return [...prev, ...newSteps];
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendToFirestore = useWriteStepsBatch({
      stepRef,
      currentStepId: currentStepIdRef.current,
      setCurrentStepId,
      setPendingSteps,
      setDocument,
      applyStep,
    });

    useEffect(() => {
      if (pendingSteps.length > 0) {
        sendToFirestore(pendingSteps);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pendingSteps.length]);

    const firestoreModule = useDynamic(
      import('../../config/firebase-client/firestore'),
    );
    const firebaseFirestoreModule = useDynamic(import('firebase/firestore'));

    useEffect(() => {
      if (!firebaseFirestoreModule) {
        return;
      }
      const { onSnapshot, orderBy, query, where } = firebaseFirestoreModule;

      if (!stepRef || !document || !uid) {
        return;
      }
      const unsubscribe = onSnapshot(
        query(
          stepRef,
          orderBy('stepId', 'asc'),
          // TODO: refactor to time based ids
          where('stepId', '>', currentStepIdRef.current),
        ).withConverter<EditStep>(ConverterFactory.buildDateConverter()),
        (newSteps) => {
          newSteps.docs.forEach((doc) => {
            const step = doc.data();
            applyStep(step, setDocument as Dispatch<SetStateAction<T>>);
          });
          if (!!newSteps.size) {
            setCurrentStepId(
              parseInt(newSteps.docs[newSteps.docs.length - 1]?.id || '1'),
            );
          }
        },
      );

      return () => {
        return unsubscribe();
      };
    }, [document, firebaseFirestoreModule, stepRef, uid]);

    useEffect(() => {
      if (!firebaseFirestoreModule || !firestoreModule) {
        return;
      }
      const { collection, doc, getDocs, onSnapshot } = firebaseFirestoreModule;
      const { firestore } = firestoreModule;

      if (!documentPath || !uid || !connector) {
        return;
      }

      const newStepRef = collection(
        firestore,
        `${documentPath}/EditStep`,
      ) as CollectionReference<EditStep>;
      setStepRef(newStepRef);
      const initialize = async () => {
        const [snapshot, _] = await Promise.all([
          getDocs(newStepRef),
          connector.connect(),
        ]);
        setCurrentStepId(
          parseInt(snapshot.docs[snapshot.docs.length - 1]?.id || '1'),
        );
      };
      const unsubscribe = onSnapshot(
        doc(firestore, documentPath).withConverter<T>(
          ConverterFactory.buildDateConverter(),
        ),
        (snapshot) => {
          setDocument(snapshot.data());
        },
        (error) => {
          console.error(error);
        },
      );
      initialize();
      return () => {
        unsubscribe();
        connector.disconnect();
      };
    }, [
      connector,
      documentPath,
      firebaseFirestoreModule,
      firestoreModule,
      uid,
    ]);

    const optimisticFieldUpdate = useOptimisticFieldUpdate({
      addNewSteps,
      applyStep,
      setDocument,
    });

    const updateField: EditableStepsContextType<T>['updateField'] = useCallback(
      async (updates) => {
        if (
          !document ||
          updates.some(({ field }) => {
            return !editableFields.some((editableField) => {
              return String(field).startsWith(String(editableField));
            });
          })
        ) {
          console.warn('invalid update', updates);
          return;
        }
        await optimisticFieldUpdate(updates);
      },
      [document, editableFields, optimisticFieldUpdate],
    );

    return (
      <EditableStepsContext.Provider
        value={{
          document,
          setDocumentPath,
          updateField,
          editableFields,
        }}
      >
        {children}
      </EditableStepsContext.Provider>
    );
  },
);
