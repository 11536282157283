import { Link, LinkProps } from '../Link';
import { ReactNode, FC } from 'react';
import { memo } from '../../util/memo';
import { Tournament } from '../../../functions/src/types/firestore/Game/Tournament';
import { toEventUrl } from '../../util/event/toEventUrl';

export type LinkEventProps = Omit<LinkProps, 'href' | 'style'> & {
  hit: Pick<Tournament<Date>, 'id' | 'gameId' | 'date' | 'roles'>;
  children: ReactNode;
};

const LinkEventUnmemoized: FC<LinkEventProps> = ({
  hit,
  children,
  ...props
}) => {
  const { eventUrlPath } = toEventUrl(hit);

  return (
    <Link href={eventUrlPath} style={{ textDecoration: 'none' }} {...props}>
      {children}
    </Link>
  );
};

export const LinkEvent = memo(LinkEventUnmemoized);
