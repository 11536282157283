import {
  createContext,
  ReactElement,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { memo } from '../util/memo';
import { useTheme } from '@mui/material/styles';
import { useRouter } from 'src/hooks/routing/useRouter';
import Box from '@mui/material/Box';
import { LottieLoader } from 'src/components/LottieLoader';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';

export type PageLoadingContextType = {
  toggleLoadingOverlay: (show: boolean) => void;
};

const defaultState: PageLoadingContextType = {
  toggleLoadingOverlay: () => {
    /* */
  },
};

const PageLoadingContext = createContext(defaultState);

export function usePageLoadingContext() {
  const context = useContext(PageLoadingContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'usePageLoadingContext must be used within a PageLoadingProvider',
    );
  }
  return context;
}

export type PageLoadingProviderProps = {
  children: ReactElement;
};

export const PageLoadingProvider = memo(function PageLoadingProviderUnmemoized({
  children,
}: PageLoadingProviderProps) {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState<boolean>();
  const theme = useTheme();

  useEffect(() => {
    const handleStart = (_url, { shallow }) => {
      shallow ? setIsLoading(false) : setIsLoading(true);
    };
    const handleComplete = () => {
      return setIsLoading(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  });

  const contextValue = useMemo(() => {
    return {
      toggleLoadingOverlay: (state: boolean) => {
        setIsLoading(state);
      },
    };
  }, []);

  return (
    <PageLoadingContext.Provider value={contextValue}>
      <>
        {children}
        {!!isLoading && (
          <Box
            sx={{
              height: '100dvh',
              width: '100vw',
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: theme.palette.background.elevation[1],
              borderRadius: '10px',
              zIndex: 9999,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LottieLoader sx={{ height: '40px', width: '40px' }} />
          </Box>
        )}
      </>
    </PageLoadingContext.Provider>
  );
});
