import {
  AlgoliaEventsCalendar,
  AlgoliaEventsCalendarProps,
} from '../../algolia/AlgoliaEventsCalendar';
import {
  SelectableEventWrapper,
  SelectableEventWrapperProps,
} from './SelectableEventWrapper';
import { CreateEventButton } from '../CreateEventButton';
import { FC, useMemo, ReactElement, useCallback } from 'react';
import { useRouterState } from '../../../hooks/routing/useRouterState';
import { memo } from '../../../util/memo';
import { useUserEventsFilters } from '../../../hooks/calendar/selectable-event/useUserEventsFilters';
import { SQUARE_300_AD_INJECTION_CONFIG } from '../../../util/ads/adInjectionConfigs';
import { useAdInjection } from '../../../hooks/ads/useAdInjection';
import { EventHit } from '../../algolia/catalog-wrappers/EventsCalendar';
import { CALENDAR_AD } from '../../../../functions/src/util/ads/adIds';
import { useMobile } from '../../../hooks/useMobile';

export type SelectableEventsCalendarProps = Omit<
  AlgoliaEventsCalendarProps,
  | 'Wrapper'
  | 'configureOptions'
  | 'TitleRight'
  | 'initialDate'
  | 'transformedHits'
  | 'Extension'
> &
  Pick<SelectableEventWrapperProps, 'routerOptions' | 'onChange'> & {
    groupId?: string;
    showCreateEventButton?: boolean;
  };

const SELECT_CALENDAR_AD_CONFIG = {
  id: CALENDAR_AD,
  ...SQUARE_300_AD_INJECTION_CONFIG,
} as const;

const CALENDAR_VISIBILITY_OPTIONS_MOBILE = {
  threshold: 0,
  rootMargin: '0px',
} as const;

const CALENDAR_VISIBILITY_OPTIONS_DESKTOP = {
  threshold: 0,
  rootMargin: '500px',
} as const;

const SelectableEventsCalendarUnmemoized: FC<SelectableEventsCalendarProps> = ({
  description,
  height = '520px',
  groupId,
  showCreateEventButton = true,
  routerOptions,
  onChange,
  ...props
}) => {
  const [calendarDateParam] = useRouterState({
    key: 'event-date',
    silent: true,
  });

  const calendarDate = useMemo(() => {
    return calendarDateParam && !Number.isNaN(Number(calendarDateParam))
      ? new Date(Number(calendarDateParam))
      : undefined;
  }, [calendarDateParam]);

  const filters = useUserEventsFilters(groupId);

  const configureOptions = useMemo(() => {
    return {
      filters,
    };
  }, [filters]);

  const isMobile = useMobile();

  const injectAdsProps = useMemo(() => {
    const visibilityOptions = isMobile
      ? CALENDAR_VISIBILITY_OPTIONS_MOBILE
      : CALENDAR_VISIBILITY_OPTIONS_DESKTOP;

    return {
      ...SELECT_CALENDAR_AD_CONFIG,
      visibilityOptions,
    };
  }, [isMobile]);

  const injectAdsMain = useAdInjection<EventHit<Date>>(injectAdsProps);
  const injectAdsExtension = useAdInjection<EventHit<Date>>(injectAdsProps);

  const createEventButton = useMemo(() => {
    if (showCreateEventButton) {
      return <CreateEventButton />;
    }
    return undefined;
  }, [showCreateEventButton]);

  const EventWrapper = useCallback(
    (wrapperProps: { children: ReactElement }) => {
      return (
        <SelectableEventWrapper
          {...wrapperProps}
          routerOptions={routerOptions}
          onChange={onChange}
        />
      );
    },
    [routerOptions, onChange],
  );

  const EventsCalendar = useMemo(() => {
    return (
      <AlgoliaEventsCalendar
        {...props}
        Wrapper={EventWrapper}
        transformHits={injectAdsMain}
        transformHitsExtension={injectAdsExtension}
        configureOptions={configureOptions}
        description={description}
        SearchRight={createEventButton}
        initialDate={calendarDate}
        height={height}
        Title={props.Title}
      />
    );
  }, [
    calendarDate,
    configureOptions,
    createEventButton,
    description,
    height,
    injectAdsExtension,
    injectAdsMain,
    props,
    EventWrapper,
  ]);

  return EventsCalendar;
};

export const SelectableEventsCalendar = memo(
  SelectableEventsCalendarUnmemoized,
);
