export const TOURNAMENT_URL_MATCH = /Tournament%2F([a-zA-Z0-9-]+)/;
export const GROUP_URL_MATCH = /UTC-\d+\/([a-zA-Z0-9-]+)/;

export const toTournamentId = (url: string) => {
  const tournamentIdMatch = url.match(TOURNAMENT_URL_MATCH);
  return tournamentIdMatch ? { tournamentId: tournamentIdMatch[1] } : undefined;
};

export const toGroupId = (url: string) => {
  const groupIdMatch = url.match(GROUP_URL_MATCH);
  return groupIdMatch ? { groupId: groupIdMatch[1] } : undefined;
};

// View remarks for gameId in functions/src/types/firestore/User/ChannelGroup/index.ts
// export const toGameId = (url: string) => {
//   const gameIdMatch = url.match(/Game%2F([a-zA-Z0-9-]+)/);
//   return gameIdMatch ? { gameId: gameIdMatch[1] } : undefined;
// };
