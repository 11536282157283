import Typography from '@mui/material/Typography';
import { useCallback, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { useAuth } from 'src/contexts/AuthContext';
import { useWizard } from 'src/components/wizard/Wizard';
import { DialogBodyStandard } from '../../dialog/DialogBodyStandard';
import { DialogActionsStandard } from '../../dialog/DialogActionsStandard';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';

const UnregisterGuardDialogUnmemoized = () => {
  const { go } = useWizard();
  const { gameId, id: tournamentId, foundTeam } = useTournamentRegistration();
  const { id: teamId } = foundTeam!;
  const { uid } = useAuth();

  const unregister = useCallback(async () => {
    if (!uid) {
      return go(undefined);
    }
    const { exitTeam } = await import(
      '../../../firebaseCloud/tournament/exitTeam'
    );
    await exitTeam({
      gameId,
      userId: uid,
      tournamentId,
      teamId,
    });
    go('UnregisteredDialog');
  }, [gameId, go, teamId, tournamentId, uid]);

  const description = useMemo(() => {
    return (
      <Typography variant="h6">
        This will remove you from the tournament. <br /> You will NOT be able to
        re-register.
      </Typography>
    );
  }, []);

  const actionButtons = useMemo(() => {
    return [
      {
        children: 'Unregister',
        isAsync: true,
        onClick: unregister,
        color: 'error',
      },
      {
        children: 'Cancel',
        isAsync: false,
        color: 'primary',
        onClick: () => {
          return go('EntryDialog');
        },
      },
    ];
  }, [go, unregister]);

  return (
    <DialogBodyStandard title="Are You Sure?" description={description}>
      <DialogActionsStandard buttons={actionButtons} />
    </DialogBodyStandard>
  );
};

export const UnregisterGuardDialog = memo(UnregisterGuardDialogUnmemoized);
