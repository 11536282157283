import {
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';
import { memo } from '../util/memo';
import {
  cleanPassword,
  isValidPassword,
  PasswordFailureReason,
  getPasswordFailures,
} from 'src/util/credentialFormatValidation';
import { isValidEmail } from 'src/util/emailUtils';
import { useWizard } from 'src/components/wizard/Wizard';
import { useAuth } from './AuthContext';
import { useRemoveAuthQueryParams } from 'src/hooks/useRemoveAuthQueryParams';
import { ConfirmationResult } from 'firebase/auth';
import {
  CustomSignInMethod,
  GenericSignInMethod,
} from 'functions/src/types/firestore/User';
import {
  DEFAULT_ERROR_MESSAGE,
  AUTH_ERROR_LIBRARY,
  extractErrorCode,
  extractMethodFromCode,
} from '../util/auth/authErrorConfig';
import { genericOAuthSignIn } from '../util/auth/genericOAuthSignIn';
import { emailPasswordSignIn } from '../util/auth/emailPasswordSignIn';
import { availableMethods } from '../util/auth/availableMethods';
import { createAccount } from '../util/auth/createAccount';
import { AuthFlowStore } from '../hooks/auth/useAuthFlowBase';
import { initRecaptchaVerifier } from '../util/auth/initRecaptchaVerifier';
import { extractDigits } from '../../functions/src/util/extractDigits';
import { useAlertDialog } from '../hooks/useAlertDialog';
import { extractProviderId } from '../../functions/src/util/auth/extractProviderIds';
import { phoneNumberSignIn } from '../util/auth/phoneNumberSignIn';
import { phone } from 'phone';
import { useUpdatePhoneNumber } from '../hooks/auth/useUpdatePhoneNumber';
import { useSignOut } from '../hooks/auth/useSignOut';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';

export const REAUTHENTICATE_ALERT_ID = 'REAUTHENTICATE_ALERT';

export type EmailInput = 'email';

export type PasswordInput = 'password' | 'confirmPassword';

export type ConfirmationCodeInput = 'confirmationCode';

export type PhoneNumberInput = 'phoneNumber';

export type AgreementsInput = 'agreements';

export type UsernameInput = 'username';

export type AuthSubmitInput =
  | EmailInput
  | PasswordInput
  | ConfirmationCodeInput
  | PhoneNumberInput
  | UsernameInput;

export const VERIFICATION_CODE_LENGTH = 6;

export type SubmitSettings = {
  userCredentialsUpdated: {
    [K in AuthSubmitInput]: string;
  };
  confirmPassword?: boolean;
};

export type ErrorableInput =
  | AuthSubmitInput
  | AgreementsInput
  | GenericSignInMethod
  | CustomSignInMethod
  | 'generic';

export type AuthSubmitContextProps = {
  passwordStrengthErrors: { [K in PasswordFailureReason]: boolean };
  userCredentials: { [K in AuthSubmitInput]: string };
  errorMessage: {
    [K in ErrorableInput]?: string;
  };
  clearAllErrors: () => void;
  clearAllCredentials: () => void;
  setErrorCodeOf: (method: ErrorableInput, errorCode?: string) => void;
  onInputChange: (inputName: AuthSubmitInput, inputValue: string) => void;
  signUp: () => Promise<void>;
  signInOAuthGeneric: (provider: GenericSignInMethod) => Promise<void>;
  enterEmail: () => Promise<void>;
  isLoading?: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  signIn: () => Promise<void>;
  isValidated: { [K in AuthSubmitInput]: boolean };
  hasUserTyped: { [K in AuthSubmitInput]: boolean };
  updateUsername: (username: string) => Promise<void>;
};

export const AuthSubmitContext = createContext<AuthSubmitContextProps | null>(
  null,
);

export const useAuthSubmit = () => {
  const context = useContext(AuthSubmitContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useAuthSubmit must be used within a AuthSubmitProvider',
    );
  }
  return context;
};

export const AuthSubmitProvider = memo(function AuthSubmitProviderUnmemoized({
  children,
  ...props
}: {
  children?: ReactNode;
}) {
  const { go, get, set } = useWizard<AuthFlowStore>();
  const { uid, userData } = useAuth();

  const { open: openReAuthenticateAlert } = useAlertDialog(
    REAUTHENTICATE_ALERT_ID,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const removeAuthQueryParams = useRemoveAuthQueryParams();
  const [hasUserTyped, setHasUserTyped] = useState<{
    [K in AuthSubmitInput]: boolean;
  }>({
    password: false,
    confirmPassword: false,
    username: false,
    phoneNumber: false,
    confirmationCode: false,
    email: false,
  });
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult>();

  const [userCredentials, setUserCredentials] = useState<{
    [K in AuthSubmitInput]: string;
  }>({
    email: get('email'),
    password: '',
    confirmPassword: '',
    confirmationCode: '',
    phoneNumber: '',
    username: '',
  });
  const [errorMessage, setErrorMessage] = useState<{
    [K in ErrorableInput]?: string;
  }>({});
  const [isValidated, setIsValidated] = useState<{
    [K in AuthSubmitInput]: boolean;
  }>({
    email: false,
    password: false,
    confirmPassword: false,
    confirmationCode: false,
    phoneNumber: false,
    username: false,
  });
  const [passwordStrengthErrors, setPasswordStrengthErrors] = useState<{
    [K in PasswordFailureReason]: boolean;
  }>({
    blacklistedChar: false,
    needsLowercase: false,
    needsUppercase: false,
    needsNumber: false,
    needsSymbol: false,
    tooShort: false,
    tooLong: false,
  });
  const { updatePhoneNumber } = useUpdatePhoneNumber();
  const setErrorCodeOf = useCallback(
    (method: ErrorableInput, errorCode?: string) => {
      const errorMessageValue = errorCode
        ? AUTH_ERROR_LIBRARY[String(errorCode)] || DEFAULT_ERROR_MESSAGE
        : undefined;
      setErrorMessage((prev) => {
        return {
          ...prev,
          [method]: errorMessageValue,
        };
      });
    },
    [],
  );

  useEffect(() => {
    const { confirmPassword } = userCredentials;
    validate('confirmPassword', confirmPassword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCredentials]);

  const { signOut } = useSignOut();
  const reAuthenticate = useCallback(async () => {
    await signOut();
    go(undefined);
    openReAuthenticateAlert({
      title: 'Sign In Again',
      description:
        'You have not signed in for a quite some time. Before you may continue, please sign in again to verify your identity.',
    });
  }, [go, openReAuthenticateAlert, signOut]);

  const clearErrors = useCallback(
    (method: AuthSubmitInput) => {
      setErrorCodeOf(method);
    },
    [setErrorCodeOf],
  );

  const clearAllErrors = useCallback(() => {
    setErrorMessage({});
  }, []);

  const clearAllCredentials = useCallback(() => {
    setUserCredentials({
      email: get('email'),
      password: '',
      confirmPassword: '',
      confirmationCode: '',
      phoneNumber: '',
      username: '',
    });
  }, [get]);

  const updatePasswordErrors = useCallback(
    (passwordFailures: PasswordFailureReason[]) => {
      const newPasswordErrors: { [K in PasswordFailureReason]: boolean } = {
        ...passwordStrengthErrors,
      };

      Object.keys(passwordStrengthErrors).forEach((failureReason) => {
        newPasswordErrors[failureReason as PasswordFailureReason] =
          passwordFailures.includes(failureReason as PasswordFailureReason);
      });

      setPasswordStrengthErrors(newPasswordErrors);
    },
    [passwordStrengthErrors],
  );

  const signIn = useCallback(async () => {
    const { email, password, phoneNumber, confirmationCode } = userCredentials;
    try {
      setIsLoading(true);
      if (!!confirmationResult && confirmationCode !== '') {
        await confirmationResult.confirm(confirmationCode);
        go(!!uid ? 'Link Successful' : undefined);
        await updatePhoneNumber(phoneNumber);
        clearAllCredentials();
        return;
      }
      if (phoneNumber !== '') {
        const verifier = await initRecaptchaVerifier({
          size: 'invisible',
        });
        const confirmation = await phoneNumberSignIn(phoneNumber, verifier);
        setConfirmationResult(confirmation);
        setErrorCodeOf('phoneNumber');
        go('Enter Confirmation Code');
        setHasUserTyped((prev) => {
          return { ...prev, phoneNumber: false };
        });
        return;
      }
      set('password', cleanPassword(password));
      await emailPasswordSignIn(email, password);
      removeAuthQueryParams();
      setErrorCodeOf('password');
      if (!!userData && !userData.email) {
        const { updateUserEmail } = await import(
          '../firebaseCloud/user/updateUserEmail'
        );
        await updateUserEmail({ email });
      }
      if (!!uid && !!userData) {
        go('Link Successful');
      }
      go(undefined);
    } catch (e) {
      const errorCode = extractErrorCode(e);
      const keyToSet = extractMethodFromCode(errorCode) as ErrorableInput;
      setErrorCodeOf(keyToSet, errorCode);
      if (errorCode === 'requires-recent-login') {
        await reAuthenticate();
      }
      if (errorCode === 'account-exists-with-different-credential') {
        go('Enter Phone Number');
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    clearAllCredentials,
    confirmationResult,
    go,
    reAuthenticate,
    removeAuthQueryParams,
    set,
    setErrorCodeOf,
    uid,
    updatePhoneNumber,
    userCredentials,
    userData,
  ]);

  const signInOAuthGeneric = useCallback(
    async (provider: GenericSignInMethod) => {
      clearAllErrors();
      try {
        setIsLoading(true);
        await genericOAuthSignIn(provider);
        const next = !!uid ? 'Link Successful' : undefined;
        go(next);
      } catch (e) {
        const errorCode = extractErrorCode(e);
        console.error(e);
        setErrorCodeOf('generic', errorCode);
        if (errorCode === 'requires-recent-login') {
          await reAuthenticate();
        }
      } finally {
        setIsLoading(false);
      }
    },
    [clearAllErrors, go, reAuthenticate, setErrorCodeOf, uid],
  );

  const signUp = useCallback(async () => {
    try {
      const { password, confirmPassword, email } = userCredentials;
      const isPasswordStrengthError = Object.values(
        passwordStrengthErrors,
      ).some(Boolean);

      const passwordsMatch = password === confirmPassword;
      if (!passwordsMatch || isPasswordStrengthError) {
        setErrorCodeOf('confirmPassword', 'do-not-match');
        return;
      }
      setIsLoading(true);
      set('email', email.toLowerCase());
      await createAccount(email, password);
      go(undefined);
    } catch (e) {
      if (
        (e as { message: string }).message.includes(
          'This value is already taken',
        )
      ) {
        setErrorCodeOf('username', 'username-unavailable');
        return;
      }
      setErrorCodeOf('email', 'verification-issue');
      go('Enter Email');
    } finally {
      setIsLoading(false);
    }
  }, [go, passwordStrengthErrors, set, setErrorCodeOf, userCredentials]);

  const enterEmail = useCallback(async () => {
    const { email } = userCredentials;
    const { email: emailIsValidated } = isValidated;
    try {
      setIsLoading(true);
      const emailFormatted = email.toLowerCase();
      const { findOwner } = await import('../firebaseCloud/auth/findOwner');
      const emailIsOwnedBy = await findOwner({
        type: 'email',
        value: emailFormatted,
      });

      const methods = await availableMethods(emailFormatted);
      const errorCode =
        !emailIsValidated && !methods ? 'invalid-email' : undefined;
      setErrorCodeOf('email', errorCode);
      if (!emailIsOwnedBy) {
        go(!!uid && !!userData ? 'Link Email' : 'Email Sign Up');
        return;
      }

      const { provider } = emailIsOwnedBy;

      if (methods.includes('password') && provider === 'password') {
        go('Email Sign In');
        return;
      }
      setErrorCodeOf('email', `email-linked-to-${extractProviderId(provider)}`);
    } catch (e) {
      console.error(e);
      setErrorCodeOf('email', extractErrorCode(e));
    } finally {
      setIsLoading(false);
    }
  }, [go, isValidated, setErrorCodeOf, uid, userCredentials, userData]);

  const validate = useCallback(
    (inputName: AuthSubmitInput, inputValue: string) => {
      const validationFunctions: {
        [key in AuthSubmitInput]: (userCredential: string) => boolean;
      } = {
        email: (email) => {
          return isValidEmail(email);
        },
        username: (_username) => {
          return true; //username.length >= 1 && username.length <= 25;
        },
        password: (password) => {
          const passwordFailures: PasswordFailureReason[] =
            getPasswordFailures(password);
          updatePasswordErrors(passwordFailures);
          return isValidPassword(password);
        },
        confirmPassword: (confirmPassword) => {
          return userCredentials.password === confirmPassword;
        },
        confirmationCode: (confirmationCode) => {
          const digitOnlyRegex = /^\d+$/;
          const isValidFormat = digitOnlyRegex.test(confirmationCode);
          const isValidLength =
            confirmationCode.length === VERIFICATION_CODE_LENGTH;
          return isValidFormat && isValidLength;
        },
        phoneNumber: (phoneNumber) => {
          const numbersOnly = extractDigits(phoneNumber);
          const { isValid: isValidNumber } = phone(phoneNumber);
          return /^\+?[0-9]+$/g.test(numbersOnly) && isValidNumber;
        },
      };

      const isValid = validationFunctions[`${inputName}`](inputValue);

      setIsValidated((prev) => {
        return {
          ...prev,
          [inputName]: isValid,
        };
      });
    },
    [updatePasswordErrors, userCredentials.password],
  );

  const onInputChange = useCallback(
    (inputName: AuthSubmitInput, inputValue: string) => {
      setErrorCodeOf('generic');
      if (inputValue.length >= 0) {
        setHasUserTyped((prev) => {
          return { ...prev, [inputName]: true };
        });
      }
      if (!!errorMessage[`${inputName}`]) {
        clearErrors(inputName);
      }
      setUserCredentials((prev) => {
        return {
          ...prev,
          [inputName]: inputValue,
        };
      });
      validate(inputName, inputValue);
    },
    [clearErrors, errorMessage, setErrorCodeOf, validate],
  );

  const updateUsername = useCallback(async (username: string) => {
    const { updateUsername: update } = await import(
      '../firebaseCloud/user/updateUsername'
    );
    await update({ username });
  }, []);

  const authSubmitData = useMemo(() => {
    return {
      passwordStrengthErrors,
      userCredentials,
      onInputChange,
      signUp,
      signInOAuthGeneric,
      enterEmail,
      clearAllErrors,
      clearAllCredentials,
      isLoading,
      setIsLoading,
      signIn,
      isValidated,
      errorMessage,
      setErrorCodeOf,
      updateUsername,
      hasUserTyped,
      ...props,
    };
  }, [
    passwordStrengthErrors,
    userCredentials,
    onInputChange,
    signUp,
    signInOAuthGeneric,
    enterEmail,
    clearAllErrors,
    clearAllCredentials,
    isLoading,
    setIsLoading,
    signIn,
    isValidated,
    errorMessage,
    setErrorCodeOf,
    updateUsername,
    hasUserTyped,
    props,
  ]);

  return (
    <AuthSubmitContext.Provider value={{ ...authSubmitData }}>
      {children}
    </AuthSubmitContext.Provider>
  );
});
