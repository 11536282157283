import { useCollectionSnapshot } from '../firestore/useCollectionSnapshot';
import { Subgroup } from '../../../functions/src/types/firestore/Guild/Subgroup';
import { useGroupRouter } from './useGroupRouter';

export function useGroupSubgroups() {
  const { groupDecoded } = useGroupRouter();

  const subgroups = useCollectionSnapshot<Subgroup>(
    groupDecoded.subgroupCollectionPath,
  );

  return subgroups.map(({ username, id }) => {
    return {
      username,
      id,
    };
  });
}
