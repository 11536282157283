import { ConverterFactory } from '../../../functions/src/util/firestore/ConverterFactory';
import { Subgroup } from '../../../functions/src/types/firestore/Guild/Subgroup';
import { GroupDecoder } from '../../../functions/src/util/group/GroupDecoder';
import { useEffect, useState } from 'react';

export const useSubgroupPermissions = (groupId?: string) => {
  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    const handler = async () => {
      if (!groupId) {
        return;
      }

      const { firestore } = await import(
        '../../config/firebase-client/firestore'
      );
      const { collection, getDocs } = await import('firebase/firestore');

      const decoder = GroupDecoder.fromBase62(groupId);
      const subGroupCollectionPath = decoder.subgroupCollectionPath;

      if (!subGroupCollectionPath) {
        return [];
      }

      const collectionRef = collection(firestore, subGroupCollectionPath);

      const subgroupSnap = await getDocs(
        collectionRef.withConverter<Subgroup>(
          ConverterFactory.buildDateConverter(),
        ),
      );

      const permissionsFound = subgroupSnap.docs.flatMap((doc) => {
        const subgroup = doc.data();
        return subgroup.permissions;
      });

      return setPermissions([...new Set(permissionsFound)]);
    };
    handler();
  }, [groupId]);

  return permissions;
};
