import { OnlineStatusEnum, ONLINE_STATUSES } from '.';

export const isOnlineStatusEnum = (
  value: unknown,
): value is OnlineStatusEnum => {
  return (
    typeof value === 'string' &&
    ONLINE_STATUSES.includes(value as OnlineStatusEnum)
  );
};
