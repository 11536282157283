import { Timestamp } from '../../../../config/firebaseAdmin';
import { Identifiable } from '../../../Identifiable';
import { Resolve } from '../../../utility-types';
import { Payoutable, Token } from '../../User/Payout';

export const EVENT_VISIBILITY = ['listed', 'unlisted'] as const;

export type EventVisibility = (typeof EVENT_VISIBILITY)[number];

export const PRIZES_DEFAULT = {
  amountFiat: 0,
  countIlliquid: 0,
  countUnassured: 0,
};

export type ScoreOption = {
  name: string;
  units: string;
  value?: number;
  orderBy: 'asc' | 'desc';
};

export type Sponsor = {
  name: string;
  imgUrl?: string;
  redirectUrl?: string;
  width?: number;
  height?: number;
};

export type PayoutFormatted = {
  amountFiat?: number;
  countIlliquid?: number;
  countUnassured?: number;
};

export type Blurb = {
  title: string;
  description: string;
  rules?: string[];
};

export type Intermission<T = Timestamp> = {
  start: T;
  end: T;
};

export type Competition<T = Timestamp> = Resolve<
  Identifiable &
    Payoutable<
      {
        title: string;
        gameTitle: string;
        gameId: string;
        endpoint?: string; // for now, instead of competitors/stats fields
        date: T;
        endDate: T;
        imgUrl?: string;
        tournamentThumbnailImgUrl?: string;
        intermissions?: Intermission<T>[];
        /**
         * an undefined value corresponds to a public component.
         * 'unlisted' makes the event only visible to admins & people who have the competiton URL
         */
        visibility?: EventVisibility;
        blurb: Blurb;
        sponsors?: Sponsor[];
        payouts: { tokens: Token[] }[];
        payoutGiveaway?: Token[];
        scoreOptions?: ScoreOption[];
      } & (
        | {
            type: 'event';
            viewerCount: number;
            finals?: string;
            participantsCurrent?: number;
            participantsMax?: number;
            isLive?: boolean;
          }
        | {
            type: 'leaderboard'; //note undead blocks tournaments are actually leaderboards
            competitorCount: number;
          }
        | {
            type: 'tournament';
            competitorCount: number;
          }
      )
    >
>;

export type CompetitionPeriod<T = Timestamp> = Resolve<
  Pick<Competition<T>, 'id' | 'date' | 'title' | 'endDate'>
>;
