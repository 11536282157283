import { useCallback, useMemo } from 'react';
import { memo } from '../../../../util/memo';
import Stack from '@mui/material/Stack';
import { MatchDetailsTeams } from './MatchDetailsTeams';
import { Member } from 'functions/src/types/firestore/Game/Tournament/Guestlist';
import { CompetitorOutcomeProps } from '../../CompetitorOutcome';
import { Competitor } from '../MatchCompetitor';
import { MatchLiveBadge } from '../MatchLiveBadge';
import { useRouterState } from '../../../../hooks/routing/useRouterState';
import { SessionDetails } from './SessionDetails';
import { TabPane, TabPanes } from '../../../routing/TabPanes';
import { useMatch } from '../MatchProvider';
import {
  SESSION_DIALOG_KEY,
  useMatchDetailsRouter,
} from '../../../../hooks/tournaments/useMatchDetailsRouter';
import { findPreviousDisputeIn } from '../../../../../functions/src/util/tournaments/moderation/findPreviousDisputeIn';
import { activeSessionOf } from '../../../../../functions/src/util/tournaments/moderation/activeSessionOf';

export const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

export type MatchCompetitor = Competitor &
  Pick<CompetitorOutcomeProps, 'outcome' | 'score'>;

export type CompetitorWithMembers = MatchCompetitor & {
  members: Member[];
};

export const MatchDetails = memo(function MatchDetailsUnmemoized() {
  const { games, team1Scores, team2Scores, winner } = useMatch();
  const activeSessionIndex = activeSessionOf({
    team1Scores,
    team2Scores,
    winner,
  });
  const [_activeMatchRouter, setActiveMatchRouter] = useMatchDetailsRouter();
  const tabPanesState = useRouterState({
    key: SESSION_DIALOG_KEY,
    location: 'queryParam',
  });

  const gameSessions = useMemo(() => {
    return games.map((game) => {
      return `GAME ${game}`;
    });
  }, [games]);

  const lockSessionIf = useCallback(
    (index: number) => {
      const hasPreviousDispute = findPreviousDisputeIn(
        team1Scores,
        team2Scores,
        index,
      );
      return index > activeSessionIndex || hasPreviousDispute;
    },
    [activeSessionIndex, team1Scores, team2Scores],
  );

  const sessionTabPanes: TabPane<string>[] = useMemo(() => {
    return gameSessions.map((session, index) => {
      return {
        value: `${index}`,
        component: <SessionDetails />,
        customization: {
          label: session,
          disabled: lockSessionIf(index),
        },
        onClick: () => {
          setActiveMatchRouter({
            sessionIndex: index,
          });
        },
      };
    });
  }, [gameSessions, lockSessionIf, setActiveMatchRouter]);

  return (
    <MatchLiveBadge>
      <Stack direction="column" p={4} width="100%">
        <TabPanes
          tabPanes={sessionTabPanes}
          state={tabPanesState}
          variant="chip"
        />
        <MatchDetailsTeams />
        {/* <MatchRewards rounds={rounds} /> */}
      </Stack>
    </MatchLiveBadge>
  );
});
