import { base62 } from './encoding/BaseX';

export const UUIDV4_BASE62_LENGTH = 22 as const;
export const UUIDV4_BYTES_LENGTH = 16 as const;

export function uuidv4Base62() {
  const bytes = new Uint8Array(16);
  crypto.getRandomValues(bytes);

  return base62.encode(bytes);
}
