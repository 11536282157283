import { useCallback, useMemo } from 'react';
import { EXPLORE_ELEMENT_ID } from '../components/Explore';
import { useMobile } from './useMobile';
import { MIN_HEADER_HEIGHT } from '../components/header/Header';
import { APP_NAVIGATION_TAB_HEIGHT_MOBILE } from '../components/AppNavigationTabs';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';

export const HEADER_OFFSET = MIN_HEADER_HEIGHT + 16; // 60px + 16px padding

export const useScrollTo = () => {
  const isMobile = useMobile();

  const scrollTo = useCallback(
    (element: HTMLElement) => {
      const exploreElement = document.getElementById(EXPLORE_ELEMENT_ID);
      if (!exploreElement) {
        throw new HttpsError(
          'not-found',
          `Element with  not found.`,
          `id ${EXPLORE_ELEMENT_ID}`,
        );
      }
      const headerOffset = isMobile
        ? MIN_HEADER_HEIGHT + APP_NAVIGATION_TAB_HEIGHT_MOBILE
        : HEADER_OFFSET;
      const topPosition =
        element.getBoundingClientRect().top +
        exploreElement.scrollTop -
        headerOffset;

      exploreElement.scrollTo({
        top: topPosition,
        behavior: 'smooth',
      });
    },
    [isMobile],
  );

  const scrollToId = useCallback(
    (elementId: string) => {
      const element = document.getElementById(elementId);
      if (!element) {
        throw new HttpsError(
          'not-found',
          `Element not found in scrollToId.`,
          `id: ${elementId}`,
        );
      }
      scrollTo(element);
    },
    [scrollTo],
  );

  const scrollToRef = useCallback(
    (ref: React.RefObject<HTMLElement>): void => {
      const element = ref.current;
      if (!element) {
        throw new HttpsError(
          'not-found',
          `Ref does not have an element for ref.current in scrollToRef.`,
          `ref: ${ref}`,
        );
      }
      scrollTo(element);
    },
    [scrollTo],
  );

  return useMemo(() => {
    return { scrollToRef, scrollToId };
  }, [scrollToRef, scrollToId]);
};
