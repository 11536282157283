import { extractErrorMessage } from '../../functions/src/util/errors/extractErrorMessage';

export const findErrorFrom = (
  error: unknown,
  errorLibrary: Record<string, string>,
) => {
  const message = extractErrorMessage(error).toLowerCase();
  const errorCodes = Object.keys(errorLibrary);
  const foundError =
    errorCodes.find((errorCode) => {
      return message.includes(errorCode.toLowerCase());
    }) || 'Unknown Error';
  return { title: foundError, message: errorLibrary[String(foundError)] };
};
