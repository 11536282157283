import { useEffect, useState } from 'react';
import { useDynamic } from './useDynamic';
import { toStatusPath } from '../../functions/src/types/realtimeDb/Status/path';
import { ConnectionSentinel } from '../util/realtime/ConnectionSentinel';

export const useOnlineStatus = (userIds: (string | undefined)[]) => {
  const [userStatuses, setUserStatuses] = useState<Record<string, boolean>>({});

  const firebaseDatabaseModule = useDynamic(import('firebase/database'));
  const databaseModule = useDynamic(
    import('../config/firebase-client/database'),
  );

  useEffect(() => {
    if (!databaseModule || !firebaseDatabaseModule) {
      return;
    }

    const { ref } = firebaseDatabaseModule;
    const { database } = databaseModule;

    const userConnectionSentinels = userIds.reduce<ConnectionSentinel[]>(
      (sentinels, userId) => {
        if (!userId) {
          return sentinels;
        }

        const statusRef = ref(database, toStatusPath(userId));
        const sentinel = new ConnectionSentinel({
          realtimeRef: statusRef,
          onConnect: () => {
            setUserStatuses((prev) => {
              return {
                ...prev,
                [userId]: true,
              };
            });
          },
          onDisconnect: () => {
            return {
              status: 'offline',
            };
          },
        });

        sentinel.connect();
        sentinels.push(sentinel);
        return sentinels;
      },
      [],
    );

    return () => {
      userConnectionSentinels.forEach((sentinel) => {
        sentinel.disconnect();
      });
    };
  }, [databaseModule, firebaseDatabaseModule, userIds]);

  return userStatuses;
};
