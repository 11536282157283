import { MatchAggregated } from '../../../types/firestore/Game/Tournament/Bracket';

export const isSessionCompleted = <TTime>(
  sessionIndex: number,
  {
    team1Scores = [],
    team2Scores = [],
  }: Pick<MatchAggregated<TTime>, 'team1Scores' | 'team2Scores'>,
) => {
  return [
    team1Scores[Number(sessionIndex)],
    team2Scores[Number(sessionIndex)],
  ].every((score) => {
    return score === 0 || score === 1;
  });
};
