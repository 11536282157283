import { Tournament } from '../../../functions/src/types/firestore/Game/Tournament';

export const toEventUrl = ({
  id,
  gameId,
  roles,
}: Pick<Tournament<Date>, 'id' | 'gameId' | 'roles'>) => {
  const { contributor } = roles;
  const contributorId = contributor[0];

  const eventDocumentPath = `Game/${gameId}/Tournament/${id}`;

  const eventPath = encodeURIComponent(eventDocumentPath);

  return {
    eventUrlPath: `${contributorId}/selected?event=${eventPath}`,
    eventDocumentPath,
  };
};
