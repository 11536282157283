import { useCallback, useMemo } from 'react';
import { DialogActionsStandard } from '../../dialog/DialogActionsStandard';
import { useWizard } from '../../wizard/Wizard';
import { EventFlowBase } from './EventFlowBase';
import { useAuth } from '../../../contexts/AuthContext';

export function DeleteTournamentSuccess() {
  const { uid } = useAuth();
  const profileHref = useMemo(() => {
    if (!uid) {
      return undefined;
    }
    return `/user/${uid}/organizer`;
  }, [uid]);

  const { go } = useWizard();

  const createNew = useCallback(() => {
    return go('Select Starting Point');
  }, [go]);

  const close = useCallback(() => {
    go(undefined);
  }, [go]);

  return (
    <EventFlowBase
      title="Tournament deleted"
      Content={
        <DialogActionsStandard
          buttons={[
            {
              isAsync: false,
              onClick: createNew,
              children: 'Create Another',
            },
            {
              isAsync: false,
              href: profileHref,
              onClick: close,
              children: 'Back to Profile',
            },
          ]}
        />
      }
    />
  );
}
