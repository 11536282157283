import { useCallback } from 'react';
import { SelectGame } from '../../components/organizer/new-event-wizard/SelectGame';
import { useWizardDialog } from '../wizard/useWizardDialog';
import { SelectCreationMethod } from '../../components/organizer/new-event-wizard/SelectCreationMethod';
import { SelectEventType } from '../../components/organizer/new-event-wizard/SelectEventType';
import { CreateEventProvider } from '../../contexts/organizer/create-event/CreateEventContext';
import { CopyEventCalendar } from '../../components/organizer/new-event-wizard/CopyEventCalendar';
import { EventFrequency } from '../../components/organizer/new-event-wizard/EventFrequency';
import { DeleteTournamentSuccess } from '../../components/organizer/new-event-wizard/DeleteTournamentSuccess';
import { EventType } from '../../../functions/src/types/EventType';

export type CreateEventStore = {
  gameId: string;
  eventType: EventType;
  eventId: string;
  method: 'create' | 'copy';
};

export const CREATE_EVENT_ELEMENTS = {
  'Select Game': <SelectGame />,
  'Select Starting Point': <SelectCreationMethod />,
  'Select Event Type': <SelectEventType />,
  'Copy Event': <CopyEventCalendar />,
  'Event Frequency': <EventFrequency />,
  'Delete Tournament Success': <DeleteTournamentSuccess />,
};

export function useCreateEventWizard(
  startDialog?: keyof typeof CREATE_EVENT_ELEMENTS,
) {
  const { open: openWizardDialog } = useWizardDialog();
  const open = useCallback(
    (props?: any) => {
      const storeDefault = {
        gameId: 'magiccraft',
        eventType: 'copy',
        eventId: '',
        method: '',
      };

      openWizardDialog({
        storeDefault,
        elements: CREATE_EVENT_ELEMENTS,
        elementIdEntry: !!startDialog ? startDialog : 'Select Starting Point',
        Wrapper: <CreateEventProvider {...props} />,
        wrapperProps: { showCloseIcon: false },
      });
    },
    [openWizardDialog, startDialog],
  );
  return { open };
}
