import { MatchAggregated } from '../../../types/firestore/Game/Tournament/Bracket';
import { Loadable } from '../../isLoading';
import { findTeamIndex } from './findTeamIndex';

export const isCurrentSessionScoredBy = <TTime>(
  sessionIndex: number,
  {
    team1Scores = [],
    team2Scores = [],
  }: Pick<MatchAggregated<TTime>, 'team1Scores' | 'team2Scores'>,
  teamUserIds: string[][],
  uid?: Loadable<string>,
) => {
  const currentSession = sessionIndex + 1;
  const teamSessionScores = [team1Scores, team2Scores];
  const teamIndex = findTeamIndex(teamUserIds, uid);
  const lastIndex = (teamSessionScores[Number(teamIndex)]?.length || 0) - 1;
  return lastIndex !== -1 && lastIndex + 1 === currentSession;
};
