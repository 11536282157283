import { createContext, ReactNode, useContext, useMemo } from 'react';
import { memo } from '../../util/memo';
import { Tournament } from '../../../functions/src/types/firestore/Game/Tournament';
import { useAuth } from '../AuthContext';
import { useDocSnapshot } from '../../hooks/firestore/useDocSnapshot';
import { useCollectionSnapshot } from '../../hooks/firestore/useCollectionSnapshot';
import { Bracket } from '../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { Heat } from '../../../functions/src/types/firestore/Game/Tournament/Heat';

export type ActiveTournamentContextProps = {
  tournament?: Tournament<Date>;
  heats?: Heat<Date>[];
  bracket?: Bracket<Date>;
};

export const ActiveTournamentContext = createContext<
  ActiveTournamentContextProps | undefined
>(undefined);

export const useActiveTournament = () => {
  const context = useContext(ActiveTournamentContext);
  if (!context) {
    throw new Error(
      'useActiveTournament must be used within an ActiveTournamentProvider',
    );
  }
  return context;
};

export type ActiveTournamentProviderProps = {
  children: ReactNode;
};

export const ActiveTournamentProvider = memo(
  function ActiveTournamentProviderUnmemoized({
    children,
  }: ActiveTournamentProviderProps) {
    const { userData } = useAuth();

    const tournamentId = userData?.activeTournament?.tournamentId;
    const gameId = userData?.activeTournament?.gameId;
    const tournamentPath = useMemo(() => {
      if (!tournamentId || !gameId) {
        return;
      }
      return `Game/${gameId}/Tournament/${tournamentId}`;
    }, [gameId, tournamentId]);

    const bracketPath = useMemo(() => {
      if (!tournamentPath) {
        return;
      }
      return `${tournamentPath}/Bracket/${tournamentId}`;
    }, [tournamentId, tournamentPath]);

    const heatsPath = useMemo(() => {
      if (!tournamentPath) {
        return;
      }
      return `${tournamentPath}/Heat`;
    }, [tournamentPath]);

    const tournament = useDocSnapshot<Tournament<Date>>(tournamentPath);
    const bracket = useDocSnapshot<Bracket<Date>>(bracketPath); //TODO: @shaffy9633 : we can directly fetch the match doc here in BLU-4200
    const heats = useCollectionSnapshot<Heat<Date>>(heatsPath);

    const tournamentData = useMemo(() => {
      return {
        tournament,
        bracket,
        heats,
      };
    }, [tournament, bracket, heats]);

    return (
      <ActiveTournamentContext.Provider value={tournamentData}>
        {children}
      </ActiveTournamentContext.Provider>
    );
  },
);
