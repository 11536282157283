import { TournamentRole } from '../../types/firestore/Game/Tournament';
import { RoleMap } from '../../types/Roles';
import { Loadable } from '../isLoading';

export const isModerator = (
  moderator?: RoleMap<TournamentRole>['moderator'],
  uid?: Loadable<string>,
) => {
  return !!uid && moderator?.includes(uid);
};
