/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect } from 'react';
import { IS_ELECTRON } from '../util/platform';

export const useActiveGameUpdates = () => {
  useEffect(() => {
    if (typeof window === 'undefined' || !IS_ELECTRON) {
      return;
    }

    if (window.electron?.onFetchSupportedGames) {
      window.electron.onFetchSupportedGames((data) => {
        console.log({ data });
      });
    } //TODO: @shaffy9633 store it somewhere
    const handler = async () => {
      try {
        const {
          onGameInfoUpdate: subscribeGameInfo,
          onGameEventUpdate: subscribeGameEvent,
        } = window.electron!;

        const { uploadElectronData } = await import(
          '../firebaseCloud/electron/uploadElectronData'
        );

        subscribeGameInfo(async ({ gameId, data, feature }) => {
          await uploadElectronData({
            gameId: `${gameId}` as const,
            kind: 'info',
            data,
            feature,
          });
        });
        subscribeGameEvent(async ({ gameId, feature, data }) => {
          await uploadElectronData({
            gameId: `${gameId}` as const,
            kind: 'event',
            data,
            feature,
          });
        });
      } catch (e) {
        console.error('Error setting up game update listeners:', e);
      }
    };
    handler();
  }, []);
};
