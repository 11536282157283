import { ReactNode } from 'react';
import { Hit, KeyedNode, OrNode } from '../../../functions/src/types/Hit';

/**
 * This should be memoized.
 *
 * Note: You CANNOT passing a React component directly to
 * the render prop. You must wrap it in an arrow function.
 */
export function renderHits<THit extends Hit>(
  hits: OrNode<THit>[],
  render: (hit: THit) => ReactNode,
) {
  return renderKeyedNodes(hits, (hit) => {
    return {
      key: hit.objectID,
      Node: render(hit),
    };
  });
}

/**
 * This should be memoized,
 */
export function renderKeyedNodes<TElement>(
  elements: OrNode<TElement>[],
  render: (element: TElement) => KeyedNode,
) {
  return elements.map((element) => {
    if (isKeyedNode(element)) {
      return element;
    }
    return render(element);
  });
}

export function isKeyedNode(element: unknown): element is KeyedNode {
  return (
    typeof element === 'object' &&
    !!element &&
    'Node' in element &&
    'key' in element
  );
}
