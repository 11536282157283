import { useCallback, useContext } from 'react';
import { useScoring } from '../../../contexts/ScoringContext';
import { TournamentContext } from '../../../contexts/docs/TournamentContext';
import { useWizard } from '../../../components/wizard/Wizard';
import { useErrorAlert } from '../../useErrorAlert';
import { SELF_MODERATION_ERROR_LIBRARY } from '../../../util/tournaments/selfModerationErrorLibrary';
import { useMatchDetailsRouter } from '../useMatchDetailsRouter';
import { useMatch } from '../../../components/tournaments/match/MatchProvider';
import { isScreenshotUploadedFor } from '../../../../functions/src/util/tournaments/moderation/isScreenshotUploadedFor';
import { useAuth } from '../../../contexts/AuthContext';

export const useScoreSession = () => {
  const { uid } = useAuth();
  const { id, team1, team2, screenshots } = useMatch();
  const { outcome, teamToScore } = useScoring();
  const [{ sessionIndex = 0 }] = useMatchDetailsRouter();
  const { id: tournamentId, gameId } = useContext(TournamentContext);
  const { catchError } = useErrorAlert(SELF_MODERATION_ERROR_LIBRARY);
  const { go } = useWizard();

  const isScreenshotUploaded = isScreenshotUploadedFor(
    sessionIndex,
    [team1?.acceptedUserIds || [], team2?.acceptedUserIds || []],
    screenshots,
    uid,
  );

  const goScreenshotUpload = useCallback(() => {
    go(!isScreenshotUploaded ? 'screenshot' : 'successfullyScored');
  }, [go, isScreenshotUploaded]);

  const scoreSessionUnsafe = useCallback(
    async (onSubmit?: () => void) => {
      if (!outcome || !teamToScore) {
        return;
      }

      const { scoreSession: score } = await import(
        '../../../firebaseCloud/tournament/moderation/scoreSession'
      );
      await score({
        gameId,
        matchId: id,
        tournamentId,
        claim: outcome,
        teamId: teamToScore.id,
        sessionIndex: sessionIndex,
      });
      onSubmit?.();
      goScreenshotUpload();
    },
    [
      gameId,
      goScreenshotUpload,
      id,
      outcome,
      sessionIndex,
      teamToScore,
      tournamentId,
    ],
  );

  const scoreSession = useCallback(
    async (onSubmit?: () => void) => {
      await catchError(
        () => {
          return scoreSessionUnsafe(onSubmit);
        },
        (e) => {
          console.error(e);
        },
      );
    },
    [catchError, scoreSessionUnsafe],
  );

  return { scoreSession };
};
