import {
  ChannelGroup,
  GroupFilterMap,
} from '../../types/firestore/User/ChannelGroup';
import { isSupport } from '../../types/firestore/User/ChannelGroup/util/isSupport';
import { isTournament } from '../../types/firestore/User/ChannelGroup/util/isTournament';
import { isDm } from '../../types/firestore/User/ChannelGroup/util/isDm';
import { AvatarGroupUser } from '../../types/firestore/User/ChannelGroup/util/avatarGroupUser';

export type ActiveChannelGroup = Omit<
  ChannelGroup<keyof GroupFilterMap, Date>,
  'imgUrl'
> & {
  avatarUsers: AvatarGroupUser[];
  isDm: boolean;
  isSupport: boolean;
  isTournament: boolean;
};

export const toActiveChannelGroup = (
  channelGroup: ChannelGroup<keyof GroupFilterMap, Date>,
): ActiveChannelGroup => {
  const { imgUrl, ...rest } = channelGroup;
  const { mainUser, members } = rest;

  const isDmChannel = isDm(channelGroup);
  const isSupportChannel = isSupport(channelGroup);
  const isTournamentChannel = isTournament(channelGroup);

  const avatarUsers =
    !members || members.length === 0 || isSupportChannel
      ? [{ imgUrl }]
      : members.reduce((acc, { userId, image }) => {
          if (userId !== mainUser) {
            acc.push({ userId, imgUrl: image });
          }
          return acc;
        }, [] as AvatarGroupUser[]);

  return {
    ...rest,
    avatarUsers,
    isDm: isDmChannel,
    isSupport: isSupportChannel,
    isTournament: isTournamentChannel,
  };
};
