import {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { memo } from '../util/memo';
import { useAuth } from './AuthContext';
import { useRouterState } from '../hooks/routing/useRouterState';
import { useTournamentPermissions } from '../hooks/tournaments/useTournamentPermissions';
import { RefereeHeader } from '../components/referee-portal/RefereeHeader';
import { TournamentContext } from './docs/TournamentContext';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';

export type DetailsOptions = {
  keySummary?: string;
  keysExcluded?: Record<string, unknown>;
  captainOnly?: boolean;
  summaryOnly?: boolean;
};

export type RefereeContextType = {
  gameId?: string;
  detailsOptions?: DetailsOptions;
  setDetailsOptions?: Dispatch<SetStateAction<DetailsOptions | undefined>>;
  isUpdating: boolean;
  setIsUpdating: Dispatch<SetStateAction<boolean>>;
  keys: string[];
  setKeys: Dispatch<SetStateAction<string[]>>;
  openReferee: () => void;
  closeReferee: () => void;
  isReferee: boolean;
};

const defaultState: RefereeContextType = {
  isUpdating: false,
  setIsUpdating: () => {
    /* */
  },
  keys: [],
  setKeys: () => {
    /* */
  },
  openReferee: () => {
    /* */
  },
  closeReferee: () => {
    /* */
  },
  isReferee: false,
};

const RefereeContext = createContext(defaultState);

export function useReferee() {
  const context = useContext(RefereeContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useReferee must be used within a RefereeProvider',
    );
  }
  return useContext(RefereeContext);
}

export type RefereeProviderProps = {
  children: ReactNode;
};

export const RefereeProvider = memo(function RefereeProviderUnmemoized({
  children,
}: RefereeProviderProps) {
  const [referee, setReferee] = useRouterState({
    key: 'referee',
    default: 'true',
  });
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [detailsOptions, setDetailsOptions] = useState<
    Record<string, unknown> | undefined
  >(undefined);
  const { uid } = useAuth();
  const [keys, setKeys] = useState<string[]>([]);

  const set = useCallback(
    (isRefereeView: 'true' | 'false') => {
      setReferee(isRefereeView);
    },
    [setReferee],
  );

  const { isTournamentAdmin } = useTournamentPermissions();
  const { phase } = useContext(TournamentContext);
  const showReferee = isTournamentAdmin && phase !== 'unpublished';

  useEffect(() => {
    if (uid === null || !showReferee) {
      return set('false');
    }
    if (!referee && showReferee) {
      set('true');
    }
  }, [uid, showReferee, referee, set]);

  const isReferee = useMemo(() => {
    return referee !== 'false';
  }, [referee]);

  const openReferee = useCallback(() => {
    set('true');
  }, [set]);

  const closeReferee = useCallback(() => {
    set('false');
  }, [set]);

  const memoizedValue = useMemo(() => {
    return {
      openReferee,
      closeReferee,
      detailsOptions,
      setDetailsOptions,
      isUpdating,
      setIsUpdating,
      keys,
      setKeys,
      isReferee,
    };
  }, [closeReferee, detailsOptions, isReferee, isUpdating, keys, openReferee]);

  return (
    <RefereeContext.Provider value={memoizedValue}>
      {showReferee && <RefereeHeader />}
      {children}
    </RefereeContext.Provider>
  );
});
