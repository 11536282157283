import { useCallback } from 'react';
import { useConfirmDialog } from './useConfirmDialog';
import { useGuardSignIn } from './useGuardSignIn';
import { NoticeParagraphed } from '../components/NoticeParagraphed';
import {
  FIREBASE_USER_LOCAL_KEY_REGEX,
  USER_DATA_LOCAL_KEY,
} from '../contexts/AuthContext';
import { removeItem, removeItemByPattern } from '../util/webStorage';

export const DELETE_DIALOG_ID = 'DELETE_DIALOG_ID';
export const DELETE_ACCOUNT_PROMPT = 'Delete your account?';
export const DELETE_ACCOUNT_DESCRIPTION = `
You are about to delete your account. This action is irreversible and will result in the following:

1. **Loss of Access:** You will lose access to all services and features that require a user account on our platform.
2. **Data Deletion:** All personal data, preferences, and settings associated with your account will be permanently deleted. This includes your user profile and any associated content or records.
3. **No Recovery:** Once your account is deleted, it cannot be recovered. If you wish to use our services in the future, you will need to create a new account.

Please confirm that you understand these consequences and wish to proceed with deleting your account. If you have any data that you wish to save or need further assistance, please [contact our support team](/contact) before proceeding.
`;

export const useDeleteAccountDialog = () => {
  const { open: openConfirmDialog, close } = useConfirmDialog(DELETE_DIALOG_ID);
  const { guardSignIn } = useGuardSignIn();

  const deleteAccount = useCallback(
    async (onClose?: () => void | Promise<void>) => {
      try {
        const { deleteUser } = await import('../firebaseCloud/user/deleteUser');
        await deleteUser();
        removeItem(USER_DATA_LOCAL_KEY);
        removeItemByPattern(FIREBASE_USER_LOCAL_KEY_REGEX);
        onClose?.();
      } catch (e) {
        console.error(e);
      }
    },
    [],
  );

  const openConfirmDelete = useCallback(
    (onClose?: () => void | Promise<void>) => {
      openConfirmDialog({
        title: DELETE_ACCOUNT_PROMPT,
        description: <NoticeParagraphed text={DELETE_ACCOUNT_DESCRIPTION} />,
        confirmFunction: () => {
          return deleteAccount(onClose);
        },
        onClose,
        confirmProps: {
          color: 'error',
        },
        backProps: {
          color: 'primary',
        },
      });
    },
    [deleteAccount, openConfirmDialog],
  );

  const open = useCallback(
    (onClose?: () => void | Promise<void>) => {
      guardSignIn(
        () => {
          openConfirmDelete(onClose);
        },
        DELETE_ACCOUNT_PROMPT,
        onClose,
      )();
    },
    [guardSignIn, openConfirmDelete],
  );

  return { open, close };
};
