export const MP4_READY = 'video.asset.static_renditions.ready' as const;
export const VIDEO_READY = 'video.asset.ready' as const;
export const LIVESTREAM_COMPLETED =
  'video.asset.live_stream_completed' as const;
export const MUX_LIVESTREAM_SERVER_URL =
  'rtmps://global-live.mux.com:443/app' as const;
export const MUX_VOD_STREAM = 'on-demand' as const;
export const MUX_LOADING_THRESHOLD = 'viewport' as const;

export const IS_LIVE = {
  [VIDEO_READY]: true,
  [LIVESTREAM_COMPLETED]: false,
} as const;

export type MuxWebhookEvent = typeof VIDEO_READY | typeof LIVESTREAM_COMPLETED;
