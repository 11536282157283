import { track } from '@vercel/analytics';
import { detectPlatform } from '../platform';

export const trackEvent = (
  name: string,
  properties?: Record<string, string | number | boolean | null>,
) => {
  track(name, {
    platform: detectPlatform(),
    ...properties,
  });
};
