import {
  Context,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useReducer,
  useState,
} from 'react';
import { memo } from '../../../util/memo';
import { Tournament } from '../../../../functions/src/types/firestore/Game/Tournament';
import { EditableStepsProvider } from '../EditableStepsContext';
import {
  EventCreationSettingsAction,
  eventCreationSettingsReducer,
} from './reducer';
import {
  EventCreationSettings,
  EVENT_CREATION_SETTINGS_DEFAULT,
  EVENT_TYPE_TO_EDITABLE_FIELDS,
} from './constants';
import { HttpsError } from '../../../../functions/src/util/errors/HttpsError';

//TODO: | Giveaway
export type EventDocument<T = Date> = Tournament<T>;
export type CreateEventContextType<T extends EventDocument> = {
  event?: T;
  newGameId?: string;
  setNewGameId: Dispatch<SetStateAction<string | undefined>>;
  setEvent: Dispatch<SetStateAction<T | undefined>>;
  eventCreationSettings: EventCreationSettings;
  dispatchEventCreationSettings: Dispatch<EventCreationSettingsAction>;
};

// this 'any' is overwritten when the hook is used with a generic
const CreateEventContext = createContext<
  CreateEventContextType<any> | undefined
>(undefined);

export const CreateEventProvider: FC<{
  children: ReactNode;
  event: EventDocument;
  eventCreationSettings: EventCreationSettings;
}> = memo(function CreateEventProviderUnmemoized<T extends EventDocument>({
  children,
  event: eventInitial,
  eventCreationSettings:
    eventCreationSettingsInitial = EVENT_CREATION_SETTINGS_DEFAULT,
}) {
  const [event, setEvent] = useState<T | undefined>(eventInitial);
  const [newGameId, setNewGameId] = useState<string>();
  const [eventCreationSettings, dispatch] = useReducer(
    eventCreationSettingsReducer,
    eventCreationSettingsInitial,
  );
  return (
    <CreateEventContext.Provider
      value={{
        event,
        setEvent,
        newGameId,
        setNewGameId,
        eventCreationSettings,
        dispatchEventCreationSettings: dispatch,
      }}
    >
      <EditableStepsProvider
        documentPath={`Game/${event?.gameId}/${eventCreationSettings.eventType}/${event?.id}`}
        editableFields={
          EVENT_TYPE_TO_EDITABLE_FIELDS[eventCreationSettings?.eventType]
        }
        documentInitial={eventInitial}
      >
        {children}
      </EditableStepsProvider>
    </CreateEventContext.Provider>
  );
});

export function useCreateEventContext<T extends EventDocument>() {
  const context = useContext<CreateEventContextType<T>>(
    CreateEventContext as unknown as Context<CreateEventContextType<T>>,
  );
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useCreateEventContext must be used within a CreateEventProvider',
    );
  }
  return context;
}
