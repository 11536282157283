import { useMemo } from 'react';
import { useScreenshotTooltips } from '../useScreenshotTooltips';
import { ReportScreenshot } from '../../../components/tournaments/match/details/ReportScreenshot';
import { DeleteScreenshot } from '../../../components/tournaments/match/details/DeleteScreenshot';
import { ReplaceScreenshot } from '../../../components/tournaments/match/details/ReplaceScreenshot';

export const useScreenshotActions = (screenshotUrl: string) => {
  const buttons = useMemo(() => {
    return {
      replace: <ReplaceScreenshot screenshotUrl={screenshotUrl} />,
      delete: <DeleteScreenshot screenshotUrl={screenshotUrl} />,
      report: <ReportScreenshot screenshotUrl={screenshotUrl} />,
    };
  }, [screenshotUrl]);
  return useScreenshotTooltips(buttons);
};
