import { memo } from '../../util/memo';
import { SvgIconProps, SvgIconTypeMap } from '@mui/material/SvgIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useLinearGradient } from 'src/hooks/useLinearGradient';
import Tooltip from '@mui/material/Tooltip';

export type GradientIconProps = SvgIconProps & {
  IconComponent: OverridableComponent<SvgIconTypeMap>;
  gradientColor?: string;
  tooltipTitle?: string;
};

/**
 *
 * This only supports gradients that are defined in the src/styles/colors.ts file of the theme.
 */
export const GradientIcon = memo(function GradientIconUnmemoized({
  IconComponent,
  gradientColor = 'primary.horizontal',
  tooltipTitle = undefined,
  sx,
  children,
  ...props
}: GradientIconProps) {
  const gradientId = useLinearGradient(gradientColor);

  return (
    <Tooltip title={tooltipTitle}>
      <>
        <IconComponent
          className="visible"
          {...props}
          sx={{
            fill: `url(#${gradientId})`,
            ...sx,
          }}
        />
      </>
    </Tooltip>
  );
});
