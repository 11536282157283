import { createContext, ReactNode, useContext, useMemo } from 'react';
import { MatchAggregated } from '../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { memo } from '../../util/memo';
import { useActiveTournament } from './ActiveTournamentContext';
import { useAuth } from '../AuthContext';
import { MatchFinder } from '../../../functions/src/callable/tournament/bracket/MatchFinder';

export type ActiveMatchContextProps = MatchAggregated<Date> | undefined;

export const ActiveMatchContext =
  createContext<ActiveMatchContextProps>(undefined);

export const useActiveMatch = () => {
  const context = useContext(ActiveMatchContext);
  if (context === null) {
    throw new Error(
      'useActiveMatch must be used within an ActiveMatchProvider',
    );
  }
  return context;
};

export type ActiveMatchProviderProps = {
  children: ReactNode;
};

export const ActiveMatchProvider = memo(function ActiveMatchProviderUnmemoized({
  children,
}: ActiveMatchProviderProps) {
  const { userData } = useAuth();
  const { bracket } = useActiveTournament();

  const match = useMemo(() => {
    const { activeTournament } = userData || {};
    const { match: currentMatch } = activeTournament || {};
    if (!currentMatch || !bracket) {
      return;
    }
    const { match: matchFound } = new MatchFinder<Date>(
      [bracket],
      currentMatch,
      'id',
    );
    return matchFound;
  }, [bracket, userData]);

  return (
    <ActiveMatchContext.Provider value={match}>
      {children}
    </ActiveMatchContext.Provider>
  );
});
