import { useMemo } from 'react';
import { RouterState, useRouterState } from '../routing/useRouterState';
import { GroupTab } from '../../../functions/src/types/groupTab';
import { GroupDecoder } from '../../../functions/src/util/group/GroupDecoder';

export function useGroupRouterExternal() {
  const [groupId] = useRouterState({
    key: 'groupId',
    location: 'segment',
  }) as RouterState<string>;

  const groupDecoded = useMemo(() => {
    return groupId && GroupDecoder.fromBase62(groupId);
  }, [groupId]);

  const groupTabState = useRouterState({
    key: 'groupTab',
    location: 'segment',
    defaultValue: 'events' as GroupTab,
  }) as RouterState<GroupTab>;

  return useMemo(() => {
    return {
      groupId,
      groupDecoded,
      groupTabState,
    };
  }, [groupDecoded, groupId, groupTabState]);
}
