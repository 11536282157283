import { useEffect } from 'react';
import { useRouterState } from './routing/useRouterState';
import { useScrollTo } from './useScrollTo';

export function useInitialScrollTo() {
  const [scrollTo, setScrollTo] = useRouterState({ key: 'scroll-to' });
  const { scrollToId } = useScrollTo();

  useEffect(() => {
    if (scrollTo) {
      try {
        scrollToId(scrollTo);
      } catch (e) {
        console.error(e);
      }
      setScrollTo(undefined);
    }
  }, [scrollTo, scrollToId, setScrollTo]);
}
