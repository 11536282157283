import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { Link } from '../Link';
import { memo } from '../../util/memo';
import { ReactNode, useCallback, useMemo } from 'react';
import { CallerHit } from './CallersVerticalCarousel';
import { useCallerConnection } from '../../hooks/voice-chat/useCallerConnection';
import { ResponseButtons } from '../ResponseButtons';
import { useRoledUser } from '../../hooks/messaging/useRoledUser';
import { AvatarGroupNext } from '../avatar/AvatarGroupNext';
import { GradientTypography } from '../gradients/GradientTypography';
import { ELLIPSIS_SX } from '../../util/string';

export type CallerCardBaseProps = CallerHit & {
  children?: ReactNode;
  icon?: ReactNode;
};

const CallerCardBaseUnmemoized = ({
  icon,
  children,
  ...hit
}: CallerCardBaseProps) => {
  const theme = useTheme();
  const { hasAnyRole } = useRoledUser();
  const { accept, reject } = useCallerConnection();

  const { status, username, imgUrl, id: userId } = hit;

  const acceptRequest = useCallback(async () => {
    return await accept(userId);
  }, [accept, userId]);

  const rejectRequest = useCallback(async () => {
    return await reject(userId);
  }, [reject, userId]);

  const requestResponseButton = useMemo(() => {
    return (
      (status === 'requested' || status === 'rejected') &&
      (hasAnyRole ? (
        <ResponseButtons
          onAccept={acceptRequest}
          onReject={rejectRequest}
          disableReject={status === 'rejected'}
          buttonSx={{
            height: '24px',
            width: '24px',
            '.MuiSvgIcon-root': {
              mb: '6px !important',
            },
          }}
          iconSx={{ height: '18px', width: '18px' }}
        />
      ) : (
        <Chip
          label={status}
          sx={{
            backgroundColor: theme.palette.background.elevation[14],
            width: '100px',
            height: '42px',
            '.MuiChip-label': {
              p: 0,
            },
          }}
        />
      ))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, hasAnyRole, acceptRequest, rejectRequest]);

  const avatarUser = useMemo(() => {
    return [{ imgUrl, userId }];
  }, [imgUrl, userId]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{ px: 2, height: '48px' }}
    >
      <Link style={{ textDecoration: 'none' }} href={`/${userId}`}>
        <Stack direction="row" alignItems="center" spacing={4}>
          <AvatarGroupNext
            users={avatarUser}
            avatarSx={{ height: 36, width: 36 }}
            showStatus={false}
          />
          <Stack direction="row" alignItems="center" spacing="4px">
            <GradientTypography
              variant="subtitle1"
              sx={{
                maxWidth: '224px',
                ...ELLIPSIS_SX,
              }}
            >
              {username}
            </GradientTypography>
            {icon}
          </Stack>
        </Stack>
      </Link>
      <Stack direction="row" alignItems="center">
        {children}
        {requestResponseButton}
      </Stack>
    </Stack>
  );
};

export const CallerCardBase = memo(CallerCardBaseUnmemoized);
