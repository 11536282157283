import { useMatch } from '../../../components/tournaments/match/MatchProvider';
import { useAuth } from '../../../contexts/AuthContext';
import { isUserInMatch } from '../../../util/tournaments/isUserInMatch';
import { useTournamentPermissions } from '../useTournamentPermissions';

export const useInvolvedMatch = () => {
  const { uid } = useAuth();
  const { team1, team2 } = useMatch();
  const { isTournamentAdmin } = useTournamentPermissions();
  return isTournamentAdmin || isUserInMatch([team1, team2], uid);
};
