import { FC, useMemo, useCallback } from 'react';
import { memo } from '../../../util/memo';
import Typography from '@mui/material/Typography';
import { EventFlowBase } from './EventFlowBase';
import { GradientRadioGroup } from '../prize-pools/GradientRadioGroup';
import { useCreateEventContext } from '../../../contexts/organizer/create-event/CreateEventContext';
import { EVENT_TYPES } from '../../../../functions/src/types/EventType';
import { ComingSoonTooltip } from '../../coming-soon/ComingSoonTooltip';
import { useWizard } from '../../wizard/Wizard';

export const SelectEventType: FC = memo(function SelectEventTypeUnmemoized() {
  const { go } = useWizard();
  const { eventCreationSettings, dispatchEventCreationSettings } =
    useCreateEventContext();

  const goStartingPoint = useCallback(() => {
    return go('Select Starting Point');
  }, [go]);

  const items = useMemo(() => {
    return EVENT_TYPES.map((type) => {
      const isDisabled = type !== 'Tournament';
      const label = (
        <Typography
          variant="subtitle1"
          color={isDisabled ? 'text.disabled' : 'text.primary'}
        >
          {type}
        </Typography>
      );

      return {
        key: type,
        label: isDisabled ? (
          <ComingSoonTooltip title="Other event types">
            {label}
          </ComingSoonTooltip>
        ) : (
          label
        ),
        disabled: isDisabled,
      };
    });
  }, []);

  return (
    <EventFlowBase
      title="Select Event Type"
      subtitle="Choose the type of event you would like to create"
      continueButtonProps={{
        isAsync: false,
        onClick: goStartingPoint,
      }}
      Content={
        <GradientRadioGroup
          onValueChange={(index) => {
            return dispatchEventCreationSettings({
              type: 'SET_EVENT_TYPE',
              // eslint-disable-next-line security/detect-object-injection
              payload: EVENT_TYPES[index],
            });
          }}
          selectedIndex={EVENT_TYPES.indexOf(eventCreationSettings.eventType)}
          items={items}
        />
      }
    />
  );
});
