import { memo } from '../../util/memo';
import { StatusBadge } from '../status/StatusBadge';
import { AvatarNext, AvatarNextProps } from './AvatarNext';
import { SxProps } from '@mui/material/styles';
import { useMemo } from 'react';
import { OnlineStatusEnum } from '../../../functions/src/types/realtimeDb/Status';

export type AvatarStatusProps = AvatarNextProps & {
  showStatus?: boolean;
  avatarSx?: AvatarNextProps['sx'];
  badgeSx?: SxProps;
  imgUrl: string;
  onlineStatus: OnlineStatusEnum;
};

const AvatarStatusUnmemoized = ({
  showStatus = false,
  badgeSx = {},
  avatarSx = {},
  onlineStatus,
  imgUrl,
  height,
  width,
}: AvatarStatusProps) => {
  const avatar = useMemo(() => {
    return (
      <AvatarNext src={imgUrl} height={height} width={width} sx={avatarSx} />
    );
  }, [imgUrl, height, width, avatarSx]);

  if (!showStatus) {
    return avatar;
  }

  return (
    <StatusBadge color={onlineStatus} sx={badgeSx}>
      {avatar}
    </StatusBadge>
  );
};

export const AvatarStatus = memo(AvatarStatusUnmemoized);
