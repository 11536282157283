import { ALPHABET } from '../Strings';
import basex from 'base-x';
import { HttpsError } from '../errors/HttpsError';
import { Base256Converter } from './Base256Converter';

export interface BaseConverter {
  encode(buffer: Uint8Array | number[]): string;
  decodeUnsafe(string: string): Uint8Array | undefined;
  decode(string: string): Uint8Array;
}

/**
 * A class for encoding and decoding values using a custom alphabet.
 * Internally uses base-x for the encoding/decoding logic.
 */
export class BaseX implements BaseConverter {
  private readonly converter: BaseConverter;
  private readonly textEncoder = new TextEncoder();
  private readonly textDecoder = new TextDecoder();

  /**
   * Creates a new BaseX instance.
   * @param alphabet - The alphabet to use for encoding/decoding. Must contain at least 2 unique characters.
   * @throws HttpsError if the alphabet is invalid
   */
  public constructor(public readonly alphabet: string = ALPHABET) {
    if (alphabet.length < 2) {
      throw new HttpsError(
        'invalid-argument',
        'Alphabet must be at least 2 characters long',
        { alphabet, length: alphabet.length },
      );
    }

    const charCounts = new Map<string, number>();
    for (const char of alphabet) {
      const count = (charCounts.get(char) || 0) + 1;
      if (count > 1) {
        throw new HttpsError(
          'invalid-argument',
          `Alphabet contains duplicate character: "${char}"`,
          { alphabet, char, count },
        );
      }
      charCounts.set(char, count);
    }

    this.converter =
      alphabet.length === 256 ? new Base256Converter() : basex(alphabet);
  }

  public encode(buffer: Uint8Array | number[]): string {
    return this.converter.encode(buffer);
  }
  
  public decodeUnsafe(string: string): Uint8Array | undefined {
    return this.converter.decodeUnsafe(string);
  }

  public decode(string: string): Uint8Array {
    return this.converter.decode(string);
  }

  /**
   * Gets the padding character (first character of the alphabet)
   */
  public get padChar(): string {
    return this.alphabet[0]!;
  }

  /**
   * Encodes a UTF-8 string
   */
  encodeUtf8(utf8: string): string {
    const byteArray = this.textEncoder.encode(utf8);
    return this.converter.encode(byteArray);
  }

  /**
   * Decodes a string into UTF-8
   */
  decodeUtf8(encoded: string): string {
    const byteArray = this.converter.decode(encoded);
    return this.textDecoder.decode(byteArray);
  }

  /**
   * Encodes a non-negative number
   */
  encodeUint(uint: number): string {
    return this.encodeBigUint(BigInt(uint));
  }

  /**
   * Encodes a non-negative BigInt
   */
  encodeBigUint(bigUint: bigint): string {
    if (bigUint < BigInt(0)) {
      throw new HttpsError('invalid-argument', `BigInt must be non-negative`, {
        value: bigUint.toString(),
      });
    }

    const byteArray: number[] = [];
    let remaining = bigUint;

    while (remaining > BigInt(0)) {
      byteArray.unshift(Number(remaining & BigInt(0xff)));
      remaining >>= BigInt(8);
    }

    if (byteArray.length === 0) {
      byteArray.push(0);
    }

    return this.converter.encode(byteArray) || this.padChar;
  }

  /**
   * Decodes a string into a number
   */
  decodeUint(encoded: string): number {
    return Number(this.decodeBigUint(encoded));
  }

  /**
   * Decodes a string into a BigInt
   */
  decodeBigUint(encoded: string): bigint {
    const byteArray = this.converter.decode(encoded);
    let result = BigInt(0);

    for (const byte of byteArray) {
      result = (result << BigInt(8)) | BigInt(byte);
    }

    return result;
  }
}

/**
 * Default base62 converter instance using digits and letters
 */
export const base62 = new BaseX();

/**
 * Base256 converter instance using all possible byte values
 */
export const base256 = new BaseX(
  Array.from({ length: 256 }, (_, i) => String.fromCharCode(i)).join(''),
);
