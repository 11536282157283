import { MatchAggregated } from '../../../types/firestore/Game/Tournament/Bracket';
import { isMatchOver } from './isMatchOver';
import { isMatchStarted } from './isMatchStarted';

export const activeSessionOf = <TTime>({
  team1Scores = [],
  team2Scores = [],
  winner,
}: Pick<MatchAggregated<TTime>, 'team1Scores' | 'team2Scores' | 'winner'>) => {
  if (!isMatchStarted({ team1Scores, team2Scores })) {
    return 0;
  }
  if (isMatchOver({ winner })) {
    return team1Scores.length - 1;
  }
  const teamSessionScores = [team1Scores, team2Scores];
  const equalSessionsPlayed = teamSessionScores.every((scores) => {
    return scores.length === teamSessionScores[0]?.length;
  });
  if (!equalSessionsPlayed) {
    const maxLength = Math.max(
      ...teamSessionScores.map((scores) => {
        return scores.length;
      }),
    );
    return maxLength - 1;
  }
  const latestIndex = team1Scores.length - 1;
  const latestScores = teamSessionScores.map((score) => {
    return score[latestIndex];
  });
  const hasDispute = new Set(latestScores).size !== latestScores.length;
  return !hasDispute ? latestIndex + 1 : latestIndex;
};
