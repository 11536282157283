import {
  SelectableRouted,
  SelectableRoutedProps,
} from '../../routing/SelectableRouted';
import { FC, ReactElement, useCallback, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { toEventUrl } from '../../../util/event/toEventUrl';
import { useRouterState } from '../../../hooks/routing/useRouterState';
import { RoleMap } from '../../../../functions/src/types/Roles';
import { Optional } from 'utility-types';

export type SelectableEventProps = {
  id: string;
  gameId: string;
  date: Date;
  roles: RoleMap<'contributor' | 'moderator' | 'streamer' | 'registrant'>;
};

export type SelectableEventWrapperProps = Optional<
  Pick<SelectableRoutedProps, 'routerOptions'>,
  'routerOptions'
> & {
  onChange?: () => void;
  children: ReactElement<SelectableEventProps>;
};

const SelectableEventWrapperUnmemoized: FC<SelectableEventWrapperProps> = ({
  children,
  onChange,
  routerOptions = { key: 'event', location: 'queryParam' },
}) => {
  const { date } = children.props;
  const [_, setCalendarDateParam] = useRouterState({
    key: 'event-date',
    silent: true,
  });

  const isPathNavigation = typeof routerOptions === 'string';

  const value = useMemo(() => {
    const { eventUrlPath, eventDocumentPath } = toEventUrl(children.props);
    return isPathNavigation ? eventUrlPath : eventDocumentPath;
  }, [children.props, isPathNavigation]);

  const changeEventDate = useCallback(() => {
    setCalendarDateParam(new Date(date).getTime().toString());
  }, [date, setCalendarDateParam]);

  const selectEvent = useCallback(
    (__: unknown, isSelected: boolean) => {
      onChange?.();
      if (!isSelected) {
        return;
      }
      changeEventDate();
    },
    [changeEventDate, onChange],
  );

  return (
    <SelectableRouted
      value={value}
      routerOptions={routerOptions}
      onChange={selectEvent}
      borderRadius="10px"
    >
      {children}
    </SelectableRouted>
  );
};

export const SelectableEventWrapper = memo(SelectableEventWrapperUnmemoized);
