import {
  ref,
  onValue,
  onDisconnect,
  Database,
  DatabaseReference,
  Unsubscribe,
  update,
} from 'firebase/database';
import { database as realtimeDb } from '../../config/firebase-client/database';

const RTDB_CONNECTION_PATH = '.info/connected' as const;

export type ConnectionSentinelSettings = {
  realtimeRef: DatabaseReference;
  onConnect?: () => Promise<void> | void;
  onDisconnect?: () =>
    | Promise<Record<string, unknown>>
    | Record<string, unknown>;
  rtDb?: Database;
};

export class ConnectionSentinel {
  private readonly connectedRef: DatabaseReference;
  private unsubscribe?: Unsubscribe;
  private disconnectData?: Record<string, unknown>;

  constructor(private readonly settings: ConnectionSentinelSettings) {
    this.connectedRef = ref(settings.rtDb || realtimeDb, RTDB_CONNECTION_PATH);
  }

  public connect() {
    this.unsubscribe = onValue(this.connectedRef, async (snapshot) => {
      if (snapshot.val()) {
        if (this.settings.onDisconnect) {
          this.disconnectData = await this.settings.onDisconnect();
          await onDisconnect(this.settings.realtimeRef).update(
            this.disconnectData,
          );
        }

        await this.settings.onConnect?.();
      }
    });
  }

  public async disconnect() {
    this.unsubscribe?.();
    if (this.disconnectData) {
      await update(this.settings.realtimeRef, this.disconnectData);
    }
  }
}
