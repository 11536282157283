import { useCallback } from 'react';
import { memo } from '../../util/memo';
import { Selectable, SelectableProps } from '../Selectable';
import { Optional } from 'utility-types';
import { useRouter } from '../../hooks/routing/useRouter';

export type SelectablePathProps = Optional<
  SelectableProps<string>,
  'onChange'
> & {
  routerOptions: string;
};

function SelectablePathUnmemoized({
  onChange,
  value,
  children,
  ...props
}: SelectablePathProps) {
  const router = useRouter();

  const changeState = useCallback<SelectableProps<string>['onChange']>(
    (newValue, newSelected) => {
      if (!newSelected) {
        return;
      }
      router.push(value);
      onChange?.(newValue, newSelected);
    },
    [onChange, router, value],
  );

  return (
    <Selectable
      {...props}
      value={value}
      isSelected={false}
      onChange={changeState}
    >
      {children}
    </Selectable>
  );
}

export const SelectablePath = memo(SelectablePathUnmemoized);
