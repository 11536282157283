import { TournamentRole } from '../../types/firestore/Game/Tournament';
import { isAdmin } from '../../types/firestore/User/util';
import { RoleMap } from '../../types/Roles';
import { Loadable } from '../isLoading';
import { isContributor } from './isContributor';
import { isModerator } from './isModerator';

export const isTournamentAdmin = (
  roles?: RoleMap<TournamentRole>,
  uid?: Loadable<string>,
  email?: string,
) => {
  const { contributor, moderator } = roles || {};
  return (
    isModerator(moderator, uid) ||
    isContributor(contributor, uid) ||
    isAdmin(email)
  );
};
