import { useMemo } from 'react';
import {
  UrlKey,
  RouterState,
  RouterStateGetter,
  useRouterState,
  UseRouterStateProps,
} from './useRouterState';
import { Required } from 'utility-types';

export type UseRouterStateFallbackProps = Required<UseRouterStateProps, 'defaultValue'> & {
  state?: RouterStateGetter;
};

export function useRouterStateFallback({
  state,
  ...routerOptions
}: UseRouterStateFallbackProps): RouterState {
  const [defaultStateValue, setDefaultStateValue] =
    useRouterState(routerOptions);

  const stateValue = useMemo(() => {
    return state?.[0] || defaultStateValue;
  }, [defaultStateValue, state]);

  const setStateValue = useMemo(() => {
    return state?.[1] || setDefaultStateValue;
  }, [state, setDefaultStateValue]);

  return [stateValue, setStateValue];
}
