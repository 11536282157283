import { useEffect, useState, useMemo } from 'react';
import { useRoom } from '../../contexts/RoomContext';
import { useAuth } from '../../contexts/AuthContext';
import { CallerStatus } from '../../../functions/src/types/realtimeDb/Room/Caller';
import { useVoiceChat } from '../../contexts/voice-chat/VoiceChatContext';

export const useCallerStatus = (userId?: string) => {
  const { rooms } = useRoom();
  const { mode } = useVoiceChat();
  const { userData } = useAuth();
  const [status, setStatus] = useState<CallerStatus | null>(null);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    // eslint-disable-next-line @blumintinc/blumint/consistent-callback-naming
    const handler = async () => {
      const { roomRef } = rooms[mode as string] || {};
      const id = userId || userData?.id;

      if (!roomRef || !id) {
        return;
      }
      const { onValue, child } = await import('firebase/database');

      const callerRef = child(roomRef, `callers/${id}`);

      unsubscribe = onValue(callerRef, (snapshot) => {
        const caller = snapshot.val() || {};
        setStatus(caller.status);
      });
    };

    handler();

    return () => {
      unsubscribe?.();
    };
  }, [userData, rooms, mode, userId]);

  const statusMemoized = useMemo(() => {
    return status;
  }, [status]);

  return { status: statusMemoized };
};
