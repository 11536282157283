import {
  MatchAggregated,
  Round,
} from '../../../types/firestore/Game/Tournament/Bracket';
import { Team } from '../../../types/firestore/Game/Tournament/Guestlist';
import { RequiredOnly } from '../../../types/utility-types';
import { HttpsError } from '../../../util/errors/HttpsError';
import { findCaseInsensitiveIn } from '../../../util/findCaseInsensitiveIn';
import { assertNoDuplicatesOf } from '../../../util/tournaments/assertNoDuplicatesOf';
//import { log } from '../../../util/log';

//@log
export class RoundsSecretary<TTime> {
  public constructor(public readonly rounds: Round<TTime>[]) {}
  public updateRound(roundModified: RequiredOnly<Round, 'id'>): void {
    const roundFound = this.findRound(roundModified.id);
    if (!roundFound) {
      throw Error(`Round ${roundModified} not found in bracket`);
    }
    Object.assign(roundFound, roundModified);
  }

  public findRound(roundId: string): Round<TTime> | undefined {
    return RoundsSecretary.findById(this.rounds, roundId);
  }

  private static findById<T extends { id: string }>(
    arr: T[],
    id: string,
  ): T | undefined {
    return arr.find((element) => {
      return element.id === id;
    });
  }

  public findMatchWith<TKey extends keyof MatchAggregated<TTime>>(
    key: TKey,
    value: MatchAggregated<TTime>[TKey],
  ): MatchAggregated<TTime> | undefined {
    return this.matches.find((match) => {
      // eslint-disable-next-line security/detect-object-injection
      return match[key] === value;
    });
  }

  public get matches(): MatchAggregated<TTime>[] {
    return this.rounds.flatMap((round) => {
      return [...(round.matches || [])];
    });
  }

  public findMatchFor(userId: string): MatchAggregated<TTime> | undefined {
    return this.matches.find(({ team1, team2 }) => {
      const allIds = [
        ...(team1?.acceptedUserIds || []),
        ...(team2?.acceptedUserIds || []),
      ];
      return findCaseInsensitiveIn(userId, allIds);
    });
  }

  public updateMatchWithWinner(
    match: MatchAggregated<TTime>,
    winner: Team<TTime>,
  ) {
    const { id } = winner;
    assertNoDuplicatesOf(id, match);
    const { team1 } = match;
    this.updateMatch(
      Object.assign(
        { id: match.id },
        !team1 ? { team1: winner } : { team2: winner },
      ),
    );
  }

  public updateMatch(
    matchModified: RequiredOnly<MatchAggregated<TTime>, 'id'>,
  ): void {
    const matchFound = this.findMatch(matchModified.id);
    if (!matchFound) {
      throw Error(`Match ${matchModified} not found in bracket`);
    }
    Object.assign(matchFound, matchModified);
  }

  public findMatch(matchId: string): MatchAggregated<TTime> | undefined {
    return RoundsSecretary.findById(this.matches, matchId);
  }

  public roundOf(matchId: string): Round<TTime> | undefined {
    return this.rounds[this.roundIndexOf(matchId)];
  }

  public roundIndexOf(matchId: string): number {
    return this.rounds.findIndex((round) => {
      return round.matches?.some((match) => {
        return match.id === matchId;
      });
    });
  }

  public findTeam(teamId: string): Team<TTime> | undefined {
    return RoundsSecretary.findById(this.teams, teamId);
  }

  public get teams(): Team<TTime>[] {
    return this.matches.flatMap((match) => {
      const teams: Team<TTime>[] = [];
      if (match.team1) {
        teams.push(match.team1);
      }
      if (match.team2) {
        teams.push(match.team2);
      }
      return teams;
    });
  }
  // public updateTeam(teamModified: RequiredOnly<Team<TTime>, 'id'>): void {
  //   const teamFound = this.findTeam(teamModified.id);
  //   if (!teamFound) {
  //     throw Error(`Team ${teamModified} not found in bracket`);
  //   }
  //   Object.assign(teamFound, teamModified);
  // }

  public updateTeam(
    teamModified: RequiredOnly<Team<TTime>, 'id'>,
    toAll = true,
  ): void {
    const teamsFound = RoundsSecretary.findAllById(this.teams, teamModified.id);

    if (teamsFound.length === 0 || !teamsFound[0]) {
      throw new HttpsError(
        'internal',
        'Team not found in bracket',
        teamModified,
      );
    }

    if (toAll) {
      teamsFound.forEach((teamFound) => {
        Object.assign(teamFound, teamModified);
      });
    } else {
      Object.assign(teamsFound[0], teamModified);
    }
  }

  private static findAllById<T extends { id: string }>(
    arr: T[],
    id: string,
  ): T[] {
    return arr.filter((element) => {
      return element.id === id;
    });
  }
}
