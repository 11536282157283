import { useState, useEffect } from 'react';
import { useSequentialUpdate } from 'src/hooks/useSequentialUpdate';
import type { Tournament } from '../../../functions/src/types/firestore/Game/Tournament';

export type UseTeamLockStatusParams = Pick<Tournament, 'gameId'> & {
  tournamentId: string;
  teamId: string;
  isLockedDefault: boolean;
  userId?: string;
};

export const useTeamLockStatus = ({
  gameId,
  tournamentId,
  teamId,
  userId,
  isLockedDefault,
}: UseTeamLockStatusParams) => {
  const [isTeamLocked, setIsTeamLocked] = useState<boolean>(isLockedDefault);

  const { updateValue } = useSequentialUpdate<boolean, void>({
    callableFn: async (newValue) => {
      const { lockTeam } = await import(
        '../../firebaseCloud/tournament/lockTeam'
      );
      await lockTeam({
        gameId,
        tournamentId,
        teamId,
        isLocked: newValue || false,
        userId: userId!,
      });
    },
  });

  useEffect(() => {
    if (!userId) {
      return;
    }
    updateValue(isTeamLocked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTeamLocked, userId]);

  return { isTeamLocked, setIsTeamLocked };
};
