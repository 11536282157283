import { Explore } from './Explore';
import { Framework } from './Framework';
import { HeaderTabbed } from './header/HeaderTabbed';
import { memo } from '../util/memo';
import { ReactNode } from 'react';
import { usePendingCheckIns } from '../hooks/tournaments/usePendingCheckIns';
import { Sidebar } from './Sidebar';
import { useWebSocketWarningSnackbar } from '../hooks/useWebSocketWarningSnackbar';
import { useInitialScrollTo } from '../hooks/useInitialScrollTo';
import { useActionHandler } from '../hooks/useActionHandler';
import { useAppUrlOpen } from '../hooks/capacitor/useAppUrlOpen';
import { useOAuthCustomApp } from '../hooks/auth/useOAuthCustomApp';
import { useTemporaryChannelGroupSyncer } from '../hooks/messaging/useTemporaryChannelGroupSyncer';
import { useDeleteAccount } from '../hooks/useDeleteAccount';
import { useDatadog } from '../hooks/useDatadog';
import { useShowAdBlock } from '../hooks/ads/useShowAdBlock';
import { useChannelMover } from '../hooks/voice-chat/useChannelMover';
import { AdFooter } from './ads/AdFooter';
import Stack from '@mui/material/Stack';
import { useNextVoiceChannel } from '../hooks/voice-chat/useNextVoiceChannel';
import { useJoinCall } from '../hooks/voice-chat/useJoinCall';
import { useLeaveCall } from '../hooks/voice-chat/useLeaveCall';
import { useVerifyEmail } from '../hooks/auth/useVerifyEmail';
import { useElectronDeepLinking } from '../hooks/routing/useElectronDeepLinking';
import { useActiveGameUpdates } from '../hooks/useActiveGameUpdates';

export type MainLayoutProps = {
  children: ReactNode;
};

const MainLayoutUnmemoized = ({ children }: MainLayoutProps) => {
  useElectronDeepLinking();
  useActiveGameUpdates();
  useTemporaryChannelGroupSyncer();
  useWebSocketWarningSnackbar();
  usePendingCheckIns();
  useInitialScrollTo();
  useVerifyEmail();
  useActionHandler();
  useAppUrlOpen();
  useOAuthCustomApp();
  useDeleteAccount();
  useDatadog();
  useShowAdBlock();
  useNextVoiceChannel();
  useChannelMover();
  useJoinCall();
  useLeaveCall();
  return (
    <Stack height="100dvh" my={-6} sx={{ overflow: 'hidden' }}>
      <HeaderTabbed />
      <Framework
        Sidebar={<Sidebar />}
        Explore={<Explore>{children}</Explore>}
        sx={{
          flexGrow: 1,
          minHeight: 0,
        }}
      />
      <AdFooter />
    </Stack>
  );
};

export const MainLayout = memo(MainLayoutUnmemoized);
