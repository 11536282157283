import useBaseIntersectionObserver, {
  IntersectionObserverOptions,
} from '@react-hook/intersection-observer';
import { RefObject, useMemo } from 'react';
import { findScrollableAncestor } from '../../util/findScrollableAncestor';
import { stableHash } from '../../../functions/src/util/hash/stableHash';

export type ExtendedIntersectionObserverOptions = Omit<
  IntersectionObserverOptions,
  'root'
> & {
  root?: IntersectionObserverOptions['root'] | 'nearest-scrollable';
};

/**
 * You do NOT need to memoize the second paramter of this hook because
 * changes to options do not cause re-renders.
 */
export function useIntersectionObserver<
  TElement extends HTMLElement = HTMLElement,
>(
  target: RefObject<TElement | null> | TElement | null,
  { root, ...options }: ExtendedIntersectionObserverOptions = {},
) {
  const element = useMemo(() => {
    return target instanceof Element ? target : target?.current || null;
  }, [target]);

  const rootFound = useMemo(() => {
    if (root !== 'nearest-scrollable') {
      return root;
    }
    if (!target) {
      return null;
    }
    return findScrollableAncestor(element);
  }, [element, root, target]);

  const baseOptions: IntersectionObserverOptions = useMemo(() => {
    return {
      ...options,
      root: rootFound,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stableHash(options), rootFound]);

  return useBaseIntersectionObserver(element, baseOptions);
}
