import { useCallback, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useLinkAdditionalAuthDialog } from './auth/useLinkAdditionalAuthDialog';
import { useMenuItemsUnlink } from './auth/useMenuItemsUnlink';
import { useMenuItemsWallet } from './web3/useMenuItemsWallet';
import AddLinkIcon from '@mui/icons-material/AddLinkRounded';
import PersonIcon from '@mui/icons-material/PersonRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverRounded';
import { Link } from '../components/Link';
import LogoutIcon from '@mui/icons-material/LogoutRounded';
import { SignInMenuItemProps } from '../components/header/SignInMenuItem';
import { useDeleteAccountRouter } from './useDeleteAccountRouter';
import { useSignOut } from './auth/useSignOut';

export const VIEW_PROFILE_TEXT = 'View Profile';
export const SIGN_IN_METHOD_TEXT = 'Sign In Method';
export const SIGN_OUT_TEXT = 'Sign Out';
export const DELETE_ACCOUNT_TEXT = 'Delete Account';

export const useSignInItems = (onMenuClose: () => void) => {
  const { uid } = useAuth();
  const { open: openDeleteAccountDialog } = useDeleteAccountRouter();
  const { open: openLinkAdditionalAuthDialog } = useLinkAdditionalAuthDialog();
  const { MenuItemsWallet } = useMenuItemsWallet();
  const { MenuItemsUnlink } = useMenuItemsUnlink();

  const { signOut } = useSignOut();
  const signOutClose = useCallback(async () => {
    await signOut();
    onMenuClose();
  }, [onMenuClose, signOut]);

  const menuItems: SignInMenuItemProps[] = useMemo(() => {
    return [
      {
        label: VIEW_PROFILE_TEXT,
        onClick: onMenuClose,
        IconComponent: PersonIcon,
        Wrapper: <Link href={`/${uid}`} style={{ textDecoration: 'none' }} />,
      },
      {
        label: SIGN_IN_METHOD_TEXT,
        onClick: openLinkAdditionalAuthDialog,
        IconComponent: AddLinkIcon,
      },
      ...MenuItemsWallet,
      ...MenuItemsUnlink,
      {
        label: DELETE_ACCOUNT_TEXT,
        onClick: openDeleteAccountDialog,
        IconComponent: DeleteForeverIcon,
      },
      {
        label: SIGN_OUT_TEXT,
        onClick: signOutClose,
        IconComponent: LogoutIcon,
      },
    ];
  }, [
    MenuItemsUnlink,
    MenuItemsWallet,
    onMenuClose,
    openDeleteAccountDialog,
    openLinkAdditionalAuthDialog,
    signOutClose,
    uid,
  ]);

  return menuItems;
};
