import { GenericSignInMethod } from 'functions/src/types/firestore/User';
import { AuthProvider } from 'firebase/auth';
import { webSignInWith } from './webSignInWith';
import { appSignInWith } from './appSignInWith';
import { isOnAndroid } from '../platform';

export const fetchOAuthProvider = async (
  provider: GenericSignInMethod,
): Promise<AuthProvider> => {
  const firebaseAuthImport = import('firebase/auth');
  const firebaseAuth = await firebaseAuthImport;
  const PROVIDER_MAP: Record<GenericSignInMethod, AuthProvider> = {
    // facebook: new firebaseAuth['FacebookAuthProvider'](),
    google: new firebaseAuth['GoogleAuthProvider'](),
    // twitter: new firebaseAuth['TwitterAuthProvider'](),
    apple: new firebaseAuth['OAuthProvider']('apple.com'),
  };
  return PROVIDER_MAP[String(provider)];
};

export const genericOAuthSignIn = (provider: GenericSignInMethod) => {
  if (!isOnAndroid()) {
    return webSignInWith(provider);
  }
  return appSignInWith(provider);
};
