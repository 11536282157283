import Stack from '@mui/material/Stack';
import { ElectronToggleButton } from './ElectronToggleButton';
import { ElectronMinimizeButton } from './ElectronMinimizeButton';
import { ElectronCloseButton } from './ElectronCloseButton';
import { ElectronRefreshButton } from './ElectronRefreshButton';

export const ElectronTitleBar = () => {
  return (
    <Stack direction="row" alignItems="center">
      <ElectronRefreshButton />
      <ElectronMinimizeButton />
      <ElectronToggleButton />
      <ElectronCloseButton />
    </Stack>
  );
};
