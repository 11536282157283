import Stack from '@mui/material/Stack';
import Head from 'next/head';
import { REVALIDATE_SECONDS } from '../../util/vercel/revalidateSeconds';
import { GetStaticPaths, GetStaticProps } from 'next';
import { withUtcParam } from '../../util/dates/withUtcParam';
import { useTheme } from '@mui/material/styles';
import { injectSnapshots } from '../../components/snapshots/server/injectSnapshots';
import { exchangeRateStrategy } from '../../util/exchangeRates/exchangeRateStrategy';
import { SelectableEventsCalendar } from '../../components/calendar/selectable-event/SelectableEventsCalendar';

export const PUBLISHED_ONLY_TOURNAMENT_CONFIG =
  `(NOT phase:unpublished) OR (phase:finished AND visibility:unlisted)` as const;

export const ROUTER_OPTIONS = 'PATH' as const;

const HomePage = () => {
  const theme = useTheme();
  return (
    <>
      <Head>
        <title>Home - BluMint</title>
      </Head>
      <Stack
        sx={{
          px: { md: 2, xs: 0 },
          pt: 2,
          [theme.breakpoints.down(375)]: {
            mx: '-8px',
            pt: 0,
          },
        }}
      >
        <SelectableEventsCalendar routerOptions={ROUTER_OPTIONS} />
      </Stack>
    </>
  );
};

export const getStaticProps: GetStaticProps = () => {
  return injectSnapshots([exchangeRateStrategy])(() => {
    return {
      props: {},
      revalidate: REVALIDATE_SECONDS,
    };
  });
};

export const getStaticPaths: GetStaticPaths = withUtcParam(() => {
  return { paths: [], fallback: 'blocking' };
});

export { HomePage as default };
