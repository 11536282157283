import { useRef, useCallback, useEffect, useMemo } from 'react';
import { useVisibilityObserver } from './useVisibilityObserver';
import { sortedHash } from '../../../functions/src/util/hash/sortedHash';
import { useOnChange } from '../useOnChange';

/**
 * The key is in milliseconds.
 */
export type StopwatchCallbackMap = Record<number, (reset: () => void) => void>;

export type VisibilityStopwatchOptions = Omit<
  IntersectionObserverInit,
  'root' | 'threshold'
> & {
  pauseManually?: boolean;
  visibleAgainBehavior?: 'resume' | 'reset';
  threshold?: number;
};

/**
 * @param callbacks IF YOU DO NOT MEMOIZE THIS OBJECT, IT WILL CAUSE UNNECESSARY RE-RENDERS.
 */
export function useVisibilityStopwatch<
  TElement extends HTMLElement = HTMLElement,
>(
  target: TElement | null,
  options: VisibilityStopwatchOptions = {},
  callbacks: StopwatchCallbackMap = {},
) {
  const {
    visibleAgainBehavior = 'resume',
    pauseManually,
    threshold = 0,
    ...otherOptions
  } = options;

  const observerOptions = useMemo(() => {
    return {
      threshold,
      ...otherOptions,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedHash(otherOptions), threshold]);
  const observerResults = useVisibilityObserver({
    target,
    options: observerOptions,
  });
  const { isIntersecting, intersectionRatio } = observerResults;

  const elapsedTimeRef = useRef<number>(0);
  const startTimeRef = useRef<number | null>(null);

  const animationFrameRef = useRef<number | null>(null);
  const clearAnimationFrame = useCallback(() => {
    if (animationFrameRef.current !== null) {
      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = null;
    }
    startTimeRef.current = null;
  }, []);

  const triggeredCallbacksRef = useRef<Set<number>>(new Set());

  const reset = useCallback(() => {
    elapsedTimeRef.current = 0;

    clearAnimationFrame();

    triggeredCallbacksRef.current.clear();
  }, [clearAnimationFrame]);

  useOnChange(target, reset);

  const callbacksRef = useRef(callbacks);
  useEffect(() => {
    callbacksRef.current = callbacks;
  }, [callbacks]);

  const isVisible = isIntersecting && intersectionRatio >= threshold;
  const isVisibleRef = useRef(isVisible);
  useEffect(() => {
    isVisibleRef.current = isVisible;
  }, [isVisible]);

  const targetRef = useRef(target);
  useEffect(() => {
    targetRef.current = target;
  }, [target]);

  const updateElapsedTime = useCallback((timestamp: number) => {
    if (startTimeRef.current === null) {
      startTimeRef.current = timestamp;
    }

    const deltaTime = timestamp - startTimeRef.current;
    elapsedTimeRef.current += deltaTime;
    startTimeRef.current = timestamp;

    Object.entries(callbacksRef.current).forEach(([ms, callback]) => {
      const milliseconds = Number(ms);
      if (
        elapsedTimeRef.current >= milliseconds &&
        !triggeredCallbacksRef.current.has(milliseconds)
      ) {
        triggeredCallbacksRef.current.add(milliseconds);
        callback(reset);
      }
    });

    if (isVisibleRef.current && targetRef.current?.isConnected) {
      animationFrameRef.current = requestAnimationFrame(updateElapsedTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ensureAnimationFrame = useCallback(() => {
    if (animationFrameRef.current === null) {
      animationFrameRef.current = requestAnimationFrame(updateElapsedTime);
    }
  }, [updateElapsedTime]);

  useEffect(() => {
    if (pauseManually) {
      clearAnimationFrame();
      return;
    }
    if (isVisible) {
      ensureAnimationFrame();
      return;
    }

    clearAnimationFrame();
    if (visibleAgainBehavior === 'reset') {
      reset();
    }

    return () => {
      clearAnimationFrame();
    };
  }, [
    visibleAgainBehavior,
    reset,
    clearAnimationFrame,
    threshold,
    pauseManually,
    isVisible,
    ensureAnimationFrame,
  ]);

  return { reset, ...observerResults };
}
