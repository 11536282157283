import stringify from 'safe-stable-stringify';

const sortArray = <TArr>(array: TArr[]) => {
  if (
    array.every((item) => {
      return typeof item === 'number';
    })
  ) {
    (array as number[]).sort((a, b) => {
      return a - b;
    });
    return;
  }
  array.sort();
};

function sortObject<TObject extends Record<string, unknown>>(object: TObject) {
  const sortedObj: Record<string, unknown> = {};
  Object.keys(object)
    .sort()
    .forEach((key) => {
      const value = object[String(key)];
      if (Array.isArray(value)) {
        sortArray(value as unknown[]);
        sortedObj[key as string] = value;
      } else if (typeof value === 'object' && value !== null) {
        sortedObj[key as string] = sortObject(value as Record<string, unknown>);
      } else {
        sortedObj[key as string] = value;
      }
    });
  return sortedObj;
}

export function sortObjectArrays<TObject extends Record<string, unknown>>(
  object: TObject,
): TObject {
  const objectCopy = JSON.parse(stringify(object));
  sortObject(objectCopy);
  return objectCopy;
}
