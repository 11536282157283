export const checkGroupDenormalization = async (
  userId: string,
  groupId: string,
) => {
  const { firestore } = await import('../../config/firebase-client/firestore');
  const { doc, getDoc } = await import('firebase/firestore');
  const docRef = doc(
    firestore,
    `User/${userId}/GroupsDenormalization/${groupId}`,
  );
  const docSnap = await getDoc(docRef);
  return docSnap.exists();
};
