import { useCallback } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';

export const ElectronCloseButton = () => {
  const closeWindow = useCallback(() => {
    if (typeof window !== 'undefined' && window.electron) {
      window.electron.close();
    }
  }, []);
  return (
    <IconButton onClick={closeWindow}>
      <CloseRoundedIcon />
    </IconButton>
  );
};
