import {
  cloneElement,
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useState,
  useMemo,
} from 'react';
import { memo } from '../util/memo';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';

export type GlobalComponentsContextType = {
  union: (id: string, component: ReactElement) => void;
  remove: (id: string) => void;
};

const defaultState: GlobalComponentsContextType = {
  union: () => {
    /* */
  },
  remove: () => {
    /* */
  },
};

const GlobalComponentsContext = createContext(defaultState);

export function useGlobalComponentsContext() {
  const context = useContext(GlobalComponentsContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useGlobalComponentsContext must be used within a GlobalComponentsProvider',
    );
  }
  return context;
}

export type GlobalComponentsProviderProps = {
  children: ReactElement;
};

export function GlobalComponentsUnmemoized({
  components,
}: {
  components: { [key: string]: ReactElement };
}) {
  return (
    <>
      {Object.entries(components).map(([id, component]) => {
        return cloneElement(component, { key: id });
      })}
    </>
  );
}

export const GlobalComponents = memo(GlobalComponentsUnmemoized);

export const GlobalComponentsProvider = memo(function GlobalComponentsProvider({
  children,
}: GlobalComponentsProviderProps) {
  const [components, setComponents] = useState<{ [key: string]: ReactElement }>(
    {},
  );

  const union = useCallback((id: string, component: ReactElement) => {
    setComponents((prevComponents) => {
      if (prevComponents[String(id)]) {
        return prevComponents;
      }
      return { ...prevComponents, [id]: component };
    });
  }, []);

  const remove = useCallback((id: string) => {
    setComponents((prevComponents) => {
      if (!prevComponents[String(id)]) {
        return prevComponents;
      }
      const { [id]: removed, ...rest } = prevComponents;
      return rest;
    });
  }, []);

  const value = useMemo(() => {
    return { union, remove };
  }, [union, remove]);

  return (
    <GlobalComponentsContext.Provider value={value}>
      {children}
      <GlobalComponents components={components} />
    </GlobalComponentsContext.Provider>
  );
});
