import type { Timestamp } from 'firebase-admin/firestore';

export const ONLINE_STATUSES = [
  'online',
  'offline',
  'away',
  'do not disturb',
] as const;
export type OnlineStatusEnum = (typeof ONLINE_STATUSES)[number];

export type OnlineStatus<TTime = Timestamp> = {
  status: OnlineStatusEnum;
  lastChanged: TTime;
};
