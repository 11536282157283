/* eslint-disable class-methods-use-this */
import { Advancement } from 'functions/src/types/firestore/Progression';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { memo } from '../util/memo';
import { PromiseOrValue } from '../../functions/src/types/utility-types';
import { ProgressionReporterBase } from '../../functions/src/util/progression/ProgressionReporter';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';

export type ProgressionContextType = {
  advancementsByProgressionId: Record<string, Advancement[]>;
  getProgressionReporter: (progressionId: string) => ProgressionReporterBase;
};

const ProgressionContext = createContext<ProgressionContextType | undefined>(
  undefined,
);

export const ProgressionProvider = memo(function ProgressionProviderUnmemoized({
  children,
}: {
  children: ReactNode;
}) {
  const [advancementsByProgressionId, setAdvancementsByProgressionId] =
    useState<Record<string, Advancement[]>>({});

  const [reporterByProgressionId, setReporterByProgressionId] = useState<
    Record<string, ProgressionReporterBase>
  >({});

  const clearAdvancements = useCallback((progressionId: string) => {
    return setAdvancementsByProgressionId((prev) => {
      return {
        ...prev,
        [progressionId]: [],
      };
    });
  }, []);

  const addAdvancement = useCallback(
    (
      progressionId: string,
      advancementNew: Pick<Advancement, 'details' | 'name'>,
    ) => {
      return setAdvancementsByProgressionId((prev) => {
        return {
          ...prev,
          [progressionId]: [
            ...prev[String(progressionId)],
            { ...advancementNew, status: 'pending' },
          ],
        };
      });
    },
    [],
  );

  const advancementsRef = useRef(advancementsByProgressionId);
  useEffect(() => {
    advancementsRef.current = advancementsByProgressionId;
  }, [advancementsByProgressionId]);

  const getProgressionReporter = useCallback(
    (progressionId: string) => {
      if (reporterByProgressionId[String(progressionId)]) {
        return reporterByProgressionId[String(progressionId)];
      }

      class ProgressionReporterFromContext extends ProgressionReporterBase {
        constructor(public progressionId: string) {
          super();
        }

        public stage(advancement: Pick<Advancement, 'details' | 'name'>) {
          return addAdvancement(this.progressionId, advancement);
        }

        public end() {
          return clearAdvancements(this.progressionId);
        }

        public getCurrentAdvancements() {
          return advancementsRef.current[this.progressionId];
        }

        public updateProgressionWithNewAdvancements<
          T extends Advancement[] = Advancement[],
        >(advancementsNew: T): PromiseOrValue<void> {
          return setAdvancementsByProgressionId((prev) => {
            return { ...prev, [this.progressionId]: advancementsNew };
          });
        }
      }

      const reporterNew = new ProgressionReporterFromContext(progressionId);
      setReporterByProgressionId((prev) => {
        return { ...prev, [progressionId]: reporterNew };
      });
      reporterNew.updateProgressionWithNewAdvancements([]);
      return reporterNew;
    },
    [addAdvancement, clearAdvancements, reporterByProgressionId],
  );

  const memoizedValue = useMemo(() => {
    return {
      advancementsByProgressionId,
      getProgressionReporter,
    };
  }, [advancementsByProgressionId, getProgressionReporter]);

  return (
    <ProgressionContext.Provider value={memoizedValue}>
      {children}
    </ProgressionContext.Provider>
  );
});

export const useProgression = (progressionId: string) => {
  const context = useContext(ProgressionContext);
  if (context === undefined) {
    throw new HttpsError(
      'failed-precondition',
      'useProgression must be used within a ProgressionProvider',
    );
  }
  const { getProgressionReporter, advancementsByProgressionId } = context;

  const progressionReporter = useMemo(() => {
    return getProgressionReporter(progressionId);
  }, [getProgressionReporter, progressionId]);

  return {
    progressionReporter,
    advancements: advancementsByProgressionId[String(progressionId)] || [],
  };
};
