import Stack from '@mui/material/Stack';
import { memo } from '../../util/memo';
import { useMemo, useState, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import { toRoomPath } from '../../../functions/src/util/liveKit/toRoomPath';
import { VoiceChatWidget } from './VoiceChatWidget';
import { VoiceChatProvider } from '../../contexts/voice-chat/VoiceChatContext';
import { useRoom } from '../../contexts/RoomContext';
import { useConnectedRoom } from '../../hooks/voice-chat/useConnectedRoom';

export const VOICE_CHAT_MODE_STUDIO = 'studio' as const;
export const VOICE_CHAT_MODE_ARENA = 'arena' as const;

export type VoiceChatHubProps = {
  channelType?: string;
  channelId: string;
};

const VoiceChatHubUnmemoized = ({
  channelType,
  channelId,
}: VoiceChatHubProps) => {
  const { uid } = useAuth();
  const { initialize } = useRoom();
  const { channelGroupId } = useActiveChannelGroup();
  const [hoveredElement, setHoveredElement] = useState<string | null>(null);
  const { isConnectedRoom } = useConnectedRoom();

  const studioConnected = useMemo(() => {
    return isConnectedRoom('studio');
  }, [isConnectedRoom]);

  const arenaHovered = useMemo(() => {
    return hoveredElement === 'arena';
  }, [hoveredElement]);

  const studioHovered = useMemo(() => {
    return hoveredElement === 'studio';
  }, [hoveredElement]);

  const focusStudio = useCallback(() => {
    setHoveredElement('studio');
  }, []);

  const focusArena = useCallback(() => {
    setHoveredElement('arena');
  }, []);

  const unfocus = useCallback(() => {
    setHoveredElement(null);
  }, []);

  const roomPathStudio = useMemo(() => {
    if (!channelGroupId) {
      return;
    }

    return toRoomPath({
      segments: [channelGroupId, channelId, VOICE_CHAT_MODE_STUDIO],
    });
  }, [channelGroupId, channelId]);

  const roomPathArena = useMemo(() => {
    if (!channelGroupId) {
      return;
    }

    return toRoomPath({
      segments: [channelGroupId, channelId, VOICE_CHAT_MODE_ARENA],
    });
  }, [channelGroupId, channelId]);

  const voiceChatStudio = useMemo(() => {
    // NOTE: channelType should change from general to public after guild page is merged
    if (!channelGroupId || channelType !== 'general' || !roomPathStudio) {
      return;
    }

    initialize(roomPathStudio, VOICE_CHAT_MODE_STUDIO);

    return (
      !!uid && (
        <Stack
          onMouseEnter={focusStudio}
          onMouseLeave={unfocus}
          sx={{
            flex: studioConnected ? '1 1 1' : '1 1 0',
            width: {
              xs: arenaHovered
                ? '0px'
                : studioHovered
                ? '300px'
                : studioConnected
                ? '250px'
                : 'fit-content',
              md: arenaHovered
                ? '0px'
                : studioHovered
                ? '444px'
                : studioConnected
                ? '322px'
                : 'fit-content',
            },
            opacity: arenaHovered ? 0 : 1,
            transition: 'all 300ms',
          }}
        >
          <VoiceChatProvider mode={VOICE_CHAT_MODE_STUDIO}>
            <VoiceChatWidget />
          </VoiceChatProvider>
        </Stack>
      )
    );
  }, [
    arenaHovered,
    channelGroupId,
    channelType,
    initialize,
    focusStudio,
    unfocus,
    studioConnected,
    studioHovered,
    uid,
    roomPathStudio,
  ]);

  const voiceChatArena = useMemo(() => {
    if (!channelGroupId || !roomPathArena) {
      return;
    }

    initialize(roomPathArena, VOICE_CHAT_MODE_ARENA);

    return (
      <Stack
        onMouseEnter={focusArena}
        onMouseLeave={unfocus}
        sx={{
          flex: studioConnected ? '1 1 0' : '1 1 1',
          width: {
            xs: studioHovered
              ? '0px'
              : arenaHovered
              ? '300px'
              : !!voiceChatStudio
              ? '250px'
              : 'fit-content',
            md: studioHovered
              ? '0px'
              : arenaHovered
              ? '444px'
              : !!voiceChatStudio
              ? '322px'
              : '444px',
          },
          opacity: studioHovered ? 0 : 1,
          transition: 'all 300ms',
        }}
      >
        <VoiceChatProvider mode={VOICE_CHAT_MODE_ARENA}>
          <VoiceChatWidget />
        </VoiceChatProvider>
      </Stack>
    );
  }, [
    arenaHovered,
    channelGroupId,
    initialize,
    focusArena,
    unfocus,
    studioConnected,
    studioHovered,
    voiceChatStudio,
    roomPathArena,
  ]);

  return (
    <Stack
      direction="row"
      alignItems="flex-end"
      spacing={!!hoveredElement ? 0 : 1}
      sx={{
        px: 4,
        pt: 3,
        height: '100%',
        width: { xs: '335px', md: '476px' },
        overflow: 'hidden',
      }}
    >
      {voiceChatArena}
      {voiceChatStudio}
    </Stack>
  );
};

export const VoiceChatHub = memo(VoiceChatHubUnmemoized);
