import {
  ChannelGroup,
  PERSONAL_TYPES,
  PersonalType,
  FilterType,
  GroupFilterMap,
} from '..';
import { Timestamp } from 'firebase-admin/firestore';

export function isPersonal<TTime = Timestamp>(
  channelGroup: ChannelGroup<keyof GroupFilterMap, TTime>,
): channelGroup is ChannelGroup<Capitalize<PersonalType>, TTime> {
  const { groupFilter } = channelGroup;
  return (
    groupFilter.length === 1 &&
    PERSONAL_TYPES.includes(groupFilter[0].type as PersonalType)
  );
}

export function isSpecificPersonal<
  TType extends PersonalType,
  TTime = Timestamp,
>(
  channelGroup: ChannelGroup<keyof GroupFilterMap, TTime>,
  type: TType,
): channelGroup is ChannelGroup<Capitalize<TType>, TTime> {
  const { groupFilter } = channelGroup;
  return groupFilter.length === 1 && groupFilter[0].type === type;
}

export function isPersonalType(type: FilterType): type is FilterType {
  return PERSONAL_TYPES.includes(type as PersonalType);
}
