export const DEPLOYMENT_URL =
  process.env.NEXT_PUBLIC_DEPLOYMENT_URL ??
  process.env.NEXT_PUBLIC_VERCEL_URL ??
  ('blumint.com' as const);

export const ENVIRONMENT = DEPLOYMENT_URL.includes('localhost')
  ? 'local'
  : DEPLOYMENT_URL.includes('staging')
  ? 'staging'
  : 'production';

export const BLUMINT_PROTOCOL = 'blumint';
