import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useRouterState } from '../routing/useRouterState';
import {
  ENTRIES_IDS_MATCH,
  MatchEntryId,
  useMatchDetailsWizard,
} from './match-details/useMatchDetailsWizard';
import { MatchAggregated } from '../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { Tournament } from '../../../functions/src/types/firestore/Game/Tournament';

export const MATCH_DIALOG_KEY = 'match-session' as const;
export const SESSION_DIALOG_KEY = 'session-dialog' as const;
export const MATCH_ENTRY_KEY = 'match-entry' as const;

export const DEFAULT_MATCH_ROUTER_VALUES: ActiveMatchRouter = {
  matchId: undefined,
  sessionIndex: 0,
  entryId: 'details',
} as const;

export type ActiveMatchRouter = {
  matchId?: string;
  sessionIndex?: number;
  entryId?: MatchEntryId;
};

export type SetActiveMatchRouter = Dispatch<SetStateAction<ActiveMatchRouter>>;

export const useMatchDetailsRouter = (
  match?: MatchAggregated<Date>,
  tournament?: Tournament<Date>,
  isMatchDisabled = false,
): [ActiveMatchRouter, SetActiveMatchRouter] => {
  const { open: openMatchDetails } = useMatchDetailsWizard(isMatchDisabled);
  const { id } = match || {};

  const [matchId, setMatchId] = useRouterState({
    key: MATCH_DIALOG_KEY,
    location: 'queryParam',
    silent: true,
  });

  const [sessionIndexStr, setSessionIndexStr] = useRouterState({
    key: SESSION_DIALOG_KEY,
    location: 'queryParam',
    defaultValue: '0',
  });

  const [entryId, setEntryId] = useRouterState({
    key: MATCH_ENTRY_KEY,
    location: 'queryParam',
    defaultValue: 'details',
  });

  const sessionIndex = parseInt(sessionIndexStr || '0');
  const validatedEntryId = ENTRIES_IDS_MATCH.includes(entryId as MatchEntryId)
    ? (entryId as MatchEntryId)
    : 'details';

  const currentState: ActiveMatchRouter = useMemo(() => {
    return {
      matchId,
      sessionIndex,
      entryId: validatedEntryId,
    };
  }, [matchId, sessionIndex, validatedEntryId]);

  const setState: SetActiveMatchRouter = useCallback(
    (newState) => {
      const nextState =
        typeof newState === 'function' ? newState(currentState) : newState;
      setMatchId(nextState.matchId);
      setSessionIndexStr(nextState.sessionIndex?.toString());
      setEntryId(nextState.entryId);
    },
    [currentState, setEntryId, setMatchId, setSessionIndexStr],
  );

  const close = useCallback(() => {
    setState(DEFAULT_MATCH_ROUTER_VALUES);
  }, [setState]);

  // Effect to handle match details
  useEffect(() => {
    if (matchId === id && match && tournament) {
      openMatchDetails({
        onClose: close,
        match,
        tournament,
        entryId: validatedEntryId,
      });
    }
  }, [
    close,
    id,
    match,
    matchId,
    openMatchDetails,
    tournament,
    validatedEntryId,
  ]);

  return [currentState, setState];
};
