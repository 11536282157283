import { memo } from '../../../util/memo';
import { useSetMatchScreenshot } from '../../../hooks/tournaments/match-details/useSetMatchScreenshot';
import AddIcon from '@mui/icons-material/AddRounded';
import { ImageUploadButton } from '../../buttons/ImageUploadButton';
import Tooltip from '@mui/material/Tooltip';
import { SxProps } from '@mui/material/styles';

export type AddScreenshotProps = {
  buttonSx?: SxProps;
};

const AddScreenshotUnmemoized = ({ buttonSx }: AddScreenshotProps) => {
  const { addScreenshot } = useSetMatchScreenshot();
  return (
    <Tooltip
      title="Please upload a screenshot related to the tournament that you'd like to showcase. Remember, this will be public and viewable by everyone."
      placement="bottom"
    >
      <div style={{ display: 'flex', width: '100%' }}>
        <ImageUploadButton
          sx={{
            borderRadius: '50px',
            width: '100%',
            height: '40px',
            ...buttonSx,
          }}
          variant="contained"
          onChange={addScreenshot}
          startIcon={<AddIcon />}
        >
          SCREENSHOT
        </ImageUploadButton>
      </div>
    </Tooltip>
  );
};

export const AddScreenshot = memo(AddScreenshotUnmemoized);
