import Stack from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { GradientIcon } from './gradients/GradientIcon';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { memo } from '../util/memo';
import { useCallback } from 'react';
import { useSocialDrawer } from '../hooks/useSocialDrawer';
import { MIN_HEADER_HEIGHT } from './header/Header';
import { SIDEBAR_WIDTH } from './Sidebar';

const SideDrawerToggleUnmemoized = () => {
  const theme = useTheme();
  const { isSocialDrawerOpen, openSocialDrawer, closeSocialDrawer } =
    useSocialDrawer();

  const toggleSideDrawer = useCallback(() => {
    if (isSocialDrawerOpen) {
      closeSocialDrawer();
    } else {
      openSocialDrawer();
    }
  }, [closeSocialDrawer, isSocialDrawerOpen, openSocialDrawer]);

  return (
    <Stack
      width={`${SIDEBAR_WIDTH}px`}
      alignItems="center"
      justifyContent="center"
      sx={{
        background: theme.palette.background.elevationSolid[0],
        display: { xs: 'none', md: 'flex' },
        zIndex: theme.zIndex.appBar + 1,
        borderBottom: {
          xs: 'none',
          md: theme.border,
        },
        minHeight: `${MIN_HEADER_HEIGHT}px`,
      }}
    >
      <GradientIcon
        IconComponent={
          isSocialDrawerOpen ? MenuOpenRoundedIcon : MenuRoundedIcon
        }
        onClick={toggleSideDrawer}
        sx={{
          transform: 'scaleX(-1)',
          ':hover': {
            cursor: 'pointer',
          },
          height: '40px',
          width: '40px',
          zIndex: theme.zIndex.appBar + 1,
        }}
      />
    </Stack>
  );
};

export const SideDrawerToggle = memo(SideDrawerToggleUnmemoized);
