import { useMatch } from '../MatchProvider';
import { MatchCellButton } from './MatchCellIButton';
import { useCallback, useContext } from 'react';
import { memo } from '../../../../util/memo';
import { useMatchDetailsRouter } from '../../../../hooks/tournaments/useMatchDetailsRouter';
import { TournamentContext } from '../../../../contexts/docs/TournamentContext';
import { activeSessionOf } from '../../../../../functions/src/util/tournaments/moderation/activeSessionOf';

export type CellButtonDetailsProps = {
  isMatchDisabled: boolean;
};

export const CellButtonDetails = memo(function CellButtonDetailsUnmemoized({
  isMatchDisabled,
}: CellButtonDetailsProps) {
  const match = useMatch();
  const tournament = useContext(TournamentContext);
  const { id, team1Scores, team2Scores, winner } = match;
  const activeSessionIndex = activeSessionOf({
    team1Scores,
    team2Scores,
    winner,
  });
  const [_activeMatchRouter, setActiveMatchRouter] = useMatchDetailsRouter(
    match,
    tournament,
    isMatchDisabled,
  );

  const openWizard = useCallback(() => {
    setActiveMatchRouter({
      matchId: id,
      sessionIndex: activeSessionIndex,
      entryId: 'details',
    });
  }, [setActiveMatchRouter, id, activeSessionIndex]);

  return (
    <>
      <MatchCellButton onClick={openWizard} />
    </>
  );
});
