import { useEffect } from 'react';
import { useRouter } from './useRouter';
import { IS_ELECTRON } from '../../util/platform';
import { toFullUrl } from '../../../functions/src/util/toFullUrl';

export const useElectronDeepLinking = () => {
  const { push } = useRouter();
  useEffect(() => {
    if (!IS_ELECTRON) {
      return;
    }
    window?.electron?.onDeepLink(({ path, searchParams }) => {
      push(toFullUrl(path, searchParams));
    });
  }, [push]);
};
