import clsx from 'clsx';
import { useRouter } from 'src/hooks/routing/useRouter';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import {
  forwardRef,
  AnchorHTMLAttributes,
  useMemo,
  Fragment,
  useCallback,
} from 'react';
import { prependUtc } from 'functions/src/util/time/timezone';
import Box from '@mui/material/Box';
import { openInAppBrowser } from '../util/openInAppBrowser';
import { isOnAndroid } from '../util/platform';
import { BLUMINT_PROTOCOL } from '../../electron/src/util/configs';
// Add support for the sx prop for consistency with the other branches.
const Anchor = styled('a')({});

type NextLinkComposedProps = Omit<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  'href'
> &
  Omit<
    NextLinkProps,
    'href' | 'as' | 'onClick' | 'onMouseEnter' | 'onTouchStart'
  > & {
    to: NextLinkProps['href'];
    linkAs?: NextLinkProps['as'];
  };

export const NextLinkComposed = forwardRef<
  HTMLAnchorElement,
  NextLinkComposedProps
>(function NextLinkComposed(props, ref) {
  // eslint-disable-next-line no-shadow
  const { to, linkAs, replace, scroll, shallow, prefetch, locale, ...other } =
    props;

  return (
    <NextLink
      href={to}
      prefetch={prefetch}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref
      locale={locale}
    >
      <Anchor ref={ref} {...other} />
    </NextLink>
  );
});

export type LinkProps = {
  activeClassName?: string;
  as?: NextLinkProps['as'];
  href: NextLinkProps['href'];
  linkAs?: NextLinkProps['as']; // Useful when the as prop is shallow by styled().
  noLinkStyle?: boolean;
  onClickExternal?: () => void | Promise<void>;
  onError?: (error?: unknown) => void;
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
  Omit<MuiLinkProps, 'href' | 'sx'>;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Component(
  {
    activeClassName = 'active',
    as,
    className: classNameProps,
    href,
    linkAs: linkAsProp,
    locale,
    noLinkStyle,
    prefetch,
    replace,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    role, // Link doesn't have roles.
    // eslint-disable-next-line no-shadow
    scroll,
    shallow,
    target = undefined,
    onClickExternal,
    onError,
    ...other
  },
  ref,
) {
  const router = useRouter();
  const pathname = typeof href === 'string' ? href : href.pathname;
  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === pathname && activeClassName,
  });
  const isExternal =
    typeof href === 'string' &&
    (href.indexOf('http') === 0 ||
      href.indexOf('mailto:') === 0 ||
      href.indexOf(BLUMINT_PROTOCOL) === 0);

  const targetFinal = useMemo(() => {
    if (isOnAndroid()) {
      return undefined;
    }
    return target;
  }, [target]);

  const urlNormalized = href.toString();

  const openAndroidBrowser = useCallback(async () => {
    if (!isOnAndroid() || !isExternal) {
      return;
    }
    try {
      await openInAppBrowser(urlNormalized);
      onClickExternal?.();
    } catch (error) {
      onError?.(error);
    }
  }, [isExternal, urlNormalized, onClickExternal, onError]);

  const openNewTab = useCallback(async () => {
    try {
      if (!isExternal) {
        return;
      }
      window.open(urlNormalized, '_blank');
      await onClickExternal?.();
    } catch (error) {
      onError?.(error);
    }
  }, [isExternal, onClickExternal, onError, urlNormalized]);

  const externalLink = useMemo(() => {
    return isOnAndroid() ? (
      <Box
        onClick={openAndroidBrowser}
        ref={ref}
        className={className}
        {...other}
      >
        {other.children}
      </Box>
    ) : noLinkStyle ? (
      <Anchor className={className} ref={ref} onClick={openNewTab} {...other} />
    ) : (
      <MuiLink
        className={className}
        ref={ref}
        onClick={openNewTab}
        {...other}
      />
    );
  }, [openAndroidBrowser, ref, className, other, noLinkStyle, openNewTab]);

  if (isExternal) {
    return <Fragment>{externalLink}</Fragment>;
  }

  const linkAs = linkAsProp || as;
  const nextjsProps = {
    to: shallow ? prependUtc(router.asPath, href) : href,
    linkAs,
    replace,
    scroll,
    shallow,
    prefetch,
    locale,
  };

  if (noLinkStyle) {
    return (
      <NextLinkComposed
        className={className}
        ref={ref}
        {...nextjsProps}
        {...other}
        target={targetFinal}
      />
    );
  }

  return (
    <MuiLink
      component={NextLinkComposed}
      className={className}
      ref={ref}
      {...nextjsProps}
      {...other}
      target={targetFinal}
    />
  );
});
