import { useMemo } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { isAdmin } from '../../../../functions/src/types/firestore/User/util';
import { GroupDecoder } from '../../../../functions/src/util/group/GroupDecoder';
import {
  ADMIN_FILTERS,
  GUEST_FILTERS,
  normalUserFilters,
} from '../../../../functions/src/util/algolia/config/events';
import {
  onlyContributor,
  guildContributorFilters,
} from '../../../../functions/src/util/algolia/config/events/organizer';

export const useUserEventsFilters = (groupId?: string) => {
  const { user } = useAuth();

  return useMemo(() => {
    if (!user?.uid) {
      return GUEST_FILTERS;
    }

    if (isAdmin(user.email)) {
      return groupId ? guildContributorFilters(groupId) : ADMIN_FILTERS;
    }

    const baseFilters = normalUserFilters(user.uid);

    if (!groupId) {
      return baseFilters;
    }

    const decoder = GroupDecoder.fromBase62(groupId);
    if (decoder.groupType === 'user') {
      return `${baseFilters} AND ${onlyContributor(groupId)}`;
    }

    return `(${baseFilters}) AND ${guildContributorFilters(groupId)}`;
  }, [user?.uid, user?.email, groupId]);
};
