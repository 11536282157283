import { Timestamp } from '../../../../../config/firebaseAdmin';
import { Result as ResultAggregated } from './Result';
import { ScoreOption } from '../../Competition';
import { Round } from '../Bracket';
import { HeatEndpoint, QualificationConditions } from '..';
import { Token } from '../../../User/Payout';
import { Resolve } from '../../../../utility-types';
import { Identifiable } from '../../../../Identifiable';

export const SCORE_OPTION_DEFAULT = {
  name: 'PTS',
  units: 'pts',
  orderBy: 'desc',
} as ScoreOption;

export type HeatBlurb = {
  title: string;
  minimumRequirements: string[];
  qualifyingRequirements: string[];
  rules: string[];
};

export type Heat<T = Timestamp> = Resolve<
  Identifiable & {
    title?: string;
    startTime?: T;
    endTime?: T;
    finishedRoundCount?: number;
    activeRoundProgress?: number;
    next?: string;
    previous?: string[];
    rounds?: Round<T>[];
    /**
     * First place prize at index 0, second place price at index 1, etc..
     */
    payouts: { tokens: Token[] }[];
    results?: ResultAggregated[]; // TODO: not sustainable for very large heats
    blurb?: HeatBlurb;
    qualification?: QualificationConditions;
    scoreOptions?: ScoreOption[];
    endpoint?: HeatEndpoint;
    //TODO: add optionalColumns with correct type
    //TODO: add in optional columns to be displayed/tournament
  }
>;
