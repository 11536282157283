import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { memo } from '../util/memo';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';

export type SetGlobalIntervalParams = {
  id: string;
  intervalMs: number;
  callback: () => void;
};

export type GlobalIntervalContextProps = {
  setGlobalInterval: (params: SetGlobalIntervalParams) => () => void;
};

export const GlobalIntervalContext = createContext<
  GlobalIntervalContextProps | undefined
>(undefined);

export type GlobalIntervalProviderProps = {
  children: ReactNode;
};

export const useGlobalInterval = () => {
  const context = useContext(GlobalIntervalContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useGlobalInterval must be used within a GlobalIntervalProvider',
    );
  }
  return context;
};

const GlobalIntervalProviderUnmemoized: FC<GlobalIntervalProviderProps> = ({
  children,
}) => {
  const listenerIdCounter = useRef(0);
  const intervals = useRef<
    Record<
      string,
      {
        intervalId: NodeJS.Timeout;
        listeners: { id: string; callback: () => void }[];
      }
    >
  >({});

  const setGlobalInterval = ({
    id,
    intervalMs,
    callback,
  }: SetGlobalIntervalParams) => {
    const uniqueId = `${id}:${intervalMs}`;
    const callbackId = `${uniqueId}-${listenerIdCounter.current++}`;

    if (!intervals.current[`${uniqueId}`]) {
      intervals.current[`${uniqueId}`] = {
        intervalId: setInterval(() => {
          intervals.current[`${uniqueId}`].listeners.forEach(
            ({ callback: callbackInner }) => {
              callbackInner();
            },
          );
        }, intervalMs),
        listeners: [{ id: callbackId, callback }],
      };
      callback();
    } else {
      const existingListener = intervals.current[`${uniqueId}`].listeners.find(
        (listener) => {
          return listener.id === callbackId;
        },
      );

      if (!existingListener) {
        intervals.current[`${uniqueId}`].listeners.push({
          id: callbackId,
          callback,
        });
        callback();
      }
    }

    return () => {
      const intervalData = intervals.current[`${uniqueId}`];

      if (!intervalData) {
        return;
      }

      intervalData.listeners = intervalData.listeners.filter((listener) => {
        return listener.id !== callbackId;
      });

      if (intervalData.listeners.length === 0) {
        clearInterval(intervalData.intervalId);
        delete intervals.current[`${uniqueId}`];
      }
    };
  };

  const memoizedValue = useMemo(() => {
    return {
      setGlobalInterval,
    };
  }, []);

  return (
    <GlobalIntervalContext.Provider value={memoizedValue}>
      {children}
    </GlobalIntervalContext.Provider>
  );
};

export const GlobalIntervalProvider = memo(GlobalIntervalProviderUnmemoized);
