import { User } from '../../../functions/src/types/firestore/User';

export function minimizeUserData({
  id,
  username,
  usernameLowercase,
  email,
  imgUrl,
  level,
  xp,
  xpNextLevel,
  addresses,
  hidden: {
    auth,
    customProviders,
    customProviderIds,
    phone,
    chatToken,
    secret,
  },
  dateCreated,
  isAnonymous,
}: User<Date>): Partial<User<Date>> {
  return {
    id,
    username,
    usernameLowercase,
    email,
    socials: [],
    imgUrl,
    level,
    xp,
    xpNextLevel,
    likedPaths: [],
    itemIdsOwned: [],
    addresses,
    hidden: {
      notificationsId: [],
      auth,
      customProviders,
      customProviderIds,
      phone,
      chatToken,
      secret,
    },
    dateCreated,
    isAnonymous,
  };
}
