// eslint-disable-next-line no-restricted-imports
import type { UserInfo } from 'firebase/auth';
import { UserProviderInfo, AllSignInMethod } from '../../types/firestore/User';

export const formatProviderData = (data: UserInfo[]): UserProviderInfo[] => {
  return data.map(
    ({ displayName, email, photoURL, phoneNumber, providerId, uid }) => {
      return {
        displayName: displayName || '',
        email: email || '',
        photoURL: photoURL || '',
        phoneNumber: phoneNumber || '',
        providerId: providerId as AllSignInMethod,
        uid,
      };
    },
  );
};
