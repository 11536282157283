import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { memo } from '../../../util/memo';
import { DialogActionsStandard } from '../../dialog/DialogActionsStandard';
import { useWizard } from '../../wizard/Wizard';
import { ImageInputBasic } from '../../data-entry-ui/generic/ImageInputBasic';
import { useSetMatchScreenshot } from '../../../hooks/tournaments/match-details/useSetMatchScreenshot';
import { EventFlowBase } from '../../organizer/new-event-wizard/EventFlowBase';

export const ScreenshotUploadPortal = memo(
  function ScreenshotUploadPortalUnmemoized() {
    const { go } = useWizard();
    const [urlImage, setUrlImage] = useState<string>();
    const { addScreenshot, replaceScreenshot } = useSetMatchScreenshot();
    const next = useCallback(() => {
      go('successfullyScored');
    }, [go]);

    const upload = useCallback(
      async (event: ChangeEvent<HTMLInputElement>) => {
        try {
          if (!urlImage || urlImage === '') {
            const url = await addScreenshot(event);
            setUrlImage(url);
            return;
          }
          const urlReplaced = await replaceScreenshot(event, urlImage);
          setUrlImage(urlReplaced);
        } catch (e) {
          console.error(e);
        }
      },
      [addScreenshot, replaceScreenshot, urlImage],
    );

    const content = useMemo(() => {
      return (
        <>
          <ImageInputBasic
            value={urlImage || ''}
            required={false}
            imageProperties={{ width: 512, height: 148 }}
            label={'Click to upload screenshot'}
            onUpload={upload}
          />
          <DialogActionsStandard
            buttons={[
              {
                isAsync: false,
                children: 'Skip',
                onClick: next,
                variant: 'outlined',
                disabled: !!urlImage,
                sx: { width: '25%', height: '42px' },
              },
              {
                isAsync: false,
                children: 'Submit',
                color: 'primary',
                onClick: next,
                sx: { width: '75%' },
                disabled: !urlImage || urlImage === '',
              },
            ]}
          />
        </>
      );
    }, [next, upload, urlImage]);

    return <EventFlowBase title={'Upload Screenshot'} Content={content} />;
  },
);
