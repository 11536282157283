import {
  MatchAggregated,
  BracketRoundsKeys,
  Bracket,
  BRACKET_ROUNDS_FIELDS,
  Round,
} from '../../../types/firestore/Game/Tournament/Bracket';
import { RoundsSecretary } from './RoundsSecretary';

export class MatchFinder<TTime> {
  public match: MatchAggregated<TTime> | undefined;
  public tournamentId: string | undefined;
  public bracket: Bracket<TTime> | undefined;
  public bracketKey: BracketRoundsKeys | undefined;

  constructor(
    private brackets: Bracket<TTime>[],
    private id: string,
    private idSpecifier: 'id' | 'inGameMatchId' | 'userId',
  ) {
    this.searchForMatch();
  }

  private searchForMatch() {
    this.brackets.some((bracket) => {
      return this.searchInBracket(bracket);
    });
  }

  private searchInBracket(bracket: Bracket<TTime>): boolean {
    return BRACKET_ROUNDS_FIELDS.some((bracketKey) => {
      const match = this.findMatchInBracketKey(bracket, bracketKey);
      if (match) {
        this.match = match;
        this.bracket = bracket;
        this.bracketKey = bracketKey;
        this.tournamentId = bracket.id;
        return true;
      }
      return false;
    });
  }

  private findMatchInBracketKey(
    bracket: Bracket<TTime>,
    bracketKey: BracketRoundsKeys,
  ): MatchAggregated<TTime> | undefined {
    // eslint-disable-next-line security/detect-object-injection
    const rounds = bracket[bracketKey];
    return !!rounds && rounds.length > 0
      ? this.findMatchInRounds(rounds)
      : undefined;
  }

  private findMatchInRounds(
    rounds: Round<TTime>[],
  ): MatchAggregated<TTime> | undefined {
    const roundsSecretary = new RoundsSecretary(rounds);
    return this.idSpecifier === 'userId'
      ? roundsSecretary.findMatchFor(this.id)
      : roundsSecretary.findMatchWith(this.idSpecifier, this.id);
  }
}
