import { useCallback, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useGuardSignIn } from './useGuardSignIn';
import { useDialog } from './useDialog';
import {
  TournamentRegistrationProvider,
  useTournamentRegistration,
} from '../contexts/TournamentRegistrationContext';
import { PendingInvite } from '../components/tournaments/registration/PendingInvite';
import { GradientTypography } from '../components/gradients/GradientTypography';
import { toInviterDetails } from '../util/tournaments/toInviterDetails';
import { useRouterState } from './routing/useRouterState';
import { useInviteGuarded } from './friends/useInviteGuarded';
import { tournamentEnded } from '../../functions/src/util/tournaments/tournamentEnded';
import { trackEvent } from '../util/vercel/trackEvent';

export const INVITE_RECEIVED_ID = 'INVITE_RECEIVED' as const;
export const INVALID_INVITE_ALERT_ID = 'INVALID_INVITE_ALERT' as const;

export const useInviteFromUrl = () => {
  const { openSignInGuard } = useGuardSignIn();
  const { open: openInviteReceived, close: closeInviteReceived } =
    useDialog(INVITE_RECEIVED_ID);
  const tournament = useTournamentRegistration();
  const {
    guestlistAggregated,
    waitlistAggregated,
    id,
    phase,
    endDate,
    gameId,
  } = tournament;
  const allTeams = [...guestlistAggregated, ...waitlistAggregated];
  const { user } = useAuth();

  const [inviteToken, setInviteToken] = useRouterState({ key: 'invite-token' });
  const [inviterId, setInviterId] = useRouterState({ key: 'inviter-id' });

  const removeTournamentQueryParams = useCallback(() => {
    setInviteToken(undefined);
    setInviterId(undefined);
  }, [setInviteToken, setInviterId]);

  const closeDialog = useCallback(() => {
    closeInviteReceived();
  }, [closeInviteReceived]);

  const inviteGuarded = useInviteGuarded({
    onSuccess: () => {
      /* */
    },
  });

  useEffect(() => {
    if (
      !inviteToken ||
      !inviterId ||
      !gameId ||
      tournamentEnded(phase, endDate)
    ) {
      return;
    }
    if (!user?.uid || (!!user.email && !user.emailVerified)) {
      openSignInGuard('Accept invite?');
      return;
    }
    const handler = async () => {
      const {
        id: teamId,
        inviter,
        teammates,
        teamName,
      } = toInviterDetails(inviterId, allTeams, user.uid);

      await inviteGuarded({
        inviteToken,
        teamId,
        gameId,
        tournamentId: id,
      });

      openInviteReceived({
        description: (
          <GradientTypography
            gradientColor={'primary.vertical'}
            variant="h4"
            sx={{ textTransform: 'uppercase', fontWeight: 700 }}
          >
            You`ve been invited!
          </GradientTypography>
        ),
        children: (
          <TournamentRegistrationProvider tournament={tournament}>
            <PendingInvite
              inviter={inviter}
              teamId={teamId}
              teammates={teammates}
              teamName={teamName}
              onRsvp={closeDialog}
              headerSx={{
                justifyContent: 'center',
              }}
            />
          </TournamentRegistrationProvider>
        ),
      });

      trackEvent('invitedIntoTeam', {
        teamId,
        inviteeId: user.uid,
        inviterId: inviter.userId,
        timestamp: Date.now(),
        tournamentId: id,
        gameId,
      });
    };

    handler();
    removeTournamentQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gameId,
    user?.uid,
    user?.email,
    inviteToken,
    inviterId,
    removeTournamentQueryParams,
  ]);
};
