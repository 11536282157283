import { GenericSignInMethod } from '../../../functions/src/types/firestore/User';
import { appAuthWithApple } from './appAuthWithApple';
import { appAuthWithGoogle } from './appAuthWithGoogle';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';

export const appSignInWith = (provider: GenericSignInMethod) => {
  if (provider === 'google') {
    return appAuthWithGoogle();
  }

  if (provider === 'apple') {
    return appAuthWithApple();
  }

  throw new HttpsError('invalid-argument', `Unsupported provider`, {
    provider,
  });
};
