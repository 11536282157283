import { useCallback, useContext } from 'react';
import { useMatch } from '../MatchProvider';
import ChatIcon from '@mui/icons-material/ChatRounded';
import { useActiveTournamentChannel } from '../../../../hooks/messaging/useActiveTournamentChannel';
import { memo } from '../../../../util/memo';
import {
  DEFAULT_MATCH_ROUTER_VALUES,
  useMatchDetailsRouter,
} from '../../../../hooks/tournaments/useMatchDetailsRouter';
import { TournamentContext } from '../../../../contexts/docs/TournamentContext';
import { Button } from '@mui/material';

const OpenMatchLobbyButtonUnmemoized = () => {
  const tournament = useContext(TournamentContext);
  const { openMatchChannel } = useActiveTournamentChannel(tournament.id);
  const [_activeMatchRouter, setActiveMatchRouter] = useMatchDetailsRouter();
  const { id: matchId } = useMatch();

  const open = useCallback(() => {
    openMatchChannel(matchId);
    setActiveMatchRouter(DEFAULT_MATCH_ROUTER_VALUES);
  }, [openMatchChannel, matchId, setActiveMatchRouter]);

  return (
    <Button
      startIcon={<ChatIcon />}
      variant="contained"
      sx={{ borderRadius: '50px', width: '100%', height: '40px' }}
      onClick={open}
    >
      MATCH LOBBY
    </Button>
  );
};

export const OpenMatchLobbyButton = memo(OpenMatchLobbyButtonUnmemoized);
