import { CustomSignInMethod } from '../../types/firestore/User';
import { deploymentUrlSchemed } from '../deploymentUrl';
import { BlumintPlatform } from '../../types/Platform';

export const CUSTOM_PROVIDER_KEY = 'custom-provider' as const;

export const oAuthRedirectUri = (
  platform: BlumintPlatform,
  provider?: CustomSignInMethod,
) => {
  const isOnMobile = platform === 'android'; // TODO: add iOS
  const isOffBrowser = platform !== 'browser';
  const isOnElectron = platform === 'electron';
  const subdomain = isOnMobile ? undefined : 'oauth.';
  const subdir = `${isOnElectron ? 'authorization' : 'oauth'}${
    isOffBrowser ? '' : '/index.html'
  }`;
  const queryParam = isOffBrowser ? `?${CUSTOM_PROVIDER_KEY}=${provider}` : '';
  return `${deploymentUrlSchemed(subdomain)}/${subdir}${queryParam}`;
};
