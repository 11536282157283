export const toSegmentKeySingle = (name: string) => {
  return `[${name}]`;
};

export const toSegmentKeyCatchAll = (name: string) => {
  return `[[...${name}]]`;
};

export const toSegmentKey = (name: string, isCatchAll?: boolean) => {
  return isCatchAll ? toSegmentKeyCatchAll(name) : toSegmentKeySingle(name);
};

export const findMatchingSegment = (name: string, keys: string[]) => {
  const catchAllKey = toSegmentKeyCatchAll(name);

  const catchAllIndex = keys.indexOf(catchAllKey);
  if (catchAllIndex !== -1) {
    return { index: catchAllIndex, isCatchAll: true };
  }

  const dynamicKey = toSegmentKeySingle(name);

  const dynamicIndex = keys.indexOf(dynamicKey);
  if (dynamicIndex !== -1) {
    return { index: dynamicIndex, isCatchAll: false };
  }

  return false;
};
