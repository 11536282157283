import { Team } from '../../../functions/src/types/firestore/Game/Tournament/Guestlist';
import { findCaseInsensitiveIn } from '../../../functions/src/util/findCaseInsensitiveIn';
import { Loadable } from '../../../functions/src/util/isLoading';

export const isUserInMatch = (
  teams: (Team<Date> | undefined)[],
  uid?: Loadable<string>,
) => {
  return (
    !!uid &&
    teams.some((team) => {
      return findCaseInsensitiveIn(uid, team?.acceptedUserIds);
    })
  );
};
