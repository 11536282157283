import IconButton from '@mui/material/IconButton';
import { useCallback } from 'react';
import MinimizeRoundedIcon from '@mui/icons-material/MinimizeRounded';

export const ElectronMinimizeButton = () => {
  const minimizeWindow = useCallback(() => {
    if (typeof window !== 'undefined' && window.electron) {
      window.electron.minimize();
    }
  }, []);

  return (
    <IconButton onClick={minimizeWindow}>
      <MinimizeRoundedIcon />
    </IconButton>
  );
};
