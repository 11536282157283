import { createContext, ReactNode, useContext, useMemo } from 'react';
import { memo } from '../../util/memo';
import { VoiceChatMode } from '../../contexts/RoomContext';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';

export type VoiceChatContextProps = {
  mode: VoiceChatMode;
};

export const VoiceChatContext = createContext<
  VoiceChatContextProps | undefined
>(undefined);

export type VoiceChatProviderProps = {
  children: ReactNode;
  mode: VoiceChatMode;
};

export const useVoiceChat = () => {
  const context = useContext(VoiceChatContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useVoiceChat must be used within a VoiceChatProvider',
    );
  }
  return context;
};

// NOTE: remove/rename in case this doesn't grow to include more than just mode
const VoiceChatProviderUnmemoized = ({
  children,
  mode,
}: VoiceChatProviderProps) => {
  const memoizedValue = useMemo(() => {
    return { mode };
  }, [mode]);

  return (
    <VoiceChatContext.Provider value={memoizedValue}>
      {children}
    </VoiceChatContext.Provider>
  );
};

export const VoiceChatProvider = memo(VoiceChatProviderUnmemoized);
