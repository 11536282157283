import {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';
import { memo } from '../util/memo';
import {
  Tournament,
  Waitlistable,
} from '../../functions/src/types/firestore/Game/Tournament';
import { PendingInviteProps } from '../components/tournaments/registration/PendingInvite';
import { useAuth } from './AuthContext';
import { Team } from '../../functions/src/types/firestore/Game/Tournament/Guestlist';
import { getTournamentRules } from '../components/tournaments/registration/containers/RegistrationContainer';
import { Optional } from 'utility-types';
import { isUserSolo } from '../../functions/src/util/tournaments/isUserSolo';
import { getMemberStatus } from '../../functions/src/util/tournaments/getMemberStatus';
import { STATUS_PRIORITY } from '../../functions/src/util/tournaments/statusPriority';
import { parseStatus } from '../../functions/src/util/tournaments/parseStatus';
import { getInviteUrl } from '../../functions/src/util/tournaments/getInviteUrl';
import { constructPendingInvites } from '../../functions/src/util/tournaments/constructPendingInvites';
import { generateInviteToken } from '../../functions/src/util/tournaments/generateInviteToken';
import { isAcceptedOrPendingTeam } from '../../functions/src/util/tournaments/isAcceptedOrPendingTeam';
import { isAcceptedTeam } from '../../functions/src/util/tournaments/isAcceptedTeam';
import { ProviderDetail } from '../components/tournaments/registration/SelectableRegistrationOption';
import { useFetchInGameId } from '../hooks/tournaments/useFetchInGameId';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';

export const PHASES_WITH_PARTICIPANTS_ONLY = ['ready', 'live', 'finished'];

export type TournamentRegistrationContextType = Tournament<Date> & {
  inviteUrl: string;
  isUserCaptain: boolean;
  isSubscribed: boolean;
  pendingInvites?: PendingInviteProps[];
  foundTeam?: Team<Date> & Optional<Waitlistable>;
  rulesAndRegulations?: string;
  userType: string;
  registeredTeams: Team<Date>[];
  connectedIds?: ProviderDetail[];
  availableProviders?: ProviderDetail[];
};

export type TournamentDataProviderProps = {
  children?: ReactNode;
  tournament: Tournament<Date>;
};

const TournamentRegistrationContext =
  createContext<TournamentRegistrationContextType | null>(null);

export function useTournamentRegistration() {
  const context = useContext(TournamentRegistrationContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useTournamentRegistration must be used within a TournamentRegistrationProvider',
    );
  }
  const { registeredTeams, foundTeam } = context;

  const isAcceptedOrPendingAnyTeam = useCallback(
    (userId: string) => {
      return isAcceptedOrPendingTeam(userId, registeredTeams);
    },
    [registeredTeams],
  );

  const isAcceptedOrPendingMyTeam = useCallback(
    (userId: string) => {
      return foundTeam && isAcceptedOrPendingTeam(userId, [foundTeam]);
    },
    [foundTeam],
  );

  const isAcceptedAnyTeam = useCallback(
    (userId: string) => {
      return isAcceptedTeam(userId, registeredTeams);
    },
    [registeredTeams],
  );

  const isAcceptedMyTeam = useCallback(
    (userId: string) => {
      return foundTeam && isAcceptedTeam(userId, [foundTeam]);
    },
    [foundTeam],
  );

  const isMemberCaptain = (memberId: string) => {
    return (
      foundTeam &&
      foundTeam.members.some((member) => {
        return member.userId === memberId && member.status === 'captain';
      })
    );
  };

  return {
    ...context,
    isAcceptedOrPendingAnyTeam,
    isAcceptedOrPendingMyTeam,
    isAcceptedAnyTeam,
    isAcceptedMyTeam,
    isMemberCaptain,
  };
}

export const TournamentRegistrationProvider = memo(
  function TournamentRegistrationProviderUnmemoized({
    tournament,
    children,
  }: TournamentDataProviderProps) {
    const {
      id,
      skipCheckIn,
      phase,
      subscribedUserIds,
      gameId,
      waitlistAggregated,
      guestlistAggregated,
      participantsAggregated,
      tournamentDetails,
      continuousRegistration,
    } = tournament;

    const { uid, userData } = useAuth();

    const registeredTeams = [...waitlistAggregated, ...guestlistAggregated];
    const teamsAll =
      PHASES_WITH_PARTICIPANTS_ONLY.includes(phase) && !continuousRegistration
        ? participantsAggregated
        : registeredTeams;

    const teams = teamsAll.filter(({ members }) => {
      return members.some((member) => {
        return member.userId === uid;
      });
    });

    const relevantTeams = teams.filter((team) => {
      const { userIds } = team;
      return userIds.includes(uid || '');
    });

    const foundTeam = relevantTeams.find((team) => {
      const { members } = team;
      return members.some((member) => {
        const { userId, status } = member;
        return (
          userId === uid &&
          (status === 'captain' ||
            status === 'accepted' ||
            status === 'reassigned')
        );
      });
    });

    const inviteToken =
      !!uid && !!userData && !!foundTeam
        ? generateInviteToken({
            inviterId: uid,
            teamId: foundTeam.id,
            inviterSecret: userData.hidden.secret,
          })
        : undefined;

    const teamsWithInvites = relevantTeams.filter((team) => {
      const { members } = team;
      return members.some((member) => {
        const { userId, status } = member;
        return userId === uid && status === 'pending';
      });
    });

    const userType = useMemo(() => {
      if (!uid) {
        return 'unregistered';
      }

      if (
        relevantTeams.some((team) => {
          return isUserSolo(team, uid);
        })
      ) {
        return 'solo';
      }

      const statuses = relevantTeams
        .map((team) => {
          return getMemberStatus(team, uid);
        })
        .filter(Boolean) as string[];
      const sortedStatus = statuses.sort((a, b) => {
        return STATUS_PRIORITY.indexOf(a) - STATUS_PRIORITY.indexOf(b);
      })[0];
      return parseStatus(sortedStatus);
    }, [relevantTeams, uid]);

    const { connectedIds, availableProviders } = useFetchInGameId({ gameId });

    const parsedData = Object.assign(
      {},
      {
        // todo: update once we have subtabs routing
        inviteUrl: window
          ? `${getInviteUrl(id, inviteToken, uid || undefined)}`
          : '',
        isSubscribed: subscribedUserIds?.includes(uid || ''),
        pendingInvites: uid
          ? constructPendingInvites(
              teamsWithInvites,
              uid,
              phase,
              continuousRegistration,
            )
          : undefined,
        skipCheckIn: !!skipCheckIn,
        isUserCaptain: userType === 'captain',
        rulesAndRegulations: getTournamentRules(tournamentDetails),
        foundTeam,
        userType,
        registeredTeams,
        connectedIds,
        availableProviders,
        ...tournament,
      },
    );

    const memoizedValue = useMemo(() => {
      return {
        ...parsedData,
      };
    }, [parsedData]);

    return (
      <TournamentRegistrationContext.Provider value={memoizedValue}>
        {children}
      </TournamentRegistrationContext.Provider>
    );
  },
);
