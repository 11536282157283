import { useCallback, useMemo, useState } from 'react';
import { memo } from '../../../../util/memo';
import WarningIcon from '@mui/icons-material/WarningRounded';
import CheckIcon from '@mui/icons-material/CheckRounded';
import { GradientIconButton } from '../../../gradients/GradientIconButton';
import { usePersonalChannelGroup } from '../../../../hooks/messaging/usePersonalChannelGroup';
import { useAuth } from '../../../../contexts/AuthContext';
import { ScreenshotImageHoverProps } from './ScreenshotImageHover';
import { LoadingIconButton } from '../../../buttons/LoadingIconButton';
import { useGuardSignIn } from '../../../../hooks/useGuardSignIn';
import { useStream } from '../../../../contexts/get-stream/StreamContext';
import { useActiveChannelGroup } from '../../../../contexts/ActiveChannelGroupContext';
import { ChannelFetcher } from '../../../../../functions/src/util/messaging/ChannelFetcher';
import {
  DEFAULT_MATCH_ROUTER_VALUES,
  useMatchDetailsRouter,
} from '../../../../hooks/tournaments/useMatchDetailsRouter';

export const REPORT_CHANNEL_TYPE = 'support' as const;

export type ReportScreenshotProps = Pick<
  ScreenshotImageHoverProps,
  'screenshotUrl'
>;

const ReportScreenshotUnmemoized = ({
  screenshotUrl,
}: ReportScreenshotProps) => {
  const { set: setPersonalChannelGroup } = usePersonalChannelGroup();
  const { userData } = useAuth();
  const { chatClient } = useStream();
  const { openSignInGuard } = useGuardSignIn();
  const { openChannelGroup } = useActiveChannelGroup();
  const [_activeMatchRouter, setActiveMatchRouter] = useMatchDetailsRouter();
  const [hasReported, setHasReported] = useState(false);

  const attachments = useMemo(() => {
    return [{ type: 'image', image_url: screenshotUrl }];
  }, [screenshotUrl]);

  const report = useCallback(async () => {
    if (!userData?.username) {
      return openSignInGuard('Report this image?');
    }

    if (!chatClient.user) {
      return;
    }

    const channelFetcher = new ChannelFetcher(chatClient);
    const channels = await channelFetcher.fetchMatching({
      type: REPORT_CHANNEL_TYPE,
    });

    const reportExists = channels?.[0].state.messages.some(
      ({ attachments }) => {
        return attachments?.some(({ image_url }) => {
          return image_url === screenshotUrl;
        });
      },
    );

    if (!reportExists) {
      const { id } = await setPersonalChannelGroup({
        type: REPORT_CHANNEL_TYPE,
        friendIds: [],
        messagesInitial: [
          {
            text: `SCREENSHOT REPORTED BY: ${userData.username}`,
            attachments,
          },
        ],
      });
      openChannelGroup(id);
      setActiveMatchRouter(DEFAULT_MATCH_ROUTER_VALUES);
    }

    setHasReported(true);
  }, [
    userData?.username,
    chatClient,
    openSignInGuard,
    screenshotUrl,
    setPersonalChannelGroup,
    attachments,
    openChannelGroup,
    setActiveMatchRouter,
  ]);

  return (
    <LoadingIconButton
      onClick={report}
      sx={{
        p: 0,
      }}
    >
      <GradientIconButton
        IconComponent={hasReported ? CheckIcon : WarningIcon}
        gradientColor={
          hasReported ? 'secondary.horizontal' : 'warning.horizontal'
        }
      />
    </LoadingIconButton>
  );
};

export const ReportScreenshot = memo(ReportScreenshotUnmemoized);
