import { memo } from '../../util/memo';
import { VoiceChatToolbar } from './VoiceChatToolbar';
import { RequestCount } from './RequestCount';
import { HoverProvider } from '../../contexts/HoverContext';

export const HOVER_PROVIDER_STYLE = { width: '100%' } as const;

const VoiceChatWidgetUnmemoized = () => {
  return (
    <RequestCount>
      <HoverProvider style={HOVER_PROVIDER_STYLE}>
        <VoiceChatToolbar />
      </HoverProvider>
    </RequestCount>
  );
};

export const VoiceChatWidget = memo(VoiceChatWidgetUnmemoized);
