import { Screenshot } from '../../../types/firestore/Game/Tournament/Bracket';
import { findCaseInsensitiveIn } from '../../findCaseInsensitiveIn';
import { Loadable } from '../../isLoading';
import { findTeamIndex } from './findTeamIndex';

export const isScreenshotUploadedFor = (
  sessionIndex: number,
  teamUserIds: string[][],
  screenshots?: Screenshot[],
  uid?: Loadable<string>,
) => {
  const teamIndex = findTeamIndex(teamUserIds, uid);
  return (
    !!screenshots &&
    screenshots?.some(
      ({ sessionNumber: sessionIndexScreenshot, uploaderUid }) => {
        const acceptedUserIds = teamUserIds[Number(teamIndex)];
        const hasTeamUploaded = findCaseInsensitiveIn(
          uploaderUid,
          acceptedUserIds,
        );
        return !!hasTeamUploaded && sessionIndexScreenshot === sessionIndex;
      },
    )
  );
};
