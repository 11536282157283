import { useContext, createContext, ReactNode, useMemo } from 'react';
import { memo } from '../../../util/memo';
import {
  MatchAggregated,
  Outcome,
} from 'functions/src/types/firestore/Game/Tournament/Bracket';
import { CompetitorWithMembers } from './details/MatchDetails';
import { matchCompetitorsOf } from '../../../util/tournaments/matchCompetitorsOf';
import { useAuth } from '../../../contexts/AuthContext';
import { HttpsError } from '../../../../functions/src/util/errors/HttpsError';

export type MatchProps = MatchAggregated<Date> & {
  isLarge?: boolean;
  outcome?: Outcome;
  competitor1?: CompetitorWithMembers;
  competitor2?: CompetitorWithMembers;
  numberOfTeams?: number;
  games: number[];
};

export const MatchContext = createContext<
  (MatchProps & { isMatchDisabled: boolean }) | null
>(null);

export const useMatch = () => {
  const context = useContext(MatchContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useMatch must be used within a MatchProvider',
    );
  }
  return context;
};

export type MatchProviderProps = MatchAggregated<Date> & {
  children: ReactNode;
  isMatchDisabled: boolean;
};

export const MatchProvider = memo(function MatchProviderUnmemoized({
  children,
  isMatchDisabled,
  ...match
}: MatchProviderProps) {
  const { uid } = useAuth();
  const {
    team1Scores = [],
    team2Scores = [],
    bestOf = 1,
    team1Score = 0,
    team2Score = 0,
    team1: team1Original,
    team2: team2Original,
    ...rest
  } = match;
  const [team1, team2] = matchCompetitorsOf(match);

  const { competitor: competitorTeam1, ...restTeam1 } = team1;
  const { competitor: competitorTeam2, ...restTeam2 } = team2;

  const userTeam = useMemo(() => {
    if (!uid) {
      return;
    }
    const team1UserIds = team1Original?.acceptedUserIds || [];
    const team2UserIds = team2Original?.acceptedUserIds || [];
    if (team1UserIds.includes(uid)) {
      return team1Original;
    }
    if (team2UserIds.includes(uid)) {
      return team2Original;
    }
    return undefined;
  }, [team1Original, team2Original, uid]);

  const competitor1 = useMemo(() => {
    return {
      ...restTeam1,
      ...competitorTeam1,
      members: match.team1?.members || [],
    };
  }, [competitorTeam1, match.team1?.members, restTeam1]);

  const competitor2 = useMemo(() => {
    return {
      ...restTeam2,
      ...competitorTeam2,
      members: match.team2?.members || [],
    };
  }, [competitorTeam2, match.team2?.members, restTeam2]);

  const outcome = competitor1?.outcome && competitor2?.outcome;

  const numberOfTeams = useMemo(() => {
    return [team1Original, team2Original].filter(Boolean).length;
  }, [team1Original, team2Original]);

  const games = useMemo(() => {
    return Array.from({ length: bestOf }, (_, index) => {
      return index + 1;
    });
  }, [bestOf]);

  const matchData = useMemo(() => {
    return {
      outcome,
      competitor1,
      competitor2,
      numberOfTeams,
      team1Scores,
      team2Scores,
      bestOf,
      team1Score,
      team2Score,
      team1: team1Original,
      team2: team2Original,
      ...rest,
      games,
      isMatchDisabled,
      userTeam,
    };
  }, [
    outcome,
    competitor1,
    competitor2,
    numberOfTeams,
    team1Scores,
    team2Scores,
    bestOf,
    team1Score,
    team2Score,
    team1Original,
    team2Original,
    rest,
    games,
    isMatchDisabled,
    userTeam,
  ]);

  return (
    <MatchContext.Provider value={{ ...matchData }}>
      {children}
    </MatchContext.Provider>
  );
});
