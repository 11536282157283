import {
  AdvancementStatus,
  Advancement,
} from '../../types/firestore/Progression';
import { PromiseOrValue } from '../../types/utility-types';

export type ReportInvocationSettings<T> = {
  callback: () => PromiseOrValue<T>;
  onSuccess?: (res: T) => PromiseOrValue<void>;
  onError?: (error: unknown) => PromiseOrValue<void>;
  advancement: Pick<Advancement, 'details' | 'name'>;
};

export type IProgressionReporter = {
  reportInvocation<T>(
    settings: ReportInvocationSettings<T>,
  ): Promise<T | void>;
  stage(
    advancement: Pick<Advancement, 'details' | 'name'>,
  ): PromiseOrValue<void>;
  setStatus(
    advancementName: string,
    status: AdvancementStatus,
  ): PromiseOrValue<void>;
  end(): PromiseOrValue<void>;
  updateProgressionWithNewAdvancements<T extends Advancement[] = Advancement[]>(
    advancementsNew: T,
  ): PromiseOrValue<void>;
};

export abstract class ProgressionReporterBase implements IProgressionReporter {
  public async reportInvocation<T>({
    callback,
    onSuccess,
    onError = (error) => {
      throw error;
    },
    advancement,
  }: ReportInvocationSettings<T>): Promise<T | void> {
    await this.stage(advancement);
    try {
      const res = (await callback()) as T;
      await onSuccess?.(res);
      await this.setStatus(advancement.name, 'completed');
      return res;
    } catch (error) {
      console.error(error);
      await this.setStatus(advancement.name, 'error');
      await onError(error);
    }
    return undefined;
  }

  abstract stage(
    advancement: Pick<Advancement, 'details' | 'name'>,
  ): PromiseOrValue<void>;

  public async setStatus(
    advancementName: string,
    statusNew: AdvancementStatus,
  ): Promise<void> {
    const advancementsCurrent = await this.getCurrentAdvancements();
    const advancementsNew = advancementsCurrent.map((advancement) => {
      return advancement.name === advancementName
        ? { ...advancement, status: statusNew }
        : advancement;
    });
    this.updateProgressionWithNewAdvancements(advancementsNew);
  }

  abstract getCurrentAdvancements(): PromiseOrValue<Advancement[]>;

  /**
   *
   * Generic typing allows FieldValue
   */
  abstract updateProgressionWithNewAdvancements<
    T extends Advancement[] = Advancement[],
  >(advancementsNew: T): PromiseOrValue<void>;

  abstract end(): PromiseOrValue<void>;
}
