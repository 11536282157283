import { memo } from '../../util/memo';
import { SelectablePath, SelectablePathProps } from './SelectablePath';
import { SelectableState, SelectableStateProps } from './SelectableState';

export type SelectableRoutedProps = SelectableStateProps | SelectablePathProps;

const SelectableRoutedUnmemoized = (props: SelectableRoutedProps) => {
  const isPathNavigation = typeof props.routerOptions === 'string';
  return isPathNavigation ? (
    <SelectablePath {...(props as SelectablePathProps)} />
  ) : (
    <SelectableState {...(props as SelectableStateProps)} />
  );
};

export const SelectableRouted = memo(SelectableRoutedUnmemoized);
