import { useTheme, SxProps } from '@mui/material/styles';
import { useMemo, FC, ReactNode } from 'react';
import { memo } from '../../../util/memo';
// import { ShareUrlToClipboard } from '../headers/ShareUrlToClipboard';
import Stack from '@mui/material/Stack';
import { ViewMoreChip } from '../headers/ViewMoreChip';
import { InfiniteCatalog } from './InfiniteCatalog';
import { useNearEndContent } from '../../../hooks/content/useNearEndContent';
import { ExtendedIntersectionObserverOptions } from '../../../hooks/visibility/useIntersectionObserver';

export type VerticalCarouselProps = InfiniteCatalog & {
  viewMoreLink?: string;
  header?: ReactNode;
  cardSpacing?: string;
  share?: boolean;
  containerSx?: SxProps;
  contentSx?: SxProps;
  background?: number;
  intersectionOptions?: ExtendedIntersectionObserverOptions;
};

const VerticalCarouselUnmemoized: FC<VerticalCarouselProps> = ({
  Content,
  header,
  onNearEnd,
  viewMoreLink,
  cardSpacing = '0px',
  containerSx,
  contentSx,
  intersectionOptions,
}) => {
  const { NearEndContent } = useNearEndContent({
    Content,
    onNearEnd,
    intersectionOptions,
    scrollDirection: 'down',
  });

  const theme = useTheme();

  const containerAllSx = useMemo(() => {
    return {
      borderRadius: '10px',
      ...(containerSx || {}),
    } as SxProps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerSx]);

  const contentAllSx = useMemo(() => {
    return {
      height: '100%',
      borderRadius: '10px',
      overflowY: 'auto',
      position: 'relative',
      ...theme.scrollbars.invisible,
      ...contentSx,
    } as SxProps;
  }, [contentSx]);

  return (
    <Stack direction="column" height="100%" sx={containerAllSx} spacing={3}>
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Stack
          direction="row"
          spacing={2}
          flex={1}
          sx={{ alignItems: 'center' }}
        >
          {header}
          {viewMoreLink && <ViewMoreChip href={viewMoreLink}></ViewMoreChip>}
        </Stack>
        {/* {share && <ShareUrlToClipboard></ShareUrlToClipboard>} */}
      </Stack>
      <Stack spacing={cardSpacing} sx={contentAllSx}>
        {NearEndContent}
      </Stack>
    </Stack>
  );
};

export const VerticalCarousel = memo(VerticalCarouselUnmemoized);
