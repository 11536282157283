import { useCallback, useMemo } from 'react';
import { useEditable } from '../../contexts/organizer/EditableStepsContext';
import { useRouter } from '../routing/useRouter';
import { useWizard } from '../../components/wizard/Wizard';
import { useGroupRouterExternal } from '../group/useGroupRouterExternal';
import { useAuth } from '../../contexts/AuthContext';
import { toEventUrl } from '../../util/event/toEventUrl';

export const useCreateTournament = () => {
  const { setDocumentPath: setEditableDocumentPath } = useEditable();
  const { go } = useWizard();
  const { push } = useRouter();
  const { uid } = useAuth();

  const { groupId } = useGroupRouterExternal();
  const primaryContributorId = useMemo(() => {
    return groupId || uid; // Sign up guard
  }, [groupId, uid]);

  const closeDialog = useCallback(() => {
    go(undefined);
  }, [go]);

  const createTournament = useCallback(
    async (gameId: string, previousId?: string) => {
      if (!primaryContributorId) {
        return;
      }
      const { createTemplateTournament } = await import(
        '../../firebaseCloud/tournament/organizer/createTemplateTournament'
      );

      const { tournamentNew } = await createTemplateTournament({
        gameId,
        previousId,
        groupId: primaryContributorId,
      });

      const { id: newEventId, gameId: newGameId, roles } = tournamentNew;

      const { eventUrlPath, eventDocumentPath } = toEventUrl({
        id: newEventId,
        gameId: newGameId,
        roles,
      });

      setEditableDocumentPath(eventDocumentPath);

      push(`${eventUrlPath}&edit-mode=primary`);

      closeDialog();
    },
    [closeDialog, primaryContributorId, push, setEditableDocumentPath],
  );

  return { createTournament };
};
