import { useEffect, useState, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { checkGroupDenormalization } from '../../util/group/checkGroupDenormalization';

export function useIsUserInGroup(groupId?: string) {
  const [isInGroup, setIsInGroup] = useState<boolean | 'unknown'>('unknown');
  const { userData } = useAuth();

  const checkIsUserInGroup = useCallback(
    async (checkGroupId: string) => {
      if (!userData?.id || !userData?.groups?.length) {
        return false;
      }

      const isInLatest1000 = userData?.groups?.includes(checkGroupId);

      if (!isInLatest1000 && userData.groups.length >= 1000) {
        return await checkGroupDenormalization(userData.id, checkGroupId);
      }

      return isInLatest1000;
    },
    [userData?.id, userData?.groups],
  );

  useEffect(() => {
    const handler = async () => {
      if (!groupId) {
        return;
      }
      const result = await checkIsUserInGroup(groupId);
      setIsInGroup(result);
    };

    handler();
  }, [userData?.id, groupId, userData?.groups, checkIsUserInGroup]);

  return { isInGroup, checkIsUserInGroup };
}
