import { useCallback, MouseEvent } from 'react';
import { memo } from '../../util/memo';
import { createPortal } from 'react-dom';
import { useTheme } from '@mui/material/styles';
import { useTheaterMode } from '../../contexts/mux-player/TheaterModeContext';

export const THEATER_MODE_BUTTON_ID = 'theater-mode-button';

export type TheaterModeButtonProps = {
  controlBar?: Element | null;
};

const TheaterModeButtonUnmemoized = ({
  controlBar,
}: TheaterModeButtonProps) => {
  const theme = useTheme();
  const { isTheaterMode, enterTheaterMode, exitTheaterMode } = useTheaterMode();

  const toggleTheaterMode = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      if (isTheaterMode) {
        exitTheaterMode();
      } else {
        enterTheaterMode();
      }
    },
    [isTheaterMode, enterTheaterMode, exitTheaterMode],
  );

  if (!controlBar) {
    return null;
  }

  return createPortal(
    <div
      id={THEATER_MODE_BUTTON_ID}
      onClick={toggleTheaterMode}
      style={{
        order: 1,
        display: 'flex',
        alignItems: isTheaterMode ? 'flex-end' : 'center',
        marginBottom: isTheaterMode ? '8px' : '0px',
        marginRight: '8px',
        marginLeft: '4px',
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          height: isTheaterMode ? '3px' : '10px',
          width: '16px',
          backgroundColor: isTheaterMode
            ? theme.palette.text.primary
            : 'transparent',
          border: isTheaterMode
            ? 'none'
            : `1px solid ${theme.palette.text.primary}`,
          borderRadius: '2px',
        }}
      />
    </div>,
    controlBar,
  );
};

export const TheaterModeButton = memo(TheaterModeButtonUnmemoized);
