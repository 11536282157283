/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, ReactNode, FC, useMemo } from 'react';
import { memo } from '../util/memo';
import { createContext, useContextSelector } from 'use-context-selector';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';

export type OptimisticValueContextType = {
  values: { [key: string]: any };
  updateOptimisticValue: (key: string, value: any) => void;
  clearOptimisticValue: (key: string) => void;
  clearAllOptimisticValues: () => void;
};

const OptimisticValueContext = createContext<
  OptimisticValueContextType | undefined
>(undefined);

export const useOptimisticValue = <TValue,>(key: string) => {
  return useContextSelector(OptimisticValueContext, (contextInstance) => {
    if (!contextInstance) {
      throw new HttpsError(
        'failed-precondition',
        'useOptimisticValue must be used within a OptimisticValueContext',
      );
    }
    return contextInstance.values[String(key)] as TValue;
  });
};

export const useOptimisticValueUtils = () => {
  return useContextSelector(OptimisticValueContext, (contextInstance) => {
    if (!contextInstance) {
      throw new HttpsError(
        'failed-precondition',
        'useOptimisticValueUtils must be used within a OptimisticValueContext',
      );
    }
    return contextInstance;
  });
};

export const OptimisticValueProviderUnmemoized: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [values, setValues] = useState<{
    [key: string]: any;
  }>({});

  const updateOptimisticValue = useCallback((key: string, value: any) => {
    setValues((prevValues) => {
      return { ...prevValues, [key]: value };
    });
  }, []);

  const clearOptimisticValue = useCallback(
    (key: string) => {
      updateOptimisticValue(key, undefined);
    },
    [updateOptimisticValue],
  );

  const clearAllOptimisticValues = useCallback(() => {
    if (typeof window === 'undefined') {
      return;
    }
    Object.keys(values).forEach((key) => {
      clearOptimisticValue(key);
    });
  }, [clearOptimisticValue, values]);

  const providerValue = useMemo(() => {
    return {
      values,
      updateOptimisticValue,
      clearOptimisticValue,
      clearAllOptimisticValues,
    };
  }, [
    values,
    updateOptimisticValue,
    clearOptimisticValue,
    clearAllOptimisticValues,
  ]);

  return (
    <OptimisticValueContext.Provider value={providerValue}>
      {children}
    </OptimisticValueContext.Provider>
  );
};

export const OptimisticValueProvider = memo(OptimisticValueProviderUnmemoized);
