import {
  toGroupCollectionPath,
  toGroupDocumentPath,
} from '../../types/firestore/Group/path';
import {
  toSubgroupCollectionPath,
  toSubgroupDocumentPath,
} from '../../types/firestore/Group/Subgroup/path';
import { base62, base256 } from '../encoding/BaseX';
import { HttpsError } from '../errors/HttpsError';
import { UUIDV4_BYTES_LENGTH } from '../uuidv4Base62';
import { GroupType, GROUP_TYPES } from '../../types/groupType';
import { toGroupMetricsDocumentPath } from '../../types/firestore/Group/GroupMetrics/path';

export class GroupDecoder {
  public static readonly SIGNATURE_BITS_COUNT = BigInt(UUIDV4_BYTES_LENGTH * 8);
  public static readonly SUBGROUP_BITS_COUNT = BigInt(UUIDV4_BYTES_LENGTH * 8);
  public static readonly GROUP_TYPE_BITS_COUNT = BigInt(3);
  public static readonly SUBGROUP_FLAG_BITS_COUNT = BigInt(1);
  private constructor(
    public readonly fullNumber: bigint,
    public readonly fullNumberBase62: string,
    public readonly fullNumberBase256: string,
  ) {}

  static fromBase62(fullNumber: string): GroupDecoder {
    const num = base62.decodeBigUint(fullNumber);
    return new GroupDecoder(num, fullNumber, base256.encodeBigUint(num));
  }

  static fromBase10(fullNumber: string): GroupDecoder {
    const num = BigInt(fullNumber);
    return GroupDecoder.fromNumber(num);
  }

  static fromNumber(fullNumber: bigint): GroupDecoder {
    return new GroupDecoder(
      fullNumber,
      base62.encodeBigUint(fullNumber),
      base256.encodeBigUint(fullNumber),
    );
  }

  static fromBase256(fullNumber: string): GroupDecoder {
    const num = base256.decodeBigUint(fullNumber);
    return new GroupDecoder(num, base62.encodeBigUint(num), fullNumber);
  }

  get groupCollectionPath(): string {
    return toGroupCollectionPath(this.groupType);
  }

  get groupType(): GroupType {
    if (!this.hasSignature) {
      return 'user';
    }

    const signaturelessNumber =
      this.fullNumber >> GroupDecoder.SIGNATURE_BITS_COUNT;
    const typeIndex = Number(
      signaturelessNumber & GroupDecoder.GROUP_TYPE_BITS_MASK,
    );

    const type = GROUP_TYPES[typeIndex as number];
    if (!type) {
      throw new HttpsError('invalid-argument', `Invalid group type index`, {
        fullNumber: this.fullNumberBase62,
        typeIndex,
      });
    }
    return type;
  }

  private get hasSignature(): boolean {
    return (this.fullNumber & GroupDecoder.SIGNATURE_BITS_MASK) === BigInt(0);
  }

  private static get SIGNATURE_BITS_MASK(): bigint {
    return (BigInt(1) << GroupDecoder.SIGNATURE_BITS_COUNT) - BigInt(1);
  }

  private static get GROUP_TYPE_BITS_MASK(): bigint {
    return (BigInt(1) << GroupDecoder.GROUP_TYPE_BITS_COUNT) - BigInt(1);
  }

  get groupMetricsPath(): string {
    return toGroupMetricsDocumentPath(this.groupPath);
  }

  get groupPath(): string {
    return toGroupDocumentPath(this.groupType, this.groupId);
  }

  get groupId(): string {
    if (this.groupType === 'user' || !this.isSubgroup) {
      return this.fullNumberBase62;
    }

    const groupHashPortion = this.groupHash >> GroupDecoder.SUBGROUP_BITS_COUNT;
    const overheadBits = this.fullNumber & GroupDecoder.OVERHEAD_BITS_MASK;
    const clearedSubgroupFlag =
      overheadBits & GroupDecoder.SIGNATURE_AND_TYPE_BITS_MASK;

    const fullGroupNumber =
      (groupHashPortion << GroupDecoder.OVERHEAD_BITS_COUNT) |
      clearedSubgroupFlag;
    return base62.encodeBigUint(fullGroupNumber);
  }

  get isSubgroup(): boolean {
    if (!this.hasSignature) {
      return false;
    }

    const subgroupBit =
      (this.fullNumber >>
        (GroupDecoder.SIGNATURE_BITS_COUNT +
          GroupDecoder.GROUP_TYPE_BITS_COUNT)) &
      GroupDecoder.SUBGROUP_FLAG_BITS_MASK;
    return subgroupBit === BigInt(1);
  }

  private static get SUBGROUP_FLAG_BITS_MASK(): bigint {
    return (BigInt(1) << GroupDecoder.SUBGROUP_FLAG_BITS_COUNT) - BigInt(1);
  }

  get groupHash(): bigint {
    if (!this.hasSignature) {
      // When there is no signature (user), the entire number is the group ID (user ID) before its encoded
      return this.fullNumber;
    }

    return this.fullNumber >> GroupDecoder.OVERHEAD_BITS_COUNT;
  }

  private static get OVERHEAD_BITS_COUNT(): bigint {
    return (
      GroupDecoder.SIGNATURE_BITS_COUNT +
      GroupDecoder.GROUP_TYPE_BITS_COUNT +
      GroupDecoder.SUBGROUP_FLAG_BITS_COUNT
    );
  }

  private static get OVERHEAD_BITS_MASK(): bigint {
    return (BigInt(1) << GroupDecoder.OVERHEAD_BITS_COUNT) - BigInt(1);
  }

  private static get SIGNATURE_AND_TYPE_BITS_MASK(): bigint {
    return (
      (BigInt(1) <<
        (GroupDecoder.SIGNATURE_BITS_COUNT +
          GroupDecoder.GROUP_TYPE_BITS_COUNT)) -
      BigInt(1)
    );
  }

  get subgroupCollectionPath(): string {
    return toSubgroupCollectionPath(this.groupType, this.groupId);
  }

  get subgroupMetricsPath(): string | undefined {
    if (!this.subgroupPath) {
      return undefined;
    }

    return toGroupMetricsDocumentPath(this.subgroupPath);
  }

  get subgroupPath(): string | undefined {
    if (!this.subgroupId) {
      return undefined;
    }
    return toSubgroupDocumentPath(
      this.groupType,
      this.groupId,
      this.subgroupId,
    );
  }

  get subgroupId(): string | undefined {
    if (!this.subgroupHash) return undefined;
    return this.fullNumberBase62;
  }

  get subgroupHash(): bigint | undefined {
    if (!this.isSubgroup) return undefined;
    const totalBitsToShift = GroupDecoder.OVERHEAD_BITS_COUNT;
    const groupAndSubgroupNumber = this.fullNumber >> totalBitsToShift;
    return groupAndSubgroupNumber & GroupDecoder.SUBGROUP_BITS_MASK;
  }

  private static get SUBGROUP_BITS_MASK(): bigint {
    return (BigInt(1) << GroupDecoder.SUBGROUP_BITS_COUNT) - BigInt(1);
  }
}
