import { useMemo } from 'react';
import { useOptimisticValue } from '../../contexts/OptimisticValueContext';
import { useRouter } from './useRouter';

export const toRouterOptimisticKey = (key: string, location: string) => {
  return `routerState-${location}-${key}`;
};

export const useRouterValueOptimistic = (
  key: string,
  location: string,
  isCatchAll = false,
) => {
  const optimisticKey = useMemo(() => {
    return toRouterOptimisticKey(key, location);
  }, [key, location]);

  /**
   * If value is undefined, then we know nobody else has set this key's value
   * in the OptimisticValueContext from the router. So we reference the
   * router instead.
   */
  const value = useOptimisticValue<string>(optimisticKey);

  const { getParam, getSegment } = useRouter();

  const queryValue = useMemo(() => {
    const queryValues =
      location === 'queryParam' ? getParam(key) : getSegment(key, isCatchAll);
    if (Array.isArray(queryValues)) {
      return queryValues[0];
    }
    return queryValues;
  }, [location, getParam, getSegment, key, isCatchAll]);

  return value !== undefined ? value || undefined : queryValue;
};
