/* eslint-disable class-methods-use-this */
import { HttpsError } from '../errors/HttpsError';
import { BaseConverter } from './BaseX';

export class Base256Converter implements BaseConverter {
  encode(buffer: Uint8Array | number[]): string {
    return Array.from(buffer)
      .map((byte) => {
        return String.fromCharCode(byte);
      })
      .join('');
  }

  decodeUnsafe(string: string): Uint8Array | undefined {
    try {
      return this.decode(string);
    } catch {
      return undefined;
    }
  }

  decode(string: string): Uint8Array {
    const bytes = new Uint8Array(string.length);
    for (let i = 0; i < string.length; i++) {
      const charCode = string.charCodeAt(i);
      if (charCode > 255) {
        throw new HttpsError(
          'invalid-argument',
          'Invalid base256 string: character code exceeds 255',
          { charCode, position: i },
        );
      }
      bytes[Number(i)] = charCode;
    }
    return bytes;
  }
}
