import { useCallback } from 'react';
import { memo } from '../../util/memo';
import { Selectable, SelectableProps } from '../Selectable';
import {
  RouterState,
  UseRouterStateProps,
} from '../../hooks/routing/useRouterState';
import { useRouterStateFallback } from '../../hooks/routing/useRouterStateFallback';
import { Optional } from 'utility-types';

export type SelectableStateProps = Optional<
  SelectableProps<string>,
  'onChange'
> & {
  routerOptions: UseRouterStateProps;
  state?: RouterState;
};

function SelectableStateUnmemoized({
  routerOptions,
  state,
  onChange,
  value,
  children,
  ...props
}: SelectableStateProps) {
  const [stateValue, setStateValue] = useRouterStateFallback({
    ...routerOptions,
    state,
  });

  const changeState = useCallback<SelectableProps<string>['onChange']>(
    (newValue, newSelected) => {
      onChange?.(newValue, newSelected);
      setStateValue(newSelected ? newValue : undefined);
    },
    [onChange, setStateValue],
  );

  return (
    <Selectable
      {...props}
      value={value}
      isSelected={stateValue === value}
      onChange={changeState}
    >
      {children}
    </Selectable>
  );
}

export const SelectableState = memo(SelectableStateUnmemoized);
