import Stack from '@mui/material/Stack';
import { DialogActionsStandard } from '../../dialog/DialogActionsStandard';
import { MatchActions } from './MatchActions';
import { useCallback, useMemo } from 'react';
import { useScoring } from '../../../contexts/ScoringContext';
import { EventFlowBase } from '../../organizer/new-event-wizard/EventFlowBase';
import { useScoreSession } from '../../../hooks/tournaments/match-details/useScoreSession';
import { memo } from '../../../util/memo';
import { useMatchDetailsRouter } from '../../../hooks/tournaments/useMatchDetailsRouter';
import { useMatch } from '../match/MatchProvider';
import { isSessionCompleted } from '../../../../functions/src/util/tournaments/moderation/isSessionCompleted';
import { useAuth } from '../../../contexts/AuthContext';
import { isCurrentSessionScoredBy } from '../../../../functions/src/util/tournaments/moderation/isCurrentSessionScoredBy';

export const SCORE_MATCH_TITLE = 'Score Game' as const;
export const SCORING_INSTRUCTIONS =
  'Enter your match results below. Dishonesty will lead to a lifetime ban from BluMint.' as const;
export const ALREADY_SCORED =
  'This game has already been scored by your team. Score again?' as const;

export const GAME_COMPLETED = 'This game has already been completed.' as const;

export type ScoringProps = {
  onSubmit?: () => void;
};

export const SessionScoringPortal = memo(
  function SessionScoringPortalUnmemoized({ onSubmit }: ScoringProps) {
    const { uid } = useAuth();
    const { outcome } = useScoring();
    const [{ sessionIndex = 0 }] = useMatchDetailsRouter();
    const { team1Scores, team2Scores, team1, team2 } = useMatch();
    const sessionCompleted = useMemo(() => {
      return isSessionCompleted(sessionIndex, { team1Scores, team2Scores });
    }, [sessionIndex, team1Scores, team2Scores]);
    const currentSessionScored = useMemo(() => {
      return isCurrentSessionScoredBy(
        sessionIndex,
        {
          team1Scores,
          team2Scores,
        },
        [team1?.acceptedUserIds || [], team2?.acceptedUserIds || []],
        uid,
      );
    }, [
      sessionIndex,
      team1Scores,
      team2Scores,
      team1?.acceptedUserIds,
      team2?.acceptedUserIds,
      uid,
    ]);
    const { scoreSession } = useScoreSession();
    const score = useCallback(async () => {
      await scoreSession(onSubmit);
    }, [scoreSession, onSubmit]);

    const description = useMemo(() => {
      return sessionCompleted
        ? GAME_COMPLETED
        : currentSessionScored
        ? ALREADY_SCORED
        : SCORING_INSTRUCTIONS;
    }, [sessionCompleted, currentSessionScored]);

    const matchActions = useMemo(() => {
      return !sessionCompleted && <MatchActions />;
    }, [sessionCompleted]);

    const buttons = useMemo(() => {
      return (
        !sessionCompleted && (
          <DialogActionsStandard
            buttons={[
              {
                isAsync: true,
                children: 'Submit',
                color: 'primary',
                disabled: !outcome,
                onClick: score,
              },
            ]}
          />
        )
      );
    }, [outcome, score, sessionCompleted]);

    const content = useMemo(() => {
      return (
        <Stack gap={6}>
          {matchActions}
          {buttons}
        </Stack>
      );
    }, [buttons, matchActions]);

    return (
      <EventFlowBase
        title={`${SCORE_MATCH_TITLE} ${sessionIndex + 1}`}
        subtitle={description}
        Content={content}
      />
    );
  },
);
