import Stack from '@mui/material/Stack';
import { OpenMatchLobbyButton } from './OpenMatchLobbyButton';
import { AddScreenshot } from '../../../data-entry-ui/specific/AddScreenshot';
import { OpenScoringButton } from './OpenScoringButton';
import { Fragment, useMemo } from 'react';
import { useCanAccessMatch } from '../../../../hooks/tournaments/match-details/useCanAccessMatch';
import { useInvolvedMatch } from '../../../../hooks/tournaments/match-details/useInvolvedMatch';
import { useCanScore } from '../../../../hooks/tournaments/match-details/useCanScore';

export const SessionActionable = () => {
  const canScore = useCanScore();
  const canUploadScreenshot = useInvolvedMatch();
  const canAccessMatch = useCanAccessMatch();

  const actions = useMemo(() => {
    return (
      canAccessMatch && (
        <Stack direction="row" spacing={2}>
          <OpenMatchLobbyButton />
          {canUploadScreenshot && <AddScreenshot />}
          {canScore && <OpenScoringButton />}
        </Stack>
      )
    );
  }, [canAccessMatch, canScore, canUploadScreenshot]);

  return <Fragment>{actions}</Fragment>;
};
