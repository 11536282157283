import { useCallback, useEffect, useRef } from 'react';
import { useAuthFlowDialog } from './auth/useAuthFlowDialog';
import { OOB_CODE_KEY, useRouterState } from './routing/useRouterState';
import { removeItem } from '../util/webStorage';
import { AUTH_ERROR_LIBRARY } from '../util/auth/authErrorConfig';
import { useErrorAlert } from './useErrorAlert';
import { AUTH_MAIL_SEND_KEY } from '../components/authentication/flow-dialogs/AuthenticationVerifyingEmailDialog';
import { useAuth } from '../contexts/AuthContext';
import { useSignOut } from './auth/useSignOut';
import { useRemoveAuthQueryParams } from './useRemoveAuthQueryParams';

export const useActionHandler = () => {
  const { uid } = useAuth();
  const { signOut } = useSignOut();
  const { open: openAuthDialog } = useAuthFlowDialog();
  const removeAuthQueryParams = useRemoveAuthQueryParams();
  const [mode] = useRouterState({
    key: 'mode',
    location: 'queryParam',
  });
  const [oobCode] = useRouterState({
    key: OOB_CODE_KEY,
    location: 'queryParam',
  });
  const { catchError } = useErrorAlert(AUTH_ERROR_LIBRARY);

  const invokedRef = useRef(false);
  const verifyEmail = useCallback(
    async (code: string) => {
      if (invokedRef.current) {
        return;
      }
      invokedRef.current = true;
      if (!!uid) {
        await signOut();
      }
      const authImport = import('../config/firebase-client/auth');
      const { auth } = await authImport;
      const firebaseAuthImport = import('firebase/auth');
      const { applyActionCode } = await firebaseAuthImport;
      await applyActionCode(auth, code);
      removeAuthQueryParams();
      removeItem(AUTH_MAIL_SEND_KEY);
      openAuthDialog({ entryDialog: 'Verifying Email Success' });
    },
    [openAuthDialog, removeAuthQueryParams, signOut, uid],
  );

  useEffect(() => {
    const handler = async () => {
      if (mode === 'verifyEmail' && !!oobCode) {
        await catchError(async () => {
          await verifyEmail(oobCode);
        });
        return;
      }
      if (mode === 'resetPassword') {
        openAuthDialog({ entryDialog: 'Reset Password' });
      }
    };

    handler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, oobCode]);
};
