import { useCallback, useMemo, useState } from 'react';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
import IconButton from '@mui/material/IconButton';
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded';

export const ElectronToggleButton = () => {
  const [isMaximized, setIsMaximized] = useState(() => {
    return (
      typeof window !== 'undefined' && window.electron?.isWindowMaximized()
    );
  });

  const toggleMaximize = useCallback(() => {
    if (typeof window === 'undefined' || !window.electron) {
      return;
    }
    window.electron.toggle();
    setIsMaximized((prev) => {
      return !prev;
    });
  }, []);

  const toggleIcon = useMemo(() => {
    return isMaximized ? (
      <CloseFullscreenRoundedIcon />
    ) : (
      <OpenInFullRoundedIcon />
    );
  }, [isMaximized]);

  return <IconButton onClick={toggleMaximize}>{toggleIcon}</IconButton>;
};
