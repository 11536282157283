export const THIRTY_MINS_MILLIS = 1000 * 60 * 30;
export const ONE_HOUR_MILLIS = 1000 * 60 * 60;
export const TWO_HOURS_MILLIS = 1000 * 60 * 60 * 2;
export const NINETY_DAYS_MS = ONE_HOUR_MILLIS * 24 * 90;
export const ONE_MINUTE_MILLIS = 1000 * 60;
export const ONE_MINUTE_SECONDS = 60;
export const TWO_HOURS_MINS = 120;
export const ONE_HOURS_MINS = 60;
export const THIRTY_MINS = 30;
export const ZERO_MINS = 0;
export const ONE_SECOND_MILLIS = 1000;
export const ONE_DAY_MILLIS = 24 * 60 * 60 * 1000;
