/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, FC, useMemo } from 'react';
import { memo } from '../util/memo';
import {
  LoadingWrapper,
  LoadingWrapperProps,
} from '../components/LoadingWrapper';

export type UseLoadingWrapperProps = {
  loadingWhile: <T extends (...args: any[]) => any>(funcToWrap: T) => T;
  LoadingWrapper: FC<Omit<LoadingWrapperProps, 'isLoading'>>;
  isLoading: boolean;
  funcWrapped: (...args: any[]) => any;
};

export function useLoadingWrapper<TFunc extends (...args: any[]) => any>(
  func?: TFunc,
): UseLoadingWrapperProps {
  const [isLoading, setLoading] = useState<boolean>(false);

  const loadingWhile = useCallback(
    <T extends (...args: any[]) => any>(funcToWrap: T): T => {
      return ((...args: Parameters<T>): ReturnType<T> => {
        setLoading(true);
        const result = funcToWrap(...args);
        const isPromise = result instanceof Promise;

        if (isPromise) {
          return result.finally(() => {
            return setLoading(false);
          }) as ReturnType<T>;
        }

        setLoading(false);
        return result;
      }) as T;
    },
    [],
  );

  const funcWrapped = useCallback(
    (...args: Parameters<TFunc>) => {
      if (!func) return undefined;
      return loadingWhile(func)(...args);
    },
    [func, loadingWhile],
  );

  const LoadingWrapperInternalUnmemoized = useCallback<
    FC<Omit<LoadingWrapperProps, 'isLoading'>>
  >(
    (props) => {
      return <LoadingWrapper isLoading={isLoading} {...props} />;
    },
    [isLoading],
  );

  const LoadingWrapperInternal = memo(LoadingWrapperInternalUnmemoized);

  return useMemo(() => {
    return {
      loadingWhile,
      LoadingWrapper: LoadingWrapperInternal,
      isLoading,
      funcWrapped,
    };
  }, [loadingWhile, LoadingWrapperInternal, isLoading, funcWrapped]);
}
