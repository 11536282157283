import { prependUtc } from 'functions/src/util/time/timezone';
import type { NextRouter } from 'next/router';
// eslint-disable-next-line @blumintinc/blumint/use-custom-router
import { useRouter as originalUseRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { segmentsOf } from '../../util/routing/segmentsOf';
import { toSegmentKey } from '../../util/routing/toSegmentKey';
import { useUrlModifications } from '../../contexts/routing/UrlModificationsContext';
import { UrlObject } from 'url';

export type TransitionOptions = {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
};

export type SegmentModification = {
  name: string;
  value?: string;
};

export type ParamModification = SegmentModification & {
  silent?: boolean;
};

export type ModifiedRouter = NextRouter & {
  getParam: (name: string) => string | string[] | undefined;
  replaceParam: (...params: ParamModification[]) => void;
  replaceSegment: (...segments: SegmentModification[]) => void;
  getSegment: (name: string, isCatchAll?: boolean) => string | undefined;
};

export const useRouter = (): ModifiedRouter => {
  const router = originalUseRouter();
  const {
    asPath,
    push: pushOriginal,
    replace: replaceOriginal,
    pathname: pathnameOriginal,
    query,
  } = router;

  const { appendModifications, refreshOptimisticValues } =
    useUrlModifications();

  const changeUrl = useCallback(
    async (
      url: UrlObject | string,
      type: 'push' | 'replace',
      as?: UrlObject | string,
      options?: TransitionOptions,
    ) => {
      const urlNew = !options?.shallow ? url : prependUtc(asPath, url);
      const asNew = !options?.shallow || !as ? as : prependUtc(asPath, as);
      const routingSuccess =
        type === 'push'
          ? await pushOriginal(urlNew, asNew, options)
          : await replaceOriginal(urlNew, asNew, options);
      refreshOptimisticValues();
      return routingSuccess;
    },
    [asPath, pushOriginal, refreshOptimisticValues, replaceOriginal],
  );

  const push = useCallback<ModifiedRouter['push']>(
    async (url, as, options) => {
      return await changeUrl(url, 'push', as, options);
    },
    [changeUrl],
  );

  const replace = useCallback<ModifiedRouter['replace']>(
    async (url, as, options) => {
      return await changeUrl(url, 'replace', as, options);
    },
    [changeUrl],
  );

  // NOTE: We have to use router to trigger this on every change of router
  // TODO: Remove this once we upgrade to Next13. There is a router bug in Next12
  // that this code circumvents.
  useEffect(() => {
    router.beforePopState(({ as, options }) => {
      const baseAs = as.split(/\?/)[0];
      const baseAsPath = asPath.split(/\?/)[0];
      if (options.shallow && baseAs !== baseAsPath) {
        push(as, as, { ...options, shallow: false });
        return false;
      }
      return true;
    });
    return () => {
      router.beforePopState(() => {
        return true;
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  const replaceParam = useCallback((...params: ParamModification[]) => {
    const paramModifications = params.map((param) => {
      return {
        location: 'queryParam' as const,
        ...param,
      };
    });
    appendModifications(paramModifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const replaceSegment = useCallback((...segments: SegmentModification[]) => {
    const segmentModifications = segments.map((segment) => {
      return {
        location: 'segment' as const,
        ...segment,
      };
    });
    appendModifications(segmentModifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getParam = useCallback(
    (name: string) => {
      if (typeof window === 'undefined') {
        return Object.keys(query).includes(name)
          ? query[String(name)]
          : undefined;
      }
      const url = new URL(window.location.href);
      return (
        (url.searchParams.has(name) && url.searchParams.get(name)) || undefined
      );
    },
    [query],
  );

  const getSegment = useCallback(
    (name: string, isCatchAll = false) => {
      const keys = segmentsOf(pathnameOriginal);
      const segmentKey = toSegmentKey(name, isCatchAll);
      const keyIndex = keys.indexOf(segmentKey);

      if (keyIndex !== -1 && keyIndex < keys.length) {
        const values = segmentsOf(asPath);
        const value = values[Number(keyIndex)];
        return decodeURIComponent(value);
      }

      return undefined;
    },
    [pathnameOriginal, asPath],
  );

  return useMemo(() => {
    return {
      ...router,
      push,
      replace,
      getParam,
      replaceParam,
      getSegment,
      replaceSegment,
    };
  }, [
    router,
    push,
    replace,
    getParam,
    replaceParam,
    getSegment,
    replaceSegment,
  ]);
};
