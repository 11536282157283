import IconButton from '@mui/material/IconButton';
import { useCallback } from 'react';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

export const ElectronRefreshButton = () => {
  const refresh = useCallback(() => {
    return typeof window !== 'undefined' && window.electron?.refresh();
  }, []);
  return (
    <IconButton onClick={refresh}>
      <RefreshRoundedIcon />
    </IconButton>
  );
};
