import { SetShipParams } from '../../../functions/src/callable/ship/setShip';

export const rejectShipRequest = async (
  props: Omit<SetShipParams, 'action'>,
) => {
  const { setShipExternal } = await import(
    '../../firebaseCloud/ship/setShipExternal'
  );
  const params: SetShipParams = {
    ...props,
    action: 'rejectRequest',
  };

  await setShipExternal(params);
};
