import {
  createContext,
  useState,
  FC,
  ReactNode,
  useContext,
  useMemo,
  CSSProperties,
} from 'react';
import { memo } from '../util/memo';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';

export type HoverContextProps = {
  isHovered: boolean;
};

export const HoverContext = createContext<HoverContextProps | undefined>(
  undefined,
);

export type HoverProviderProps = {
  children: ReactNode;
  style?: CSSProperties;
};

export const useHover = () => {
  const context = useContext(HoverContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useHover must be used within a HoverProvider',
    );
  }
  return context;
};

const HoverProviderUnmemoized: FC<HoverProviderProps> = ({
  children,
  style = {},
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const memoizedValue = useMemo(() => {
    return { isHovered };
  }, [isHovered]);

  return (
    <div
      onMouseEnter={() => {
        return setIsHovered(true);
      }}
      onMouseLeave={() => {
        return setIsHovered(false);
      }}
      style={style}
    >
      <HoverContext.Provider value={memoizedValue}>
        {children}
      </HoverContext.Provider>
    </div>
  );
};

export const HoverProvider = memo(HoverProviderUnmemoized);
