import type { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isOnAndroid } from '../util/platform';

export const useMobile = () => {
  const isMobileClient = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('md');
  });
  return isMobileClient || isOnAndroid();
};
