import { createContext, useContext, ReactNode } from 'react';
import { memo } from '../../util/memo';
import { useTypingContext } from 'stream-chat-react';
import { useAuth } from '../../contexts/AuthContext';
import { toUserId } from '../../../functions/src/util/messaging/mapId';
import { truncateIfTooLong } from '../../util/truncate';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';

export type TypingProviderProps = {
  children: ReactNode;
};

type TypingContextProps = {
  typingIndicatorText?: string;
};

export const TypingContext = createContext<TypingContextProps | null>(null);

export const useTyping = () => {
  const context = useContext(TypingContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useTyping must be used within a TypingProvider',
    );
  }
  return context;
};

const TypingProviderUnmemoized = ({ children }: TypingProviderProps) => {
  const { typing: typingState } = useTypingContext();
  const { uid } = useAuth();
  const typingUsers = typingState
    ? Object.keys(typingState).filter((getStreamUserId) => {
        return uid !== toUserId(getStreamUserId);
      })
    : [];

  const typingIndicatorText =
    typingUsers.length === 1
      ? `${truncateIfTooLong(
          `${typingState?.[typingUsers[0]]?.user?.name}`,
          36,
        )} is`
      : typingUsers.length > 1
      ? `${typingUsers.length} people are`
      : undefined;

  return (
    <TypingContext.Provider
      value={{
        typingIndicatorText,
      }}
    >
      {children}
    </TypingContext.Provider>
  );
};

export const TypingProvider = memo(TypingProviderUnmemoized);
