import { ReactNode, useMemo } from 'react';
import { memo } from '../../../util/memo';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Link } from '../../Link';
import { SxProps, useTheme } from '@mui/material/styles';
import { User } from '../../../../functions/src/types/firestore/User';
import { GradientTypography } from '../../gradients/GradientTypography';
import { AvatarGroupNext } from '../../avatar/AvatarGroupNext';
import { ELLIPSIS_SX } from '../../../util/string';

export type FriendListItemLayoutProps = Pick<
  User,
  'username' | 'imgUrl' | 'status'
> & {
  userId: string;
  message?: string;
  mutualFriendsCountEstimate?: number;
  children?: ReactNode;
  sx?: SxProps;
  redirect?: string;
};

const FriendListItemLayoutUnmemoized = ({
  children,
  sx,
  redirect = 'profile',
  ...props
}: FriendListItemLayoutProps) => {
  const theme = useTheme();
  const {
    username,
    imgUrl,
    userId,
    status,
    mutualFriendsCountEstimate,
    message,
  } = props;

  const mutualFriends = useMemo(() => {
    return (
      !!mutualFriendsCountEstimate && (
        <Typography
          variant="body1"
          color="primary.light"
          sx={{ fontWeight: 'medium', ...ELLIPSIS_SX }}
        >
          {`${
            mutualFriendsCountEstimate >= 10 ? '9+' : mutualFriendsCountEstimate
          } Mutual Friend${mutualFriendsCountEstimate === 1 ? '' : 's'}`}
        </Typography>
      )
    );
  }, [mutualFriendsCountEstimate]);

  const presenceStatus = useMemo(() => {
    return (
      !!status &&
      status.toLowerCase() !== 'unknown' && (
        <Typography variant="subtitle2" color="text.secondary" sx={ELLIPSIS_SX}>
          {status}
        </Typography>
      )
    );
  }, [status]);

  const messageStatus = useMemo(() => {
    return (
      !!message && (
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ wordBreak: 'break-word' }}
        >
          {message}
        </Typography>
      )
    );
  }, [message]);

  const avatarUsers = useMemo(() => {
    return [{ userId, imgUrl }];
  }, [userId, imgUrl]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={sx}
    >
      <Link
        style={{ textDecoration: 'none', flex: 1, minWidth: 0 }}
        href={`/${redirect}/${userId}`}
      >
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          sx={{
            '.MuiBadge-badge.MuiBadge-dot': {
              height: '12px',
              width: '12px',
              borderRadius: '64px',
              border: '1px solid #FFFFFF',
              boxShadow: `0 0 0 3px ${theme.palette.background.elevation[2]}`,
            },
          }}
        >
          <AvatarGroupNext
            showStatus
            users={avatarUsers}
            height={56}
            width={56}
          />
          <Stack direction="column" sx={{ minWidth: 0, flex: 1 }}>
            <GradientTypography variant="subtitle1" sx={{ ...ELLIPSIS_SX, maxWidth: '100%' }}>
              {username}
            </GradientTypography>
            {mutualFriends}
            {presenceStatus}
            {messageStatus}
          </Stack>
        </Stack>
      </Link>
      {children}
    </Stack>
  );
};

export const FriendListItemLayout = memo(FriendListItemLayoutUnmemoized);
