import {
  MUX_VOD_STREAM,
  MUX_LOADING_THRESHOLD,
} from '../../../../functions/src/util/mux/constants';
import { memo } from '../../../util/memo';
import MuxPlayer from '@mux/mux-player-react/lazy';
import { MuxPlayerRefAttributes } from '@mux/mux-player-react/.';
import { HoverProvider } from '../../../contexts/HoverContext';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { usePictureInPicture } from '../../../contexts/mux-player/PictureInPictureContext';
import { useLivestream } from '../../../contexts/LivestreamContext';
import { ViewerCountDynamic } from './ViewerCountDynamic';
import { useAutoplayVideoElement } from '../../../hooks/useAutoplayVideoElement';
import { useLocalStorage } from '../../../contexts/LocalStorage';
import { useAuth } from '../../../contexts/AuthContext';
import { useConnectedRoom } from '../../../hooks/voice-chat/useConnectedRoom';
import { useChatContext as useStreamChatContext } from 'stream-chat-react';
import { useRoomContext as useLivekitRoom } from '@livekit/components-react';
import { useDetectPresence } from '../../../hooks/presence/useDetectPresence';
import { ViewerCountProvider } from './ViewerCountProvider';
import { TheaterModeButton } from '../TheaterModeButton';

export const LIVESTREAM_PLAYER_HEIGHT = 274 as const;
export const LIVESTREAM_PLAYER_HEIGHT_DESKTOP = 312 as const;
export const LIVESTREAM_PLAYER_HEIGHT_MOBILE = 232 as const;

const MUX_PLAYER_STYLE = {
  height: '100%',
  aspectRatio: 16 / 9,
  maxWidth: '1200px',
} as const;

const VOLUME_PREFERENCE = 'volumePreference' as const;

const LivestreamPlayerUnmemoized = () => {
  const { uidFull } = useAuth();
  const { getItem, setItem } = useLocalStorage();
  const { playbackId } = useLivestream();
  const { isConnectedRoom } = useConnectedRoom();
  const { setIsPictureInPicture } = usePictureInPicture();
  const room = useLivekitRoom();
  const { channel } = useStreamChatContext();
  const [muxPlayer, setMuxPlayer] = useState<MuxPlayerRefAttributes | null>(
    null,
  );

  useDetectPresence<MuxPlayerRefAttributes>({
    elementId: playbackId,
    target: muxPlayer,
  });

  const videoElement = useMemo(() => {
    return muxPlayer?.shadowRoot
      ?.querySelector('media-theme')
      ?.querySelector('mux-video')
      ?.shadowRoot?.querySelector('video');
  }, [muxPlayer]);

  useAutoplayVideoElement({ videoElement });

  const enableSound = useCallback(() => {
    if (!videoElement) {
      return;
    }

    videoElement.muted = false;

    const volumePreference = getItem<number>(VOLUME_PREFERENCE);

    if (typeof volumePreference !== 'number') {
      return;
    }
    videoElement.volume = volumePreference;
  }, [getItem, videoElement]);

  const saveVolumePreference = useCallback(
    (event: Event) => {
      const { volume, muted } = event.target as HTMLVideoElement;
      const videoElementVolume = muted ? 0 : volume;
      return setItem('volumePreference', videoElementVolume);
    },
    [setItem],
  );

  const mediaControllerElement = useMemo(() => {
    return muxPlayer?.shadowRoot
      ?.querySelector('media-theme')
      ?.shadowRoot?.querySelector('media-controller');
  }, [muxPlayer]);

  useEffect(() => {
    if (!mediaControllerElement) {
      return;
    }

    const observer = new MutationObserver(() => {
      setIsPictureInPicture(mediaControllerElement.hasAttribute('mediaispip'));
    });

    observer.observe(mediaControllerElement, {
      attributes: true,
      attributeFilter: ['mediaispip'],
    });

    return () => {
      observer.disconnect();
    };
  }, [mediaControllerElement, setIsPictureInPicture]);

  const isInStudio = useMemo(() => {
    if (!channel) {
      return;
    }
    return (
      isConnectedRoom('studio') && room.name.includes(channel.id as string)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectedRoom, channel?.id, room?.name]);

  const [controlBar, setControlBar] = useState<Element | null>(null);

  useEffect(() => {
    if (!mediaControllerElement) {
      return;
    }

    const locateControlBar = () => {
      const controlBarElement = mediaControllerElement.querySelector(
        '[part="control-bar bottom"]',
      );
      setControlBar(controlBarElement);
    };

    locateControlBar();

    const observer = new MutationObserver(locateControlBar);

    observer.observe(mediaControllerElement, {
      childList: true,
    });

    return () => {
      return observer.disconnect();
    };
  }, [mediaControllerElement]);

  return (
    <HoverProvider
      style={{
        height: '100%',
        width: 'fit-content',
        margin: '0 auto',
      }}
    >
      <ViewerCountProvider>
        <ViewerCountDynamic
          sx={{
            position: 'absolute',
            width: '100%',
            top: 4,
            pl: 2,
          }}
        >
          <MuxPlayer
            ref={setMuxPlayer}
            muted={isInStudio}
            playbackId={playbackId}
            loading={MUX_LOADING_THRESHOLD}
            streamType={MUX_VOD_STREAM}
            style={MUX_PLAYER_STYLE}
            onPlay={enableSound}
            onVolumeChange={saveVolumePreference}
            metadata={{ viewer_user_id: uidFull }}
          />
          <TheaterModeButton controlBar={controlBar} />
        </ViewerCountDynamic>
      </ViewerCountProvider>
    </HoverProvider>
  );
};
export const LivestreamPlayer = memo(LivestreamPlayerUnmemoized);
