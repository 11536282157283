import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { memo } from '../util/memo';
import { DEPLOYMENT_URL } from '../../functions/src/util/deploymentUrl';

export type LocationContextType = {
  location: Location | null;
  href: string;
};

const DEFAULT_STATE: LocationContextType = {
  location: null,
  href: DEPLOYMENT_URL,
};

const LocationContext = createContext(DEFAULT_STATE);
/**
 * Deprecated, use the useRouter() hook instead.
 */

export function useLocation() {
  return useContext(LocationContext);
}

export type LocationProviderProps = {
  children: ReactElement;
};

export const LocationProvider = memo(function LocationProviderUnmemoized({
  children,
}: LocationProviderProps) {
  const [location, setLocation] = useState<Location | null>(null);

  const popLocation = () => {
    setLocation(window.location);
  };
  useEffect(() => {
    popLocation();
    window.addEventListener('popstate', popLocation);
    return () => {
      window.removeEventListener('popstate', popLocation);
    };
  }, []);

  const memoizedValue = useMemo(() => {
    return {
      location,
      href: location?.href || DEFAULT_STATE.href,
    };
  }, [location]);

  return (
    <LocationContext.Provider value={memoizedValue}>
      {children}
    </LocationContext.Provider>
  );
});
