export const calculateOnlineCount = (
  onlineStatus: Record<string, boolean>,
  friends: string[] = [],
) => {
  const { onlineFriendsCount } = friends.reduce(
    (counts, userId) => {
      if (onlineStatus[userId as string] === true) {
        counts.onlineFriendsCount++;
      }
      return counts;
    },
    { onlineFriendsCount: 0 },
  );

  return { onlineFriendsCount };
};
