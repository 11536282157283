import { CUSTOM_PROVIDER_KEY } from '../../functions/src/util/oauth/oAuthRedirectUri';
import { OOB_CODE_KEY } from './routing/useRouterState';
import { useRemoveQueryParams } from './useRemoveQueryParams';

export const CODE_QUERY_PARAM = 'code';

export const AUTH_QUERY_PARAMS = [
  'api-key',
  'mode',
  OOB_CODE_KEY,
  'continue-url',
  CODE_QUERY_PARAM,
  CUSTOM_PROVIDER_KEY,
  'email',
  'lang',
] as const;

export const useRemoveAuthQueryParams = () => {
  return useRemoveQueryParams(AUTH_QUERY_PARAMS);
};
