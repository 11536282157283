export type UploadFileParams = {
  /**
   * Without the file ending
   */
  storagePath: string;
  file: File;
};

export const UGC_STORAGE_DIRECTORY = 'Ugc' as const;

/**
 * This should be dynamically imported
 */
export async function uploadFile({ storagePath, file }: UploadFileParams) {
  const [{ storage }, { ref, uploadBytes, getDownloadURL }] = await Promise.all(
    [
      import('../../config/firebase-client/storage'),
      import('firebase/storage'),
    ],
  );
  const fileEnding = file.name.split('.')[1];
  const storageRef = ref(storage, `${storagePath}.${fileEnding}`);

  const snapshot = await uploadBytes(storageRef, file);
  return getDownloadURL(snapshot.ref);
}
