import { useCallback } from 'react';
import { useWizardDialog } from '../../wizard/useWizardDialog';
import { MatchProvider } from '../../../components/tournaments/match/MatchProvider';
import { MatchDetails } from '../../../components/tournaments/match/details/MatchDetails';
import { ScreenshotUploadPortal } from '../../../components/tournaments/self-moderation/ScreenshotUploadPortal';
import { TournamentContext } from '../../../contexts/docs/TournamentContext';
import { MatchScoredSuccessfully } from '../../../components/tournaments/self-moderation/MatchScoredSuccessfully';
import { SessionScoringPortal } from '../../../components/tournaments/self-moderation/SessionScoringPortal';
import { ScoringProvider } from '../../../contexts/ScoringContext';
import { Tournament } from '../../../../functions/src/types/firestore/Game/Tournament';
import { MatchAggregated } from '../../../../functions/src/types/firestore/Game/Tournament/Bracket';

export const ENTRIES_IDS_MATCH = [
  'details',
  'scoring',
  'screenshot',
  'successfullyScored',
  // 'placement',
] as const;

export type MatchEntryId = (typeof ENTRIES_IDS_MATCH)[number];

export type OpenMatchDetailsWizardParams = {
  entryId?: MatchEntryId;
  onClose?: () => void;
  match: MatchAggregated<Date>;
  tournament: Tournament<Date>;
};

export const useMatchDetailsWizard = (isMatchDisabled: boolean) => {
  const { open: openDialog } = useWizardDialog();

  const open = useCallback(
    ({ entryId, onClose, match, tournament }: OpenMatchDetailsWizardParams) => {
      openDialog({
        storeDefault: {},
        onClose,
        elements: {
          details: <MatchDetails />,
          scoring: (
            <ScoringProvider>
              <SessionScoringPortal />
            </ScoringProvider>
          ),
          // 'Placement Selection Portal': <MatchPlacementSelector />,
          screenshot: <ScreenshotUploadPortal />,
          successfullyScored: <MatchScoredSuccessfully />,
        },
        elementIdEntry: !entryId ? 'details' : entryId,
        wrapperProps: { showCloseIcon: false },
        Wrapper: (
          <TournamentContext.Provider value={tournament}>
            <MatchProvider {...match} isMatchDisabled={isMatchDisabled}>
              <></>
            </MatchProvider>
          </TournamentContext.Provider>
        ),
      });
    },
    [openDialog, isMatchDisabled],
  );
  return { open };
};
