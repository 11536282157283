import type { StreamChat } from 'stream-chat';
import {
  useContext,
  createContext,
  ReactNode,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { memo } from '../../util/memo';
import { Chat } from 'stream-chat-react';
import { useAuth } from 'src/contexts/AuthContext';
import { toGetStreamId } from '../../../functions/src/util/messaging/mapId';
import { streamChatFrontend } from '../../config/get-stream/streamChatFrontend';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';

export type StreamContextType = {
  chatClient: StreamChat;
  isLoading: boolean;
};

export type ConnectionStatus = 'Anonymous' | 'None' | 'Authenticated';

export type StreamProviderProps = { children?: ReactNode };

export const StreamContext = createContext<StreamContextType | null>(null);

export const useStream = () => {
  const context = useContext(StreamContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useStream must be used within a StreamProvider',
    );
  }
  return context;
};

export const StreamProvider = memo(function StreamProviderUnmemoized({
  children,
}: StreamProviderProps) {
  const { userDataFull } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const [lastConnectionStatus, setLastConnectionStatus] =
    useState<ConnectionStatus>('None');

  const chatToken = userDataFull?.hidden?.chatToken;

  const connect = useCallback(
    async (status: ConnectionStatus) => {
      if (!userDataFull?.id) {
        return;
      }
      const userIdGetStream = toGetStreamId(userDataFull.id);

      if (status === 'Anonymous') {
        await streamChatFrontend.setGuestUser({ id: userIdGetStream });
      } else {
        const userGetStream = {
          id: userIdGetStream,
          name: userDataFull.username,
          image: userDataFull.imgUrl,
        };
        await streamChatFrontend.connectUser(userGetStream, chatToken);
      }
      setLastConnectionStatus(status);
    },
    [chatToken, userDataFull?.id, userDataFull?.imgUrl, userDataFull?.username],
  );

  const disconnect = useCallback(async () => {
    await streamChatFrontend.disconnectUser();
  }, []);

  useEffect(() => {
    const handler = async () => {
      if (!userDataFull?.id) {
        return;
      }

      const currentConnectionStatus: ConnectionStatus =
        !chatToken || userDataFull.isAnonymous ? 'Anonymous' : 'Authenticated';

      if (currentConnectionStatus !== lastConnectionStatus) {
        if (lastConnectionStatus !== 'None') {
          await disconnect();
        }
        await connect(currentConnectionStatus);
      }
      setIsLoading(false);
    };

    handler();
  }, [
    chatToken,
    userDataFull?.id,
    userDataFull?.isAnonymous,
    lastConnectionStatus,
    connect,
    disconnect,
  ]);

  const valueMemoed = useMemo(() => {
    return { chatClient: streamChatFrontend, isLoading };
  }, [isLoading]);

  return (
    <StreamContext.Provider value={valueMemoed}>
      <Chat client={streamChatFrontend} theme="str-chat__theme-dark">
        {children}
      </Chat>
    </StreamContext.Provider>
  );
});
