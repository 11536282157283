/* eslint-disable @blumintinc/blumint/consistent-callback-naming */
import { useEffect, useState } from 'react';
import type { DatabaseReference } from 'firebase/database';

export const useCallerCount = (roomRefs: (DatabaseReference | undefined)[]) => {
  const [count, setCount] = useState<number | 'unknown'>('unknown');

  useEffect(() => {
    const unsubscribes: (() => void)[] = [];
    const counts: Record<string, number> = {};

    const handler = async () => {
      const { onValue, child } = await import('firebase/database');

      const refs = (Array.isArray(roomRefs) ? roomRefs : [roomRefs]).filter(
        Boolean,
      );
      if (!refs.length) {
        setCount(0);
        return;
      }

      refs.forEach((roomRef) => {
        if (!roomRef) {
          return;
        }

        const callerCountRef = child(roomRef, 'callerCount');
        const refKey = callerCountRef.toString();

        const unsubscribe = onValue(callerCountRef, (snapshot) => {
          counts[refKey as string] = snapshot.exists() ? snapshot.val() : 0;
          const totalCount = Object.values(counts).reduce((sum, num) => {
            return sum + num;
          }, 0);
          setCount(totalCount);
        });

        unsubscribes.push(unsubscribe);
      });
    };

    handler();

    return () => {
      return unsubscribes.forEach((unsubscribe) => {
        return unsubscribe();
      });
    };
  }, [roomRefs]);

  return count;
};
