import Stack from '@mui/material/Stack';
import { ChipUser } from 'src/components/ChipUser';
import { useAuth } from 'src/contexts/AuthContext';
import { isAdmin } from 'functions/src/types/firestore/User/util';
import { Member } from 'functions/src/types/firestore/Game/Tournament/Guestlist';
import { memo } from '../../../../util/memo';
import { ParticipantAdminDetails } from '../../participants/ParticipantAdminDetails';

export type TeamMemberDetailsProps = {
  member: Member;
};

export const TeamMemberDetails = memo(function TeamMemberDetailsUnmemoized({
  member,
}: TeamMemberDetailsProps) {
  const { user } = useAuth();
  const {
    checkedIn,
    imgUrl,
    status,
    tournamentId,
    username,
    userId,
    ...memberRest
  } = member;

  return (
    <Stack spacing={2}>
      <ChipUser
        username={username}
        avatarUrl={imgUrl}
        href={`/user/${userId}`}
      />
      {isAdmin(user?.email) && (
        <ParticipantAdminDetails
          {...memberRest}
          sx={{ wordWrap: 'break-word' }}
        />
      )}
    </Stack>
  );
});
