import {
  Context,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { memo } from '../../util/memo';
import { PrizePool } from '../../../functions/src/types/firestore/PrizePool';
import { EventDocument } from './create-event/CreateEventContext';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';

// TODO: some typings are a bit incorrect here
export type LinkPrizePoolContextType<TEvent extends EventDocument> = {
  linkEvent: () => Promise<void>;
  selectedEvent: TEvent;
  setSelectedEvent: Dispatch<SetStateAction<TEvent>>;
  prizePool: PrizePool;
};

// this 'any' is overwritten when the hook is used with a generic
const LinkPrizePoolContext = createContext<
  LinkPrizePoolContextType<any> | undefined
>(undefined);

export type LinkPrizePoolProps = {
  prizePool: PrizePool;
  isChangingLink: boolean;
};

export const LinkPrizePoolProvider: FC<
  LinkPrizePoolProps & { children: ReactNode }
> = memo(function LinkPrizePoolProviderUnmemoized<
  TEvent extends EventDocument,
>({ children, prizePool, isChangingLink }) {
  const [selectedEvent, setSelectedEvent] = useState<TEvent>();
  const linkEvent = useCallback(async () => {
    if (!selectedEvent?.id || !selectedEvent?.gameId) {
      return;
    }
    const { linkPrizePool } = await import(
      '../../firebaseCloud/tournament/organizer/linkPrizePool'
    );
    await linkPrizePool({
      prizePoolId: prizePool.id,
      tournamentId: selectedEvent?.id,
      isChangingLink,
    });
  }, [isChangingLink, prizePool.id, selectedEvent?.gameId, selectedEvent?.id]);

  return (
    <LinkPrizePoolContext.Provider
      value={{ linkEvent, selectedEvent, setSelectedEvent, prizePool }}
    >
      {children}
    </LinkPrizePoolContext.Provider>
  );
});

export function useLinkPrizePoolContext<TEvent extends EventDocument>() {
  const context = useContext<LinkPrizePoolContextType<TEvent>>(
    LinkPrizePoolContext as unknown as Context<
      LinkPrizePoolContextType<TEvent>
    >,
  );
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useStream must be used within a StreamProvider',
    );
  }
  return context;
}
