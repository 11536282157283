import { TournamentRole } from '../../types/firestore/Game/Tournament';
import { RoleMap } from '../../types/Roles';
import { Loadable } from '../isLoading';

export const isContributor = (
  contributor?: RoleMap<TournamentRole>['contributor'],
  uid?: Loadable<string>,
) => {
  return (
    !!uid && (contributor?.includes(uid))
  );
};
