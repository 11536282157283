import { deploymentUrlSchemed } from './deploymentUrl';

export const toFullUrl = (
  path: string,
  searchParams: Record<string, string>,
) => {
  const url = new URL(path, deploymentUrlSchemed());
  Object.entries(searchParams).forEach(([key, value]) => {
    url.searchParams.append(key, value);
  });
  return url.toString();
};
