import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useMemo, useRef } from 'react';
import { memo } from '../../../util/memo';
import { Message } from '../message/Message';
import { MessageInput } from '../message/MessageInput';
import { useMessageDraft } from '../../../hooks/messaging/useMessageDraft';
import { usePictureInPicture } from '../../../contexts/mux-player/PictureInPictureContext';
import { useAuth } from '../../../contexts/AuthContext';
import { useBanStatus } from '../../../hooks/messaging/useBanStatus';
import { NoticeBanned } from '../notices/NoticeBanned';
import { NoticeSignIn } from '../notices/NoticeSignIn';
import { TypingIndicator } from '../TypingIndicator';
import { PlaybacksDownloadable } from '../../../../functions/src/types/firestore/Game/Tournament';
import { useMessageInputHeight } from '../../../hooks/messaging/useMessageInputHeight';
import { useProcessMessage } from '../../../hooks/messaging/useProcessMessage';
import { useReply } from '../ReplyContext';
import { VoiceChatHub } from '../../voice-chat/VoiceChatHub';
import { useTheaterMode } from '../../../contexts/mux-player/TheaterModeContext';
import { useLivestreamPlayer } from '../../../hooks/livestream/useLivestreamPlayer';
import {
  MessageList as StreamMessageList,
  Window as StreamWindow,
  Thread as StreamThread,
  useChatContext as useStreamChatContext,
} from 'stream-chat-react';
import {
  LIVESTREAM_PLAYER_HEIGHT_DESKTOP,
  LIVESTREAM_PLAYER_HEIGHT_MOBILE,
} from '../../livestream/mux/LivestreamPlayer';

export const MESSAGE_INPUT_ID = 'message-input' as const;
export const INPUT_AREA_HEIGHT = 40 as const;

const ChannelInnerUnmemoized = () => {
  const { uid } = useAuth();
  const { replyingTo } = useReply();
  const { channel } = useStreamChatContext();
  const { getThreadInputDraft, onThreadInputChange } = useMessageDraft();
  const banStatus = useBanStatus(uid || undefined);
  const { isPictureInPicture } = usePictureInPicture();
  const { isTheaterMode } = useTheaterMode();

  const { sendMessageOverride } = useProcessMessage({
    replyingTo,
  });
  const inputRef = useRef<HTMLElement | null>(null);
  const messageInputHeight = useMessageInputHeight(inputRef);

  const textAreaProps = useMemo(() => {
    return { onChange: onThreadInputChange };
  }, [onThreadInputChange]);

  const isUserBanned =
    banStatus !== 'loading' &&
    (banStatus.isBannedFromChannel(channel?.cid) || banStatus.isBannedFromApp);

  const voiceChat = useMemo(() => {
    return (
      channel?.cid && (
        <VoiceChatHub channelType={channel?.type} channelId={channel.cid} />
      )
    );
  }, [channel?.cid, channel?.type]);

  const inputArea = useMemo(() => {
    return isUserBanned ? (
      <NoticeBanned />
    ) : !uid ? (
      <NoticeSignIn />
    ) : (
      <Box sx={{ position: 'relative', pb: 4 }}>
        {voiceChat}
        <MessageInput id={MESSAGE_INPUT_ID} sx={{ pr: 2 }} />
        <Box
          sx={{
            width: '100%',
            position: 'absolute',
            bottom: 0,
            pl: 4,
          }}
        >
          <TypingIndicator />
        </Box>
      </Box>
    );
  }, [isUserBanned, uid, voiceChat]);

  const livestreamPlayer = useLivestreamPlayer({
    playbacksDownloadable: channel?.data
      ?.playbacksDownloadable as PlaybacksDownloadable,
  });

  const livestream = useMemo(() => {
    return (
      livestreamPlayer &&
      !isTheaterMode &&
      !isPictureInPicture && (
        <Box
          sx={{
            height: {
              xs: LIVESTREAM_PLAYER_HEIGHT_MOBILE,
              md: LIVESTREAM_PLAYER_HEIGHT_DESKTOP,
            },
          }}
        >
          {livestreamPlayer}
        </Box>
      )
    );
  }, [isPictureInPicture, isTheaterMode, livestreamPlayer]);

  return (
    <Stack height="100%" width="100%">
      {livestream}
      <Box overflow="auto" height="100%">
        <StreamWindow>
          <StreamMessageList />
        </StreamWindow>
      </Box>
      <Box>{inputArea}</Box>
      <Box
        ref={inputRef}
        sx={{
          transition: 'width 300ms ease-in-out',
          '.str-chat__parent-message-li': {
            pt: '28px !important',
          },
          '.str-chat__parent-message-li > :nth-of-type(2)': {
            pl: '0px !important',
          },
          '.str-chat__parent-message-li > :nth-of-type(2) > :nth-of-type(1)': {
            pl: '0px !important',
          },
          '& .str-chat__send-button': {
            height: `${messageInputHeight}px !important`,
          },
        }}
      >
        <StreamThread
          autoFocus
          enableDateSeparator
          Message={Message}
          additionalMessageInputProps={{
            grow: true,
            additionalTextareaProps: textAreaProps,
            getDefaultValue: getThreadInputDraft,
            overrideSubmitHandler: sendMessageOverride,
          }}
        />
      </Box>
    </Stack>
  );
};

export const ChannelInner = memo(ChannelInnerUnmemoized);
