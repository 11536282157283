import { HttpsError } from '../../functions/src/util/errors/HttpsError';

export type TimeUnit =
  | 'SECONDS'
  | 'MINUTES'
  | 'HOURS'
  | 'DAYS'
  | 'WEEKS'
  | 'MONTHS'
  | 'YEARS';

// Define conversion rates from 1 unit to all others
export const timeConversionRates = {
  SECONDS: {
    SECONDS: 1,
    MINUTES: 1 / 60,
    HOURS: 1 / 3600,
    DAYS: 1 / 86400,
    WEEKS: 1 / 604800,
    MONTHS: 1 / 2628000,
    YEARS: 1 / 31536000,
  },
  MINUTES: {
    SECONDS: 60,
    MINUTES: 1,
    HOURS: 1 / 60,
    DAYS: 1 / 1440,
    WEEKS: 1 / 10080,
    MONTHS: 1 / 43800,
    YEARS: 1 / 525600,
  },
  HOURS: {
    SECONDS: 3600,
    MINUTES: 60,
    HOURS: 1,
    DAYS: 1 / 24,
    WEEKS: 1 / 168,
    MONTHS: 1 / 730,
    YEARS: 1 / 8760,
  },
  DAYS: {
    SECONDS: 86400,
    MINUTES: 1440,
    HOURS: 24,
    DAYS: 1,
    WEEKS: 1 / 7,
    MONTHS: 1 / 30.44,
    YEARS: 1 / 365.25,
  },
  WEEKS: {
    SECONDS: 604800,
    MINUTES: 10080,
    HOURS: 168,
    DAYS: 7,
    WEEKS: 1,
    MONTHS: 1 / 4.348,
    YEARS: 1 / 52.18,
  },
  MONTHS: {
    SECONDS: 2628000,
    MINUTES: 43800,
    HOURS: 730,
    DAYS: 30.44,
    WEEKS: 4.348,
    MONTHS: 1,
    YEARS: 1 / 12,
  },
  YEARS: {
    SECONDS: 31536000,
    MINUTES: 525600,
    HOURS: 8760,
    DAYS: 365.25,
    WEEKS: 52.18,
    MONTHS: 12,
    YEARS: 1,
  },
};

export function convertTime(from: TimeUnit, to: TimeUnit) {
  if (!timeConversionRates[from] || !timeConversionRates[from][to]) {
    throw new HttpsError('invalid-argument', `Cannot convert time`, {
      from,
      to,
    });
  }

  return timeConversionRates[from][to];
}
