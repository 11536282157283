import stringify from 'safe-stable-stringify';
import { convertToHash } from '../convertToHash';
import { HttpsError } from '../errors/HttpsError';

export const stableHash = <TObject>(object: TObject) => {
  const stringified = stringify(object);

  if (!stringified && stringified !== '') {
    throw new HttpsError('internal', 'Failed to stringify object', object);
  }

  return convertToHash(stringified);
};
